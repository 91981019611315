import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiJobAdd } from "../../models/job.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddJob from "./AddJob";
import { JobValidationSchema } from "../validationSchema/JobValidationSchema";
import { useAddJobMutation, useGetJobByIdQuery, useUpdateJobMutation } from "../../services/JobService";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { setCurrentPage } from "../../redux/slices/JobSlices";


const AddJobFormik = withFormik<OtherProps & formikApiProps,ApiJobAdd>({
  enableReinitialize:true,
    mapPropsToValues: (props) => {
      let values: ApiJobAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues
          break;
        case "Edit":
            values = props.intialValues
          break;
        case "View":
            values = props.intialValues
          break;
        default:
            values = props.intialValues
      }

      return values;
    },

    validationSchema : JobValidationSchema,

    handleSubmit(values ,{props,setValues,setSubmitting}) {
      switch (props.formType) {
        case 'Add':
        props.addApi(values).then((response: any) => {
          if (response?.data?.status) {
            // props.setInitialValues(props.intialValues)
            showToast(response?.data?.message, "success")
            store.dispatch(setCurrentPage(0))

            // setSubmitting(true)
            props.handleClose(false)
          } else {
            if (response?.error) {
              props.setInitialValues(values)
              showToast(response?.error?.data?.message, "error")
            } else {
              props.setInitialValues(values)
              showToast(response?.data?.message, "error")
            }
          }

        }

        )
          break
          case 'Edit':
            let reqBody = {
              jobTitle: values.jobTitle,
              description: values.description,
            }
    
            setValues({ ...values })
    
            props.editApi({ body: reqBody, id: props.selectedId }).then((response: any) => {
              if (response?.data?.status) {
                showToast(response?.data?.message, "success")
                setSubmitting(true)
                props.handleClose()
              } else {
                if (response?.error) {
                  props.setInitialValues(values)
                  showToast(response?.error?.data?.message, "error")
                } else {
                  props.setInitialValues(values)
                  showToast(response?.data?.message, "error")
                }
              }
            }
            )
            break
          default: values = props.intialValues
    
        }
      },
  })(AddJob);


const AddJobWrapper = React.forwardRef((props: OtherProps, ref) => {
  const {open=false, handleClose, formType } = props;
  const [addJobApi,addJobInfoApi ] = useAddJobMutation()
  const [updateJobApi, updateJobApiInfo]= useUpdateJobMutation()
  const job : any = useSelector((state:RootState) => state.job)
  const {selectedId} = job
  const {data: jobData, isLoading: jobDataIsLoading, isFetching: jobDataIsFetching} = useGetJobByIdQuery(selectedId, {skip:!selectedId})
  const initialData : ApiJobAdd = {
    description: "",
    jobTitle: "",
  };
  const [intialValues,setInitialValues ] = useState(initialData)
  useEffect(() =>{
    if(formType === "View" || formType === "Edit"){
      if(!jobDataIsFetching && !jobDataIsLoading){

        const dataJob = jobData?.data

        let jobDataList: ApiJobAdd = {
          description: dataJob?.description,
          jobTitle: dataJob?.jobTitle,
          
        }
        setInitialValues(jobDataList)
      }
    }
  }, [jobDataIsLoading, jobDataIsFetching, jobData, formType])

  return (
    <div>
      <Modal
          className="h-screen "
          open={open}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
        >
          <Fade in={open}>
      <Box className="fade-box">
        <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
          {formType} Job
        </div>
        <AddJobFormik
          formType={formType}
          handleClose={() => handleClose()}
          isLoading={addJobInfoApi.status === "pending" || updateJobApiInfo.isLoading}
          intialValues={intialValues}
          setInitialValues={setInitialValues}
          addApi={addJobApi}
          editApi={updateJobApi}
          selectedId={selectedId}
        
          
        />
      </Box>
      </Fade>
        </Modal> 
    </div>
  );
});

export default AddJobWrapper;
