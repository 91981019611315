import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ORGTableActionMenu from '../../components/UI/organisms/ORGTableActionMenu';
import { AppDispatch, RootState } from '../../redux/store';
import showToast from '../../utils/toast';
import MOLDeleteConfirmationDialog from '../../components/UI/molecules/MOLDeleteConfirmationDialog';
import { CommonTableColumnsPropTypes, CommonTableHeaderPropTypes } from '../../models/commonTypes';

import { setSelectedId, setCurrentPage, setRowsPerPage, setSearchValue, setIsTableLoading,  setItems, setTotalItems,  setIsVisibleDeleteConfirmationDialog, setIsVisibleViewSideBar, setIsVisibleEditSideBar } from '../../redux/slices/AdminUserSlices'
import {  useDeleteAdminUserByIdMutation, useGetAdminUserWithPaginationQuery } from '../../services/AdminUserService';
import { AdminUserItemsTypes } from '../../models/adminUser.model';
import AdminUserList from './AdminUser';
import AddAdminUserWrapper from '../../forms/AdminUser/AddAdminUserWrapper';

const AdminUserWrapper = () => {

    // **** State

    // **** Hooks
    const AdminUser: any = useSelector((state: RootState) => state.adminUser)
    const {
        searchValue,
        currentPage,
        rowsPerPage,
        isTableLoading,
        isVisibleEditSideBar,
        isVisibleViewSideBar,
        items,
        totalItems,
        selectedId,
        isVisibleDeleteConfirmationDialog,
        
    } = AdminUser

    const dispatch = useDispatch<AppDispatch>()

    // **** Services
    const { data, isFetching, isLoading } = useGetAdminUserWithPaginationQuery({
        page: currentPage + 1,
        limit: rowsPerPage,
        searchValue: searchValue.length >= 2 ? searchValue : "",
        params: [
            "_id",
        "fullName",
        "userName",
        "email",
        "mobile",
        "address",
        "userType",
        "is_active",
        "createdAt",
        "updatedAt"
        
        ],
        orderBy: ""
    })
    const [deleteFile] = useDeleteAdminUserByIdMutation()

    // **** Delete Handler
    const deleteHandler = () => {
        deleteFile(selectedId).then((res: any) => {
            if (res.data) {

                dispatch(setSelectedId(""))
                showToast(res.data?.message, res.data?.status ? "success" : "error");

            }
            if (res?.error) {
                showToast(res.error?.data?.message, "error")

            }
        }).catch((err: any) => {
            // console.log(err)
        })
        dispatch(setIsVisibleDeleteConfirmationDialog(false))
    }


    
    // **** Use Effect for set Playground Data
    useEffect(() => {
        if (!isFetching && !isLoading) {
            dispatch(setItems(data?.data || []))
            dispatch(setTotalItems(data?.totalItem || 0))
            dispatch(setIsTableLoading(false))

        } else {
            dispatch(setIsTableLoading(true))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, isLoading, data])

    // **** Table Columns Header
    const headers: CommonTableColumnsPropTypes[] = [
        {
            field: 'fullName',
            headerName: 'Full Name',
            flex: 1,
        },
        {
            field: 'userName',
            headerName: 'User Name',
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
        },
        {
            field: 'mobile',
            headerName: 'Contact Number',
            flex: 1,
        },
       
        {
            field: 'address',
            headerName: 'Address',
            flex: 1,
        },
      
       

        {
            field: 'actions',
            headerName: 'Actions',        
            flex: 40,
            extraClasses: " flex justify-end",
            renderCell: (row: AdminUserItemsTypes) => (
                <div className='flex justify-end pr-3 '>

                    <ORGTableActionMenu actions={{
                    //    activeDeactive: {
                    //     onClick: () => { dispatch(setIsVisibleActiveDeactiveConfirmationDialog(true)); setCurrentStatus(row.is_active ? true : false); dispatch(setSelectedId(row._id)) },
                    //     text: row.is_active ? "Deactive Job Application" : "Active Job Application"
                    // },
                        // delete: {
                        //     onClick: () => { dispatch(setSelectedId(row._id)); dispatch(setIsVisibleDeleteConfirmationDialog(true)) },
                        //     text: "Delete Admin User"
                        // },
                        view: {
                            onClick: () => { dispatch(setSelectedId(row._id)); dispatch(setIsVisibleViewSideBar(true)); },
                            text: "View "
                        },
                        edit: {
                            onClick: () => { dispatch(setSelectedId(row._id)); dispatch(setIsVisibleEditSideBar(true)); },
                            text: "Edit "
                        }

                    }}
                    />
                </div>
            )
        },
    ]

    // **** Table Header Props 
    const tableHeaderProps: CommonTableHeaderPropTypes = {
       
        search: {
            searchValue: searchValue,
            setSearchValue: (newValue: string) => { dispatch(setSearchValue(newValue))}
        }
    }

    // **** Table Props
    const tableProps = {
        headers,
        rows: items,
        page: currentPage,
        onPageChange: (newPage: number) => { dispatch(setCurrentPage(newPage))},
        rowsPerPage: rowsPerPage,
        onRowsPerPageChange: (newRowsPerPage: number) => { dispatch(setRowsPerPage(newRowsPerPage)); dispatch(setCurrentPage(0))},
        rowCount: totalItems,
        tableHeaderProps,
        isTableLoading,
    }

    return (
        <>
            <AdminUserList
                {...tableProps}
            />
            {
                isVisibleDeleteConfirmationDialog &&
                <MOLDeleteConfirmationDialog
                    onAccept={deleteHandler}
                    onDismiss={() => { dispatch(setIsVisibleDeleteConfirmationDialog(false)); dispatch(setSelectedId("")) }}
                />
            }
           
            {isVisibleEditSideBar && <AddAdminUserWrapper open={isVisibleEditSideBar} handleClose={() => dispatch(setIsVisibleEditSideBar(false))} formType="Edit" />}
            {isVisibleViewSideBar && <AddAdminUserWrapper open={isVisibleViewSideBar} handleClose={() => dispatch(setIsVisibleViewSideBar(false))} formType="View" />}

        </>
    )
}

export default AdminUserWrapper
