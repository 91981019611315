import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonExportApiType, CommonPaginationApiDataType } from '../models/commonTypes';
import { BASE_URL } from "../utils/constants";
const AUTH_TOKEN = localStorage.getItem("authToken")

const TOKEN = AUTH_TOKEN ? AUTH_TOKEN : ""

export const comboOfferApi = createApi({
    reducerPath: 'comboOfferApi',
    tagTypes: ['file', 'allFiles'],
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/combo-offers` }),
    endpoints: (builder) => ({

        // **** GET WITH PAGINATION
        GetComboOffersWithPagination: builder.query({
            providesTags: ['file'],
            query: (body: CommonPaginationApiDataType) => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body
            }),
        }),

        // **** ADD FileManager
        addComboOffers: builder.mutation({
            invalidatesTags: ['file'],
            query: (body: any
            ) => (
                {
                    url: `/add`,
                    headers: {
                        "x-access-token": TOKEN,
                    },
                    method: "POST",
                    body
                }
            ),
        }),

        // **** UPDATE FileManager
        updateComboOffers: builder.mutation({
            invalidatesTags: ['file'],
            query: ({ body, id }: any) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "PUT",
                body,
            }),
        }),


        // **** GET ALL FileManagers
        getAllComboOffers: builder.query({
            providesTags: ['allFiles'],
            query: () => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "GET",

            }),
        }),

        // **** GET BY ID
        getComboOffersById: builder.query({
            providesTags: ['file'],
            query: (id: string) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "GET",

            }),
        }),

        // **** GET BY ID
        changeComboOffersStatus: builder.mutation({
            invalidatesTags: ['file'],
            query: (id: string) => ({
                url: `/change-status/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "PUT",

            }),
        }),

        // **** DELETE BY ID
        DeleteComboOffersById: builder.mutation({
            invalidatesTags: ['file'],
            query: (id: string) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "DELETE",

            }),
        }),

        /** EXPORT ALL FileManager DATA */
        exportComboOffersData: builder.query({
            query: ({ searchValue, params, filterBy, rangeFilterBy, orderBy, orderByValue }: CommonExportApiType) => ({
                url: `/export-all`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body: { searchValue, params, filterBy, rangeFilterBy, orderBy, orderByValue },
            }),
        }),

    }),
})

export const
    {
        useGetAllComboOffersQuery,
        useGetComboOffersWithPaginationQuery,
        useGetComboOffersByIdQuery,
        useAddComboOffersMutation,
        useChangeComboOffersStatusMutation,
        useUpdateComboOffersMutation,
        useExportComboOffersDataQuery,
        useDeleteComboOffersByIdMutation,

    } = comboOfferApi