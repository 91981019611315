import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiUserAdd } from "../../models/user.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddUser from "./AddUser.tsx";
import {
  useAddUserMutation,
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from "../../services/UserService";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { setCurrentPage } from "../../redux/slices/UserSlices";
import { UserValidationSchema } from "../validationSchema/UserValidationSchema";

const AddUserFormik = withFormik<OtherProps & formikApiProps, ApiUserAdd>({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    let values: ApiUserAdd;
    switch (props.formType) {
      case "Add":
        values = props.intialValues;
        break;
      case "Edit":
        values = props.intialValues;
        break;
      case "View":
        values = props.intialValues;
        break;
      default:
        values = props.intialValues;
    }

    return values;
  },

  validationSchema : UserValidationSchema,
  
  handleSubmit(values, { props, setValues, setSubmitting }) {
    switch (props.formType) {
      case "Add":
        props.addApi(values).then((response: any) => {
          if (response?.data?.status) {
            // props.setInitialValues(props.intialValues)
            showToast(response?.data?.message, "success");
            store.dispatch(setCurrentPage(0));

            // setSubmitting(true)
            props.handleClose(false);
          } else {
            if (response?.error) {
              props.setInitialValues(values);
              showToast(response?.error?.data?.message, "error");
            } else {
              props.setInitialValues(values);
              showToast(response?.data?.message, "error");
            }
          }
        });
        break;
      case "Edit":
        let reqBody = {
          fullName: values.fullName,
          userName: values.userName,
          email: values.email,
          mobile: values.mobile,
          address: values.address,
        };

        setValues({ ...values });

        props
          .editApi({ body: reqBody, id: props.selectedId })
          .then((response: any) => {
            if (response?.data?.status) {
              showToast(response?.data?.message, "success");
              setSubmitting(true);
              props.handleClose();
            } else {
              if (response?.error) {
                props.setInitialValues(values);
                showToast(response?.error?.data?.message, "error");
              } else {
                props.setInitialValues(values);
                showToast(response?.data?.message, "error");
              }
            }
          });
        break;
      default:
        values = props.intialValues;
    }
  },
})(AddUser);

const AddUserWrapper = React.forwardRef((props: OtherProps, ref) => {
  const { open = false, handleClose, formType } = props;
  const [addUserApi, addUserInfoApi] = useAddUserMutation();
  const [updateUserApi, updateUserApiInfo] = useUpdateUserMutation();
  const user: any = useSelector((state: RootState) => state.user);
  const { selectedId } = user;
  const {
    data: userData,
    isLoading: userDataIsLoading,
    isFetching: userDataIsFetching,
  } = useGetUserByIdQuery(selectedId, { skip: !selectedId });
  const initialData: ApiUserAdd = {
    userName: "",
    fullName: "",
    mobile: "",
    address: "",
    email: "",
    confirm_password: "",
    password: "",


  };
  const [intialValues, setInitialValues] = useState(initialData);
  useEffect(() => {
    if (formType === "View" || formType === "Edit") {
      if (!userDataIsFetching && !userDataIsLoading) {
        const dataUser = userData?.data;

        let userDataList: ApiUserAdd = {
          userName: dataUser?.userName,
          fullName: dataUser?.fullName,
          mobile: dataUser?.mobile,
          address: dataUser?.address,
          email: dataUser?.email,
          confirm_password: dataUser?.confirm_password,
          password: dataUser?.password,

        };
        setInitialValues(userDataList);
      }
    }
  }, [userDataIsLoading, userDataIsFetching, userData, formType]);

  return (
    <div>
      <Modal
        className="h-screen "
        open={open}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="fade-box">
            <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
              {formType} User
            </div>
            <AddUserFormik
              formType={formType}
              handleClose={() => handleClose()}
              isLoading={
                addUserInfoApi.status === "pending" ||
                updateUserApiInfo.isLoading
              }
              intialValues={intialValues}
              setInitialValues={setInitialValues}
              addApi={addUserApi}
              editApi={updateUserApi}
              selectedId={selectedId}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
});

export default AddUserWrapper;
