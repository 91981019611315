import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ORGTableActionMenu from '../../components/UI/organisms/ORGTableActionMenu';
import { AppDispatch, RootState } from '../../redux/store';
import showToast from '../../utils/toast';
import MOLDeleteConfirmationDialog from '../../components/UI/molecules/MOLDeleteConfirmationDialog';
import { CommonTableColumnsPropTypes } from '../../models/commonTypes';

import { setSelectedId, setCurrentPage, setRowsPerPage, setIsTableLoading, setItems, setTotalItems, setIsVisibleActiveDeactiveConfirmationDialog, setIsVisibleDeleteConfirmationDialog } from '../../redux/slices/FeedbackSlices'
import { useChangeFeedbackStatusMutation, useDeleteFeedbackByIdMutation, useGetFeedbackWithPaginationQuery } from '../../services/FeedbackService';
import { FeedbackItemsTypes } from '../../models/feedback.model';
import FeedbackList from './Feedback';
import MOLChangeStatusConfirmationDialog from '../../components/UI/molecules/MOLChangeStatusConfirmationDialog';

const FeedbackWrapper = () => {

    // **** State
    const [currentStatus, setCurrentStatus] = useState(false)

    // **** Hooks
    const Feedback: any = useSelector((state: RootState) => state.feedback)
    const {
        searchValue,
        currentPage,
        rowsPerPage,
        isTableLoading,
        items,
        totalItems,
        selectedId,
        isVisibleDeleteConfirmationDialog,
        isVisibleActiveDeactiveConfirmationDialog
    } = Feedback

    const dispatch = useDispatch<AppDispatch>()

    // **** Services
    const { data, isFetching, isLoading } = useGetFeedbackWithPaginationQuery({
        page: currentPage + 1,
        limit: rowsPerPage,
        searchValue: searchValue.length >= 2 ? searchValue : "",
        params: [
            "_id",
        ],
        orderBy: ""
    })
    const [deleteFile] = useDeleteFeedbackByIdMutation()
    const [changeStatus, changeStatusInfo] = useChangeFeedbackStatusMutation()

    // **** Delete Handler
    const deleteHandler = () => {
        deleteFile(selectedId).then((res: any) => {
            if (res.data) {

                dispatch(setSelectedId(""))
                showToast(res.data?.message, res.data?.status ? "success" : "error");

            }
            if (res?.error) {
                showToast(res.error?.data?.message, "error")

            }
        }).catch((err: any) => {
            // console.log(err)
        })
        dispatch(setIsVisibleDeleteConfirmationDialog(false))
    }


    // **** Change Status Handler
    const changeStatusHandler = () => {
        changeStatus(selectedId)
        dispatch(setIsVisibleActiveDeactiveConfirmationDialog(false))
    }

    // **** Use Effect for set Playground Data
    useEffect(() => {
        if (!isFetching && !isLoading) {
            dispatch(setItems(data?.data || []))
            dispatch(setTotalItems(data?.totalItem || 0))
            dispatch(setIsTableLoading(false))

        } else {
            dispatch(setIsTableLoading(true))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, isLoading, data])

    // **** Table Columns Header
    const headers: CommonTableColumnsPropTypes[] = [

        {
            field: 'type',
            headerName: 'Type',
            flex: 1,
        },
        {
            field: 'personal_details',
            headerName: 'Personal Details',
            flex: 1,
        },
        {
            field: 'billing_details',
            headerName: 'Billing Details',
            flex: 1,
        },
        {
            field: 'vendor_details',
            headerName: 'Vendor Details',
            flex: 1,
        },
        {
            field: 'is_approved',
            headerName: 'Status',
            flex: 1,
            renderCell: (row: FeedbackItemsTypes) => (
                row.is_approved ? "Approved" : "Disapproved"
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 40,
            extraClasses: " flex justify-end",
            renderCell: (row: FeedbackItemsTypes) => (
                <div className='flex justify-end pr-3 '>

                    <ORGTableActionMenu actions={{
                        activeDeactive: {

                            onClick: () => { dispatch(setIsVisibleActiveDeactiveConfirmationDialog(true)); setCurrentStatus(row.is_approved ? true : false); dispatch(setSelectedId(row._id)) },
                            text: row.is_approved ? "Disapprove" : "Approve"
                        },
                    }}
                    />
                </div>
            )
        },
    ]

    // **** Table Header Props 


    // **** Table Props
    const tableProps = {
        headers,
        rows: items,
        page: currentPage,
        onPageChange: (newPage: number) => { dispatch(setCurrentPage(newPage)); changeStatusInfo.originalArgs = '' },
        rowsPerPage: rowsPerPage,
        onRowsPerPageChange: (newRowsPerPage: number) => { dispatch(setRowsPerPage(newRowsPerPage)); dispatch(setCurrentPage(0)); changeStatusInfo.originalArgs = '' },
        rowCount: totalItems,
        isTableLoading,
        renderRow: (row: FeedbackItemsTypes) => ({
            extraClasses: `${!row.is_approved && "bg-slate-300"} `,
        }),
    }

    return (
        <>
            <FeedbackList
                {...tableProps}
            />
            {
                isVisibleDeleteConfirmationDialog &&
                <MOLDeleteConfirmationDialog
                    onAccept={deleteHandler}
                    onDismiss={() => { dispatch(setIsVisibleDeleteConfirmationDialog(false)); dispatch(setSelectedId("")) }}
                />
            }
            {
                isVisibleActiveDeactiveConfirmationDialog &&
                <MOLChangeStatusConfirmationDialog
                    onAccept={changeStatusHandler}
                    onDismiss={() => { dispatch(setIsVisibleActiveDeactiveConfirmationDialog(false)); dispatch(setSelectedId("")) }}
                    message={`Do you really want to ${currentStatus ? 'Disapprove' : 'Approve'}`}
                    title={currentStatus ? 'Disapprove confirmation' : "Approve confirmation"}
                />
            }


        </>
    )
}
export default FeedbackWrapper
