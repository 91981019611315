import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ORGTableActionMenu from '../../components/UI/organisms/ORGTableActionMenu';
import { AppDispatch, RootState } from '../../redux/store';
import showToast from '../../utils/toast';
import MOLDeleteConfirmationDialog from '../../components/UI/molecules/MOLDeleteConfirmationDialog';
import { CommonTableColumnsPropTypes, CommonTableHeaderPropTypes } from '../../models/commonTypes';

import { setSelectedId, setCurrentPage, setRowsPerPage, setSearchValue, setIsTableLoading, setIsVisibleAddSideBar, setItems, setTotalItems, setIsVisibleActiveDeactiveConfirmationDialog, setIsVisibleDeleteConfirmationDialog, setIsVisibleViewSideBar, setIsVisibleEditSideBar } from '../../redux/slices/RecipeCategory'
import { useChangeRecipeCategoryStatusMutation, useDeleteRecipeCategoryByIdMutation, useGetRecipeCategoryWithPaginationQuery } from '../../services/RecipeCategoryService';
import { RecipeCategoryItemsTypes } from '../../models/recipeCategoryModel';
import RecipeCategoryList from './RecipeCategory';
import MOLChangeStatusConfirmationDialog from '../../components/UI/molecules/MOLChangeStatusConfirmationDialog';
import AddRecipeCategoryWrapper from '../../forms/recipeCategory/AddRecipeCategoryWrapper';

const RecipeCategoryWrapper = () => {

    // **** State
    const [currentStatus, setCurrentStatus] = useState(false)

    // **** Hooks
    const RecipeCategory: any = useSelector((state: RootState) => state.recipeCategory)
    const {
        searchValue,
        currentPage,
        rowsPerPage,
        isTableLoading,
        isVisibleAddSideBar,
        isVisibleEditSideBar,
        isVisibleViewSideBar,
        items,
        totalItems,
        selectedId,
        isVisibleDeleteConfirmationDialog,
        isVisibleActiveDeactiveConfirmationDialog
    } = RecipeCategory

    const dispatch = useDispatch<AppDispatch>()

    // **** Services
    const { data, isFetching, isLoading } = useGetRecipeCategoryWithPaginationQuery({
        page: currentPage + 1,
        limit: rowsPerPage,
        searchValue: searchValue.length >= 2 ? searchValue : "",
        params: [
            "category_name",          
        ],
        orderBy: ""
    })
    const [deleteFile] = useDeleteRecipeCategoryByIdMutation()
    const [changeStatus, changeStatusInfo] = useChangeRecipeCategoryStatusMutation()

    // **** Delete Handler
    const deleteHandler = () => {
        deleteFile(selectedId).then((res: any) => {
            if (res.data) {

                dispatch(setSelectedId(""))
                showToast(res.data?.message, res.data?.status ? "success" : "error");

            }
            if (res?.error) {
                showToast(res.error?.data?.message, "error")

            }
        }).catch((err: any) => {
            // console.log(err)
        })
        dispatch(setIsVisibleDeleteConfirmationDialog(false))
    }


    // **** Change Status Handler
    const changeStatusHandler = () => {
        changeStatus(selectedId)
        dispatch(setIsVisibleActiveDeactiveConfirmationDialog(false))
    }

    // **** Use Effect for set Playground Data
    useEffect(() => {
        if (!isFetching && !isLoading) {
            dispatch(setItems(data?.data || []))
            dispatch(setTotalItems(data?.totalItem || 0))
            dispatch(setIsTableLoading(false))

        } else {
            dispatch(setIsTableLoading(true))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, isLoading, data])

    // **** Table Columns Header
    const headers: CommonTableColumnsPropTypes[] = [
        {
            field: 'category_name',
            headerName: 'Recipe Category ',
            flex: 1,

        },
        {
            field: 'order',
            headerName: 'Order ',
            flex: 1,

        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (row: RecipeCategoryItemsTypes) => (
                row.is_active ? "Active" : "Deactive"
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 40,
            extraClasses: " flex justify-end",
            renderCell: (row: RecipeCategoryItemsTypes) => (
                <div className='flex justify-end pr-3 '>

                    <ORGTableActionMenu actions={{
                       activeDeactive: {
                        
                        onClick: () => { dispatch(setIsVisibleActiveDeactiveConfirmationDialog(true)); setCurrentStatus(row.is_active ? true : false); dispatch(setSelectedId(row._id)) },
                        text: row.is_active ? "Deactive " : "Active "
                    },
                        delete: {
                            onClick: () => { dispatch(setSelectedId(row._id)); dispatch(setIsVisibleDeleteConfirmationDialog(true)) },
                            text: "Delete "
                        },
                        view: {
                            onClick: () => { dispatch(setSelectedId(row._id)); dispatch(setIsVisibleViewSideBar(true)); },
                            text: "View "
                        },
                        edit: {
                            onClick: () => { dispatch(setSelectedId(row._id)); dispatch(setIsVisibleEditSideBar(true)); },
                            text: "Edit "
                        }

                    }}
                    />
                </div>
            )
        },
    ]

    // **** Table Header Props 
    const tableHeaderProps: CommonTableHeaderPropTypes = {
        addBtn: {
            onClickHandler: () => { dispatch(setIsVisibleAddSideBar(true)); },
            btnName: 'Add'
        },
        search: {
            searchValue: searchValue,
            setSearchValue: (newValue: string) => { dispatch(setSearchValue(newValue)); changeStatusInfo.originalArgs = '' }
        }
    }

    // **** Table Props
    const tableProps = {
        headers,
        rows: items,
        page: currentPage,
        onPageChange: (newPage: number) => { dispatch(setCurrentPage(newPage)); changeStatusInfo.originalArgs = '' },
        rowsPerPage: rowsPerPage,
        onRowsPerPageChange: (newRowsPerPage: number) => { dispatch(setRowsPerPage(newRowsPerPage)); dispatch(setCurrentPage(0)); changeStatusInfo.originalArgs = '' },
        rowCount: totalItems,
        tableHeaderProps,
        isTableLoading,
        renderRow: (row: RecipeCategoryItemsTypes) => ({
            extraClasses: `${!row.is_active && "bg-slate-300"} `,
            }),
    }

    return (
        <>
            <RecipeCategoryList
                {...tableProps}
            />
            {
                isVisibleDeleteConfirmationDialog &&
                <MOLDeleteConfirmationDialog
                    onAccept={deleteHandler}
                    onDismiss={() => { dispatch(setIsVisibleDeleteConfirmationDialog(false)); dispatch(setSelectedId("")) }}
                />
            }
            {
                isVisibleActiveDeactiveConfirmationDialog &&
                <MOLChangeStatusConfirmationDialog
                    onAccept={changeStatusHandler}
                    onDismiss={() => { dispatch(setIsVisibleActiveDeactiveConfirmationDialog(false)); dispatch(setSelectedId("")) }}
                    message={`Do you really want to ${currentStatus ? 'Deactivate' : 'Active'}`}
                    title={currentStatus ? 'Deactivate confirmation' : "Active confirmation"}
                />
            }
            {isVisibleAddSideBar && <AddRecipeCategoryWrapper open={isVisibleAddSideBar} handleClose={()=>dispatch(setIsVisibleAddSideBar(false))} formType="Add" /> } 
            {isVisibleEditSideBar && <AddRecipeCategoryWrapper open={isVisibleEditSideBar} handleClose={() => dispatch(setIsVisibleEditSideBar(false))} formType="Edit" />}
            {isVisibleViewSideBar && <AddRecipeCategoryWrapper open={isVisibleViewSideBar} handleClose={() => dispatch(setIsVisibleViewSideBar(false))} formType="View" />}

        </>
    )
}
export default RecipeCategoryWrapper
