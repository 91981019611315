import React from 'react'
import { TextField, InputAdornment } from '@mui/material'
import { ATMInputPropType } from './ATMInputPropType'

const ATMInput = ({
    size = "small",
    placeholder = "",
    variant = "standard",
    extraClasses = "",
    onChange = () => { },
    name,
    value,
    required = false,
    inputType = "text",
    label,
    isValid = false,
    errorMessage,
    disabled=false,
    innerText="",
    readOnly=false,
    autofocus=false,
    hidden=false
}: ATMInputPropType) => {
    return (
        <>
            <div className="mb-2">
                {required ?
                    <p className='font-medium'>{label}<span className='text-danger ps-3'>*</span></p>
                    :
                    <p className='font-medium'>{label}</p>
                }
            </div>
            <TextField
                autoComplete="off"
                size={size}
                placeholder={placeholder}
                variant={variant}
                className={extraClasses}
                onChange={(e)=>onChange(e)}
              
                name={name}
                value={value}
                type={inputType}
                disabled={disabled ? true : false}
                InputProps={{
                    hidden : hidden,
                    readOnly:readOnly,
                    autoFocus:autofocus,
                    endAdornment: <InputAdornment position="end">{innerText}</InputAdornment>,
                }}
            />
            <p className='text-red-400 mt-1'>{isValid ? "" : errorMessage}</p>
        </>
    )
}
export default ATMInput
