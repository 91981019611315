import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { ATMMultiSelectPropType } from './ATMMultiSelectPropType';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { OptionType } from './ATMMultiSelectPropType';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ATMMultiSelect = ({
  options,
  onChange = () => { },
  extraClasses = "",
  label,
  isValid,
  errorMessage = "",
  required = false,
  helperText = "",
  name,
  placeholder="",
  disabled=false,
  defaultValue=[],
  readOnly=false
}: ATMMultiSelectPropType) => {
  return (
    <div>
      <div className="mb-2">
        {required ?
          <p className='font-medium'>{label}<span className='text-danger ps-3'>*</span></p>
          :
          <p className="font-medium">{label}</p>
        }
      </div>
      <Autocomplete
        multiple
        onChange={(event, newValue) => {
         onChange(event,newValue)
        }}
        options={options}
        disabled={disabled}
        readOnly={readOnly}
        className="border rounded hover:border-black"
        disableCloseOnSelect
        defaultValue={defaultValue}
        value={defaultValue}
        getOptionLabel={(option:OptionType) => {
          return option?.selectLabel}
        }
        isOptionEqualToValue={(option,value:any)=>option?.value===value.productId}
        renderOption={(props, option, { selected }) => {

          return <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.selectLabel}
          </li>
        }}
        renderInput={(params) => (
          <TextField  {...params} placeholder={placeholder} variant="outlined"  />
        )}
      />


      <p className="text-red-400">{isValid ? <span></span> : errorMessage}</p>
    </div>
  );
}

export default ATMMultiSelect
