import DashboardTodayEnquiryList from '../../../screens/Dashboard/DashboardTodayEnquiryList'
import Header from '../headerLayout/Header'
const DashboardLayout = () => {
    return (
        <div>

            <Header/>
            <DashboardTodayEnquiryList />
            {/* <EnquiryWrapper/> */}
            {/* <Dashboard/> */}

        </div >
    )
}

export default DashboardLayout

