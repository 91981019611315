import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiInstitutionalProductAdd } from "../../models/institutionalProduct.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddInstitutionalProduct from "./AddInstitutionalProduct";
import { InstitutionalProductValidationSchema } from "../validationSchema/InstitutionalProductValidationSchema";
import { useAddInstitutionalProductMutation, useGetInstitutionalProductByIdQuery, useUpdateInstitutionalProductMutation } from "../../services/InstitutionalProductService";
import {  useSelector } from "react-redux";
import store, {  RootState } from "../../redux/store";
import { setCurrentPage } from "../../redux/slices/InstitutionalProductSlices";



const AddInstitutionalProductFormik = withFormik<OtherProps & formikApiProps,ApiInstitutionalProductAdd>({
  enableReinitialize:true,
    mapPropsToValues: (props) => {
      let values: ApiInstitutionalProductAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues
          break;
        case "Edit":
            values = props.intialValues
          break;
        case "View":
            values = props.intialValues
          break;
        default:
            values = props.intialValues
      }

      return values;
    },

    validationSchema : InstitutionalProductValidationSchema,
    
    handleSubmit(values ,{props,setValues,setSubmitting}) {
      switch (props.formType) {
        case 'Add':
        props.addApi(values).then((response: any) => {
          if (response?.data?.status) {
            // props.setInitialValues(props.intialValues)
            showToast(response?.data?.message, "success")
              store.dispatch(setCurrentPage(0))
            // setSubmitting(true)
            props.handleClose(false)
          } else {
            if (response?.error) {
              props.setInitialValues(values)
              showToast(response?.error?.data?.message, "error")
            } else {
              props.setInitialValues(values)
              showToast(response?.data?.message, "error")
            }
          }

        }

        )
          break
          case 'Edit':
            let reqBody = {
              name: values.name,
              imageUrl: values.imageUrl,
              shortDescription : values.shortDescription,
              longDescription : values.longDescription,
            }
    
            setValues({ ...values })
    
            props.editApi({ body: reqBody, id: props.selectedId }).then((response: any) => {
              if (response?.data?.status) {
                showToast(response?.data?.message, "success")
                setSubmitting(true)
                props.handleClose()
              } else {
                if (response?.error) {
                  props.setInitialValues(values)
                  showToast(response?.error?.data?.message, "error")
                } else {
                  props.setInitialValues(values)
                  showToast(response?.data?.message, "error")
                }
              }
            }
            )
            break
          default: values = props.intialValues
    
        }
      },
  })(AddInstitutionalProduct);


const AddInstitutionalProductWrapper = React.forwardRef((props: OtherProps, ref) => {
  const {open=false, handleClose, formType } = props;
  const [addInstitutionalProductApi,addInstitutionalProductInfoApi ] = useAddInstitutionalProductMutation()
  const [updateInstitutionalProductApi, updateInstitutionalProductApiInfo]= useUpdateInstitutionalProductMutation()
  const institutionalProduct : any = useSelector((state:RootState) => state.institutionalProduct)
  const {selectedId} = institutionalProduct
  const {data: institutionalProductData, isLoading: institutionalProductDataIsLoading, isFetching: institutionalProductDataIsFetching} = useGetInstitutionalProductByIdQuery(selectedId, {skip:!selectedId})
  const initialData : ApiInstitutionalProductAdd = {
    name: "",
    imageUrl: "",
    shortDescription :"",
    longDescription:"",
  };
  const [intialValues,setInitialValues ] = useState(initialData)
  useEffect(() =>{
    if(formType === "View" || formType === "Edit"){
      if(!institutionalProductDataIsFetching && !institutionalProductDataIsLoading){

        const dataInstitutionalProduct = institutionalProductData?.data

        let institutionalProductDataList: ApiInstitutionalProductAdd = {
          name: dataInstitutionalProduct?.name,
          imageUrl: dataInstitutionalProduct?.imageUrl,
          shortDescription : dataInstitutionalProduct?.shortDescription,
          longDescription :dataInstitutionalProduct?.longDescription,
        }
        setInitialValues(institutionalProductDataList)
      }
    }
  }, [institutionalProductDataIsLoading, institutionalProductDataIsFetching, institutionalProductData, formType])

  return (
    <div>
      <Modal
          className="h-screen "
          open={open}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
        >
          <Fade in={open}>
          <Box className="fade-box" sx={{ width: "40%" }}>
        <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
          {formType} Institutional Product
        </div>
        <AddInstitutionalProductFormik
          formType={formType}
          handleClose={() => handleClose()}
          isLoading={addInstitutionalProductInfoApi.status === "pending" || updateInstitutionalProductApiInfo.isLoading}
          intialValues={intialValues}
          setInitialValues={setInitialValues}
          addApi={addInstitutionalProductApi}
          editApi={updateInstitutionalProductApi}
          selectedId={selectedId}
        
          
        />
      </Box>
      </Fade>
        </Modal> 
    </div>
  );
});

export default AddInstitutionalProductWrapper;
