import React, { useEffect, useState } from "react";
import {  withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiRecipeAdd } from "../../models/recipe.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddRecipe from "./AddRecipe";
import {
  useAddRecipeMutation,
  useGetRecipeByIdQuery,
  useUpdateRecipeMutation,
} from "../../services/RecipeService";
import {   useSelector } from "react-redux";
import store, {   RootState } from "../../redux/store";
import { useGetRecipeCategoryWithPaginationQuery } from "../../services/RecipeCategoryService";
import { setCurrentPage } from "../../redux/slices/RecipeSlices";
import { RecipeValidationSchema } from "../validationSchema/RecipeValidationSchema";

const AddRecipeFormik = withFormik<OtherProps & formikApiProps, ApiRecipeAdd>(
  {
    enableReinitialize: true,
    mapPropsToValues: (props) => {
      let values: ApiRecipeAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues;
          break;
        case "Edit":
          values = props.intialValues;
          break;
        case "View":
          values = props.intialValues;
          break;
        default:
          values = props.intialValues;
      }

      return values;
    },
    validationSchema : RecipeValidationSchema,
    handleSubmit(values, { props, setValues, setSubmitting }) {
      switch (props.formType) {
        case "Add":
         
          props.addApi(values).then((response: any) => {
            if (response?.data?.status) {
              // props.setInitialValues(props.intialValues)
              showToast(response?.data?.message, "success");
              store.dispatch(setCurrentPage(0))


              // setSubmitting(true)
              props.handleClose(false);
            } else {
              if (response?.error) {
                props.setInitialValues(values);
                showToast(response?.error?.data?.message, "error");
              } else {
                props.setInitialValues(values);
                showToast(response?.data?.message, "error");
              }
            }
          });
          break;
        case "Edit":
          let reqBody = {
            imageUrl:values.imageUrl,
            title:values.title,
            recipe_category:values.recipe_category,   
            slug:values.slug,
            method:values.method,
            ingredients:values.ingredients,
            images:values.images
          };

          setValues({ ...values });

          props
            .editApi({ body: reqBody, id: props.selectedId })
            .then((response: any) => {
              if (response?.data?.status) {
                showToast(response?.data?.message, "success");
                setSubmitting(true);
                props.handleClose();
              } else {
                if (response?.error) {
                  props.setInitialValues(values);
                  showToast(response?.error?.data?.message, "error");
                } else {
                  props.setInitialValues(values);
                  showToast(response?.data?.message, "error");
                }
              }
            });
          break;
        default:
          values = props.intialValues;
      }
    },
  }
)(AddRecipe);

const AddRecipeWrapper = React.forwardRef((props: OtherProps, ref) => {
  const { open = false, handleClose, formType } = props;
  const [addRecipeApi, addRecipeInfoApi] = useAddRecipeMutation();
  const [updateRecipeApi, updateRecipeApiInfo] = useUpdateRecipeMutation();
  const recipe: any = useSelector((state: RootState) => state.recipe);
  const { selectedId } = recipe;
  const {
    data: recipeData,
    isLoading: recipeDataIsLoading,
    isFetching: recipeDataIsFetching,
  } = useGetRecipeByIdQuery(selectedId, { skip: !selectedId });

  const {
    data: recipeCategoryData,
    isLoading: recipeCategoryDataIsLoading,
    isFetching: recipeCategoryDataIsFetching,
  } = useGetRecipeCategoryWithPaginationQuery({ 
    page: 0,
    limit: 0,
    searchValue: "",
    params: [
      
      "category_name",
    ],
    orderBy: "",
        isPaginationRequired: false})

  const initialData: ApiRecipeAdd = {
    imageUrl: "",
    title: "",
    recipe_category:"",
    slug:"",
    method:"",
    ingredients:"",
    images:[""],
    channel_name : "",
    link : "",
    video_description : "",

  };
  const [intialValues, setInitialValues] = useState(initialData);

  const [brandOption, setBrandOption] = useState([
    {
      selectLabel: "",
      value: "",
    },
  ]);


  useEffect(() => {
    if (!recipeCategoryDataIsLoading && !recipeCategoryDataIsFetching) {
      let brandOptions = recipeCategoryData?.data?.filter((data: { is_active: boolean; }) => data.is_active === true).map((ele :any)=>{
        return {
          selectLabel: ele.category_name,
            value: ele._id
        }
      })  
      setBrandOption(brandOptions);
    }

  }, [recipeCategoryData, recipeCategoryDataIsLoading, recipeCategoryDataIsFetching]);



  useEffect(() => {
    if (formType === "View" || formType === "Edit") {
      if (!recipeDataIsFetching && !recipeDataIsLoading) {
        const dataRecipe = recipeData?.data;

        let recipeDataList: ApiRecipeAdd = {
          imageUrl: dataRecipe?.imageUrl,
          recipe_category: dataRecipe?.recipe_category,
          title: dataRecipe?.title,
          slug:dataRecipe?.slug,
          method : dataRecipe?.method,
          ingredients :dataRecipe?.ingredients,
          images : dataRecipe?.images,
          channel_name : dataRecipe?.channel_name,
          link : dataRecipe?.link,
          video_description : dataRecipe?.video_description ,
          
        };
        setInitialValues(recipeDataList);
      }
    }
  }, [recipeDataIsLoading, recipeDataIsFetching, recipeData, formType]);

  return (
    <div>
      <Modal
        className="h-screen "
        open={open}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
      >
        <Fade in={open}>
        <Box className="fade-box" sx={{ width: "40%" }}>
            <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
              {formType} Recipe
            </div>
            <AddRecipeFormik
              formType={formType}
              handleClose={() => handleClose()}
              isLoading={
                addRecipeInfoApi.status === "pending" ||
                updateRecipeApiInfo.isLoading
              }
              intialValues={intialValues}
              setInitialValues={setInitialValues}
              addApi={addRecipeApi}
              editApi={updateRecipeApi}
              selectedId={selectedId}
              recipecategoryListSingle={brandOption}
          
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
});

export default AddRecipeWrapper;
