import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MOLDeleteConfirmationDialog from "../../components/UI/molecules/MOLDeleteConfirmationDialog";
import {
  CommonTableColumnsPropTypes,
  CommonTableHeaderPropTypes,
} from "../../models/commonTypes";
import { AppDispatch, RootState } from "../../redux/store";
import showToast from "../../utils/toast";

import { useNavigate } from "react-router-dom";
import AddOderWrapper from "../../forms/order/AddOrderWrapper";
import { OrderItemsTypes } from "../../models/order.model";
import {
  setCurrentPage,
  setIsTableLoading,
  setIsVisibleDeleteConfirmationDialog,
  setIsVisibleViewSideBar,
  setItems,
  setRowsPerPage,
  setSearchValue,
  setSelectedId,
  setTotalItems,
} from "../../redux/slices/OrderSlice";
import {
  useChangeOrderStatusMutation,
  useDeleteOrderByIdMutation,
  useGetOrderWithPaginationQuery,
} from "../../services/OrderService";
import OrderList from "./Order";

const OrderWrapper = () => {
  // **** State

  // **** Hooks
  const order: any = useSelector((state: RootState) => state.order);
  const {
    searchValue,
    currentPage,
    rowsPerPage,
    isTableLoading,
    isVisibleViewSideBar,
    items,
    totalItems,
    selectedId,
    isVisibleDeleteConfirmationDialog,
  } = order;

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate()
  // **** Services
  const { data, isFetching, isLoading } = useGetOrderWithPaginationQuery({
    page: currentPage + 1,
    limit: rowsPerPage,
    searchValue: searchValue.length >= 2 ? searchValue : "",
    params: [
      "user_billing_details.fullName",
      "user_billing_details.email",
    ],
    orderBy: "",
  });
  const [deleteFile] = useDeleteOrderByIdMutation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [changeStatus, changeStatusInfo] = useChangeOrderStatusMutation();

  // **** Delete Handler
  const deleteHandler = () => {
    deleteFile(selectedId)
      .then((res: any) => {
        if (res.data) {
          dispatch(setSelectedId(""));
          showToast(res.data?.message, res.data?.status ? "success" : "error");
        }
        if (res?.error) {
          showToast(res.error?.data?.message, "error");
        }
      })
      .catch((err: any) => {
        // console.log(err)
      });
    dispatch(setIsVisibleDeleteConfirmationDialog(false));
  };

  // **** Change Status Handler

  // **** Use Effect for set ContactUs Data
  useEffect(() => {
    if (!isFetching && !isLoading) {
      dispatch(setItems(data?.data || []));
      dispatch(setTotalItems(data?.totalItem || 0));
      dispatch(setIsTableLoading(false));
    } else {
      dispatch(setIsTableLoading(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, isLoading, data]);

  // **** Table Columns Header
  const headers: CommonTableColumnsPropTypes[] = [
    {
      field: "order_unique_id",
      headerName: "Order Unique ID",
      flex: 1,
    },
    {
      field: "user_billing_details",
      headerName: "Name",
      flex: 1,
      renderCell: (row: OrderItemsTypes) => {
        return <h1> {row.user_billing_details.fullName || '-'} </h1>;
      },
    },
    {
      field: "user_billing_details",
      headerName: "Email",
      flex: 1,
      renderCell: (row: OrderItemsTypes) => {
        return <h1> {row.user_billing_details.email} </h1>;
      },
    },
    {
      field: "user_billing_details",
      headerName: "Phone",
      flex: 1,
      renderCell: (row: OrderItemsTypes) => {
        return <h1> {row.user_billing_details.Phone} </h1>;
      },
    },
    {
      field: "total_product_quantity",
      headerName: "Total Quantity",
      flex: 1,
    },
    {
      field: "order_total_amount",
      headerName: "Total Amount",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },

  ];

  // **** Table Header Props
  const tableHeaderProps: CommonTableHeaderPropTypes = {
    search: {
      searchValue: searchValue,
      setSearchValue: (newValue: string) => {
        dispatch(setSearchValue(newValue));
        changeStatusInfo.originalArgs = "";
      },
    },
  };

  // **** Table Props
  const tableProps = {
    headers,
    rows: items,
    page: currentPage,
    onPageChange: (newPage: number) => {
      dispatch(setCurrentPage(newPage));
      changeStatusInfo.originalArgs = "";
    },
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (newRowsPerPage: number) => {
      dispatch(setRowsPerPage(newRowsPerPage));
      dispatch(setCurrentPage(0));
      changeStatusInfo.originalArgs = "";
    },
    rowCount: totalItems,
    tableHeaderProps,
    isTableLoading,
    onRowClick: (row: any) => navigate(`/order/${row.order_unique_id}`),
    renderRow: (row: OrderItemsTypes) => ({
      extraClasses: ` ${!row.is_active && "bg-slate-300"} `,
    }),
  };

  return (
    <>
      <OrderList {...tableProps} />
      {isVisibleDeleteConfirmationDialog && (
        <MOLDeleteConfirmationDialog
          onAccept={deleteHandler}
          onDismiss={() => {
            dispatch(setIsVisibleDeleteConfirmationDialog(false));
            dispatch(setSelectedId(""));
          }}
        />
      )}

      {isVisibleViewSideBar && (
        <AddOderWrapper
          open={isVisibleViewSideBar}
          handleClose={() => dispatch(setIsVisibleViewSideBar(false))}
          formType="View"
        />
      )}
    </>
  );
};

export default OrderWrapper;
