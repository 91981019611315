import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import {  Paper } from "@mui/material";
import { UpdateAdminUserPassword } from "../../models/adminUser.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import UpdatePasswordForm from "./UpdatePasswordForm";
import {
  
  useUpdateAdminUserPasswordMutation
} from "../../services/AdminUserService";

import Header from "../../components/LayoutTemplates/headerLayout/Header";

const AddAdminUserFormik = withFormik<OtherProps & formikApiProps,UpdateAdminUserPassword>({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    let values: UpdateAdminUserPassword;
        values = props.intialValues;
    return values;
  },


  handleSubmit(values, { props, setValues, setSubmitting }) {
        let reqBody = {
          new_password: values.new_password,
          confirm_password: values.confirm_password,
        };

        setValues({ ...values });

        props
          .editApi(reqBody)
          .then((response: any) => {
            if (response?.data?.status) {
              showToast(response?.data?.message, "success");
              setSubmitting(true);
              props.handleClose();
            } else {
              if (response?.error) {
                props.setInitialValues(values);
                showToast(response?.error?.data?.message, "error");
              } else {
                props.setInitialValues(values);
                showToast(response?.data?.message, "error");
              }
            }
          });

  },
})(UpdatePasswordForm);

const UpdatePasswordWrapper = React.forwardRef((props: OtherProps, ref) => {
  const { handleClose } = props;
  const [updateAdminUserApi, updateAdminUserApiInfo] =
  useUpdateAdminUserPasswordMutation();

  const initialData: UpdateAdminUserPassword = {
    new_password: " ",
    confirm_password: " ",
  };
  const [intialValues, setInitialValues] = useState(initialData);
  useEffect(() => {
    let adminUserDataList: UpdateAdminUserPassword = {

      new_password: "",
      confirm_password: "",
    };
    setInitialValues(adminUserDataList);

  },[])

  return (
    <div>
      <Header />
      <div className="flex justify-center w-full mt-20  ">
        <Paper className=" w-4/12	py-5 border ">
          <div className="w-full p-3  items-center justify-center flex mt-3 ">
            <div className="w-3/4">
              <div className="text-xl font-semibold text-slate-600 text-center mb-10">
                UPDATE PASSWORD
              </div>
              <AddAdminUserFormik
                handleClose={() => handleClose()}
                isLoading={
                 
                  updateAdminUserApiInfo.isLoading
                }
                intialValues={intialValues}
                setInitialValues={setInitialValues}
                editApi={updateAdminUserApi}
              />
            </div>
          </div>
        </Paper>
      </div>{" "}
    </div>
  );
});

export default UpdatePasswordWrapper;
