import React from "react";
import { Form, FormikProps } from "formik";
import {  ATMButton, ATMInput } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiTransactionAdd } from "../../models/transaction.model";

const AddTransaction = (props: OtherProps & FormikProps<ApiTransactionAdd>) => {
  const { touched, errors,  handleChange, handleClose, values, formType, isLoading } =
    props;
  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
           <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Username "
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              value={values.userName}
              name="userName"
              label="Username "
              readOnly={formType === "View" ? true : false}
              required={false}
              isValid={touched.userName ? !touched.userName : true}
              errorMessage={errors.userName}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Amount "
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              value={values.amount}
              name="amount"
              label="Amount "
              readOnly={formType === "View" ? true : false}
              required={false}
              isValid={touched.amount ? !touched.amount : true}
              errorMessage={errors.amount}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Status"
              variant="outlined"
              extraClasses="w-full"
              value ={ values.status}
              onChange={handleChange}
              name="status"
              label="status"
              required={false}              
              readOnly={formType === "View" ? true : false}
              isValid={touched.status ? !touched.status : true}
              errorMessage={errors.status}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Type "
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              value={values.type}
              name="type"
              label="Type  "
              readOnly={formType === "View" ? true : false}
              required={false}
              isValid={touched.type ? !touched.type : true}
              errorMessage={errors.type}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Payment Gateway "
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              value={values.payment_gateway}
              name="payment_gateway"
              label="Payment Gateway  "
              readOnly={formType === "View" ? true : false}
              required={false}
              isValid={touched.payment_gateway ? !touched.payment_gateway : true}
              errorMessage={errors.payment_gateway}
            />
          </div>
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses='hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer'
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton

            extraClasses='py-3 border bg-red-500 hover:bg-red-600 text-white'
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddTransaction;
