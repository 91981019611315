import { Button, Grid, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { CommonTableHeaderPropTypes } from '../../../models/commonTypes';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { CSVLink } from "react-csv";
import { LoadingButton } from '@mui/lab';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const MOLTableHeader = ({
    exportBtn,
    search,
    addBtn,
    filterBtn,

}: CommonTableHeaderPropTypes
) => {


    return (
        <Grid container justifyContent="space-between" spacing={3}>

            {/* Search Input */}
            <Grid container spacing={2} item xs={6}>
                {
                    search &&
                    <Grid item  >
                        <TextField value={search.searchValue} onChange={(e: any) => { search.setSearchValue(e.target.value) }} size="small" placeholder="Search..." autoFocus InputProps={{
                            startAdornment: <InputAdornment position="start"> <SearchIcon /> </InputAdornment>,
                        }} />
                    </Grid>
                }
                {
                    filterBtn &&
                    <>
                        <Grid item  >
                            <Button sx={{ pb: 1 }} className={`${filterBtn.extraClasses}`} fullWidth variant={filterBtn.isShowClearButton ? 'contained' : "outlined"} color='primary' onClick={() => filterBtn.onClick()}> {filterBtn.isShowClearButton ? <FilterAltIcon /> : <FilterAltOutlinedIcon />}  {filterBtn.btnName} </Button>
                        </Grid>
                        {
                            filterBtn.isShowClearButton &&
                            <Grid item>
                                <Button sx={{ pb: 1 }} color='primary' onClick={() => filterBtn.onClear()}> {filterBtn.clearBtnText || "Clear"} </Button>
                            </Grid>
                        }
                    </>

                }
            </Grid>

            <Grid container item xs={6} justifyContent="flex-end" spacing={2} >

                {/* Export Button */}
                {
                    exportBtn &&
                    < Grid item >

                        <CSVLink
                            data={exportBtn.data || []}
                            headers={exportBtn.headers}
                            filename={exportBtn.fileName}
                            ref={exportBtn.csvRef}
                        />

                        <LoadingButton
                            loading={exportBtn.loading}
                            loadingPosition="start"
                            startIcon={<FileDownloadOutlinedIcon />}
                            variant='contained'
                            color='inherit'
                            onClick={() => exportBtn.onClickHandler()}
                        >
                            {exportBtn.btnName}
                        </LoadingButton>

                    </Grid>
                }

                {/* Add Button */}
                {
                    addBtn &&
                    <Grid item >
                        <Button onClick={() => { addBtn.onClickHandler() }} variant="contained" > <div className={`${addBtn.extraClasses || ''}`} > {addBtn.btnName} </div> </Button>
                    </Grid>
                }
            </Grid>

        </Grid >
    )
}

export default MOLTableHeader
