import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiGalleryCategoryAdd } from "../../models/galleryCategory.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddGalleryCategory from "./AddGalleryCategory";
import { GalleryCategoryValidationSchema } from "../validationSchema/GalleryCategoryValidation";
import { useAddGalleryCategoryMutation, useGetGalleryCategoryByIdQuery, useUpdateGalleryCategoryMutation } from "../../services/GalleryCategoryService";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { setCurrentPage } from "../../redux/slices/GalleryCategorySlices";


const AddGalleryCategoryFormik = withFormik<OtherProps & formikApiProps,ApiGalleryCategoryAdd>({
  enableReinitialize:true,
    mapPropsToValues: (props) => {
      let values: ApiGalleryCategoryAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues
          break;
        case "Edit":
            values = props.intialValues
          break;
        case "View":
            values = props.intialValues
          break;
        default:
            values = props.intialValues
      }

      return values;
    },

    validationSchema : GalleryCategoryValidationSchema,

    handleSubmit(values ,{props,setValues,setSubmitting}) {
      switch (props.formType) {
        case 'Add':
        props.addApi(values).then((response: any) => {
          if (response?.data?.status) {
            // props.setInitialValues(props.intialValues)
            showToast(response?.data?.message, "success")
            store.dispatch(setCurrentPage(0))

            // setSubmitting(true)
            props.handleClose(false)
          } else {
            if (response?.error) {
              props.setInitialValues(values)
              showToast(response?.error?.data?.message, "error")
            } else {
              props.setInitialValues(values)
              showToast(response?.data?.message, "error")
            }
          }

        }

        )
          break
          case 'Edit':
            let reqBody = {
              category_name: values.category_name,
              order : values.order,
            }
    
            setValues({ ...values })
    
            props.editApi({ body: reqBody, id: props.selectedId }).then((response: any) => {
              if (response?.data?.status) {
                showToast(response?.data?.message, "success")
                setSubmitting(true)
                props.handleClose()
              } else {
                if (response?.error) {
                  props.setInitialValues(values)
                  showToast(response?.error?.data?.message, "error")
                } else {
                  props.setInitialValues(values)
                  showToast(response?.data?.message, "error")
                }
              }
            }
            )
            break
          default: values = props.intialValues
    
        }
      },
  })(AddGalleryCategory);


const AddGalleryCategoryWrapper = React.forwardRef((props: OtherProps, ref) => {
  const {open=false, handleClose, formType } = props;
  const [addGalleryCategoryApi,addGalleryCategoryInfoApi ] = useAddGalleryCategoryMutation()
  const [updateGalleryCategoryApi, updateGalleryCategoryApiInfo]= useUpdateGalleryCategoryMutation()
  const galleryCategory : any = useSelector((state:RootState) => state.galleryCategory)
  const {selectedId} = galleryCategory
  const {data: galleryCategoryData, isLoading: galleryCategoryDataIsLoading, isFetching: galleryCategoryDataIsFetching} = useGetGalleryCategoryByIdQuery(selectedId, {skip:!selectedId})
  const initialData : ApiGalleryCategoryAdd = {
    category_name: "",
    order: "",
  };
  const [intialValues,setInitialValues ] = useState(initialData)
  useEffect(() =>{
    if(formType === "View" || formType === "Edit"){
      if(!galleryCategoryDataIsFetching && !galleryCategoryDataIsLoading){

        const datagalleryCategory = galleryCategoryData?.data

        let galleryCategoryDataList: ApiGalleryCategoryAdd = {
          category_name: datagalleryCategory?.category_name,
          order : datagalleryCategory?.order,
          
        }
        setInitialValues(galleryCategoryDataList)
      }
    }
  }, [galleryCategoryDataIsLoading, galleryCategoryDataIsFetching, galleryCategoryData, formType])

  return (
    <div>
      <Modal
          className="h-screen "
          open={open}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
        >
          <Fade in={open}>
      <Box className="fade-box">
        <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
          {formType} Gallery Category
        </div>
        <AddGalleryCategoryFormik
          formType={formType}
          handleClose={() => handleClose()}
          isLoading={addGalleryCategoryInfoApi.status === "pending" || updateGalleryCategoryApiInfo.isLoading}
          intialValues={intialValues}
          setInitialValues={setInitialValues}
          addApi={addGalleryCategoryApi}
          editApi={updateGalleryCategoryApi}
          selectedId={selectedId}
        
          
        />
      </Box>
      </Fade>
        </Modal> 
    </div>
  );
});

export default AddGalleryCategoryWrapper;
