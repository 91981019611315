import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ORGTableActionMenu from '../../components/UI/organisms/ORGTableActionMenu';
import { AppDispatch, RootState } from '../../redux/store';
import showToast from '../../utils/toast';
import MOLDeleteConfirmationDialog from '../../components/UI/molecules/MOLDeleteConfirmationDialog';
import { CommonTableColumnsPropTypes, CommonTableHeaderPropTypes } from '../../models/commonTypes';

import { setSelectedId, setCurrentPage, setRowsPerPage,  setIsTableLoading, setIsVisibleAddSideBar, setItems, setTotalItems, setIsVisibleActiveDeactiveConfirmationDialog, setIsVisibleDeleteConfirmationDialog, setIsVisibleEditSideBar, setIsVisibleViewSideBar } from '../../redux/slices/ContactUsSlice';
import { useChangeContactUsStatusMutation, useDeleteContactUsByIdMutation, useGetContactUsWithPaginationQuery } from '../../services/ContactUsService';
import { ContactUsItemsTypes } from '../../models/contactUs.model';
import ContactUsList from './ContactUs';
import MOLChangeStatusConfirmationDialog from '../../components/UI/molecules/MOLChangeStatusConfirmationDialog';
import AddContactUsWrapper from '../../forms/contactUs/AddContactUsWrapper';

const ContactUsWrapper = () => {

    // **** State
    const [currentStatus, setCurrentStatus] = useState(false)

    // **** Hooks
    const contactUs: any = useSelector((state: RootState) => state.contactUs)
    const {
        searchValue,
        currentPage,
        rowsPerPage,
        isTableLoading,
        isVisibleAddSideBar,
        isVisibleViewSideBar,
        isVisibleEditSideBar,
        items,
        totalItems,
        selectedId,
        isVisibleDeleteConfirmationDialog,
        isVisibleActiveDeactiveConfirmationDialog
    } = contactUs

    const dispatch = useDispatch<AppDispatch>()

    // **** Services
    const { data, isFetching, isLoading } = useGetContactUsWithPaginationQuery({
        page: currentPage + 1,
        limit: rowsPerPage,
        searchValue: searchValue.length >= 2 ? searchValue : "",
        params: [
            "social_network_and_links",
        ],
        orderBy: ""
    })
    const [deleteFile] = useDeleteContactUsByIdMutation()
    const [changeStatus, changeStatusInfo] = useChangeContactUsStatusMutation()

    // **** Delete Handler
    const deleteHandler = () => {
        deleteFile(selectedId).then((res: any) => {
            if (res.data) {

                dispatch(setSelectedId(""))
                showToast(res.data?.message, res.data?.status ? "success" : "error");

            }
            if (res?.error) {
                showToast(res.error?.data?.message, "error")

            }
        }).catch((err: any) => {
            // console.log(err)
        })
        dispatch(setIsVisibleDeleteConfirmationDialog(false))
    }


    // **** Change Status Handler
    const changeStatusHandler = () => {
        changeStatus(selectedId)
        dispatch(setIsVisibleActiveDeactiveConfirmationDialog(false))
    }

    // **** Use Effect for set ContactUs Data
    useEffect(() => {
        if (!isFetching && !isLoading) {
            dispatch(setItems(data?.data || []))
            dispatch(setTotalItems(data?.totalItem || 0))
            dispatch(setIsTableLoading(false))

        } else {
            dispatch(setIsTableLoading(true))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, isLoading, data])

    // **** Table Columns Header
    const headers: CommonTableColumnsPropTypes[] = [
        {
            field: 'email',
            headerName: 'Email Id',
            flex: 1,

        },
        {
            field: 'mobile',
            headerName: 'Contact Number',
            flex: 1,
        },

        {
            field: 'Name',
            headerName: 'Social App Name',
            renderCell : (row : ContactUsItemsTypes) =>(
             <div>
                {row.social_network_and_links?.map((ele, index:any)=>{
                    return  (
                    ele.name
                    )
                }).join(", ")
                }
             </div>
            ),
            flex: 1,
        },
        {
            field: 'mapLink',
            headerName: 'Map Link',
            renderCell:(row : ContactUsItemsTypes) =>{
                return ( <>
                 <a href={row.mapLink}> <u>MAP LINK </u> </a>
                
                </> )
            },
            flex: 1,
        },
          
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (row: ContactUsItemsTypes) => (
                row.is_active ? "Active" : "Deactive"
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 40,
            extraClasses: " flex justify-end",
            renderCell: (row: ContactUsItemsTypes) => (
                <div className='flex justify-end pr-3 '>

                    <ORGTableActionMenu actions={{
                        activeDeactive: {
                            onClick: () => { dispatch(setIsVisibleActiveDeactiveConfirmationDialog(true)); setCurrentStatus(row.is_active ? true : false); dispatch(setSelectedId(row._id)) },
                            text: row.is_active ? "Deactive Contact Us" : "Active Contact Us"
                        },
                        edit: {
                            onClick: () => { dispatch(setSelectedId(row._id)); dispatch(setIsVisibleEditSideBar(true)); },
                            text: "Edit Contact Us"
                        }

                    }}
                    />
                </div>
            )
        },
    ]

    

    // **** Table Header Props 
    const tableHeaderProps: CommonTableHeaderPropTypes = {
        
        addBtn: {
            onClickHandler: () => { dispatch(setIsVisibleAddSideBar(true)); },
            btnName: 'Add'
        },

    }
    // **** Table Props
    const tableProps = {
        headers,
        rows: items,
        page: currentPage,
        onPageChange: (newPage: number) => { dispatch(setCurrentPage(newPage)); changeStatusInfo.originalArgs = '' },
        rowsPerPage: rowsPerPage,
        onRowsPerPageChange: (newRowsPerPage: number) => { dispatch(setRowsPerPage(newRowsPerPage)); dispatch(setCurrentPage(0)); changeStatusInfo.originalArgs = '' },
        rowCount: totalItems,
        tableHeaderProps,
        isTableLoading,
        renderRow: (row: ContactUsItemsTypes) => ({
            extraClasses: ` ${!row.is_active && 'bg-slate-300'} `
        })
    }
    

    return (
        <>
            <ContactUsList
                {...tableProps}
            />
           {
                isVisibleDeleteConfirmationDialog &&
                <MOLDeleteConfirmationDialog
                    onAccept={deleteHandler}
                    onDismiss={() => { dispatch(setIsVisibleDeleteConfirmationDialog(false)); dispatch(setSelectedId("")) }}
                />
            }

            {
                isVisibleActiveDeactiveConfirmationDialog &&
                <MOLChangeStatusConfirmationDialog
                    onAccept={changeStatusHandler}
                    onDismiss={() => { dispatch(setIsVisibleActiveDeactiveConfirmationDialog(false)); dispatch(setSelectedId("")) }}
                    message={`Do you really want to ${currentStatus ? 'Deactivate' : 'Active'}`}
                    title={currentStatus ? 'Deactivate confirmation' : "Active confirmation"}
                />

            }
            {isVisibleAddSideBar && <AddContactUsWrapper open={isVisibleAddSideBar} handleClose={()=>dispatch(setIsVisibleAddSideBar(false))} formType="Add" /> } 
            {isVisibleEditSideBar && <AddContactUsWrapper open={isVisibleEditSideBar} handleClose={() => dispatch(setIsVisibleEditSideBar(false))} formType="Edit" />}
            {isVisibleViewSideBar && <AddContactUsWrapper open={isVisibleViewSideBar} handleClose={() => dispatch(setIsVisibleViewSideBar(false))} formType="View" />}
        </>
    )
}

export default ContactUsWrapper
