import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddComboOffers from "./AddComboOffers";
import {
  useAddComboOffersMutation,
  useGetComboOffersByIdQuery,
  useUpdateComboOffersMutation,
} from "../../services/ComboOffersService";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { setCurrentPage } from "../../redux/slices/ComboOffersSlice";
import { useGetProductWithPaginationQuery } from "../../services/ProductService";
import { ComboOfferValidationSchema } from "../validationSchema/ComboOfferValidationSchema";

const AddComboOffersFormik = withFormik<
  OtherProps & formikApiProps,
  any
>({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    let values: any;
    switch (props.formType) {
      case "Add":
        values = props.intialValues;
        break;
      case "Edit":
        values = props.intialValues;
        break;
      case "View":
        values = props.intialValues;
        break;
      default:
        values = props.intialValues;
    }

    return values;
  },

  validationSchema: ComboOfferValidationSchema,

  handleSubmit(values, { props, setValues, setSubmitting }) {
    switch (props.formType) {
      case "Add":

        const formattedValues = {
          products: values?.products?.map((product: any) => {
            return (product?.productId)
          }),
          combo_offer_name: values?.combo_offer_name
        }
        props.addApi(formattedValues).then((response: any) => {
          if (response?.data?.status) {
            // props.setInitialValues(props.intialValues)
            showToast(response?.data?.message, "success");
            store.dispatch(setCurrentPage(0));
            // setSubmitting(true)
            props.handleClose(false);
          } else {
            if (response?.error) {
              props.setInitialValues(values);
              showToast(response?.error?.data?.message, "error");
            } else {
              props.setInitialValues(values);
              showToast(response?.data?.message, "error");
            }
          }
        });
        break;
      case "Edit":
        let reqBody = {
          products: values?.products?.map((product: any) => {
            return (product?.productId)
          }),
          combo_offer_name: values?.combo_offer_name
        };
        props
          .editApi({ body: reqBody, id: props.selectedId })
          .then((response: any) => {
            if (response?.data?.status) {
              showToast(response?.data?.message, "success");
              setSubmitting(true);
              props.handleClose();
            } else {
              if (response?.error) {
                props.setInitialValues(values);
                showToast(response?.error?.data?.message, "error");
              } else {
                props.setInitialValues(values);
                showToast(response?.data?.message, "error");
              }
            }
          });
        break;
      default:
        values = props.intialValues;
    }
  },
})(AddComboOffers);

const AddComboOffersWrapper = React.forwardRef((props: OtherProps, ref) => {
  const { open = false, handleClose, formType } = props;
  const [AddComboOffersApi, AddComboOffersInfoApi] =
    useAddComboOffersMutation();
  const [updateProductGridApi, updateProductGridApiInfo] =
    useUpdateComboOffersMutation();
  const productGrid: any = useSelector((state: RootState) => state.comboOffers);
  const { selectedId } = productGrid;

  const {
    data: productGridData,
    isLoading: productGridDataIsLoading,
    isFetching: productGridDataIsFetching,
  } = useGetComboOffersByIdQuery(selectedId, { skip: !selectedId });

  const initialData: any = {
    combo_offer_name: "",
    products: [
      { 
        productId: "",
      },
    ],
  };
  const {
    data: productData,
    isLoading: productDataIsLoading,
    isFetching: productDataIsFetching,
  } = useGetProductWithPaginationQuery({
    page: 0,
    limit: 0,
    searchValue: "",
    params: ["categoryTitle"],
    orderBy: "",
    isPaginationRequired: false,
  });
  const [intialValues, setInitialValues] = useState(initialData);
  useEffect(() => {
    if (formType === "View" || formType === "Edit") {
      if (!productGridDataIsFetching && !productGridDataIsLoading) {
        // const productDataId = productGridData?.data?.products?.map((el: any) => {

        //   return {
        //     selectLabel: el.title,
        //     value: el._id,
        //   };

        // })


        const dataProductGrid = productGridData?.data;
        let productGridList: any = {
          combo_offer_name: dataProductGrid?.combo_offer_name,
          products: dataProductGrid?.products?.map((product: any) => {
            return {
              productId: product,
            };
          }),
        };
        setInitialValues(productGridList);
      }
    }
  }, [
    productGridDataIsLoading,
    productGridDataIsFetching,
    productGridData,
    formType,
  ]);
  const [productOption, setCategoryOption] = useState([
    {
      selectLabel: "",
      value: "",
    },
  ]);
  useEffect(() => {
    if (!productDataIsLoading && !productDataIsFetching) {
      let productOptions = productData?.data
        ?.filter((data: { is_active: boolean }) => data.is_active === true)
        .map((ele: any) => {
          return {
            selectLabel: ele.title,
            value: ele._id,
          };
        });
      setCategoryOption(productOptions);
    }
  }, [productData, productDataIsLoading, productDataIsFetching]);

  return (
    <div>
      <Modal
        className="h-screen"
        open={open}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="fade-box">
            <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
              {formType} Combo Offer
            </div>
            <AddComboOffersFormik
              formType={formType}
              handleClose={() => handleClose()}
              isLoading={
                AddComboOffersInfoApi.status === "pending" ||
                updateProductGridApiInfo.isLoading
              }
              intialValues={intialValues}
              setInitialValues={setInitialValues}
              addApi={AddComboOffersApi}
              editApi={updateProductGridApi}
              selectedId={selectedId}
              productOption={productOption}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
});

export default AddComboOffersWrapper;
