import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'
import { AdminUserItemsTypes } from "../../models/adminUser.model";

export interface AdminUserSliceStateType {
    items: AdminUserItemsTypes[] | [];
    totalItems: number;
    searchValue: string;
    isVisibleAddSideBar: boolean;
    currentPage: number;
    rowsPerPage: number;
    isTableLoading: boolean;
    sortBy: string;
    filterBy: string
    isVisibleDeleteConfirmationDialog: boolean;
    selectedId: string;
    isVisibleEditSideBar: boolean;
    isVisibleViewSideBar: boolean;
    isVisibleActiveDeactiveConfirmationDialog: boolean;

}

const initialState: AdminUserSliceStateType = {
    items: [],
    totalItems: 0,
    searchValue: "",
    isVisibleAddSideBar: false,
    currentPage: 0,
    rowsPerPage: 10,
    isTableLoading: false,
    sortBy: "",
    filterBy: "",
    isVisibleDeleteConfirmationDialog: false,
    selectedId: "",
    isVisibleEditSideBar: false,
    isVisibleViewSideBar: false,
    isVisibleActiveDeactiveConfirmationDialog: false

}

const adminUserSlice: any = createSlice({
    name: 'adminUser',
   initialState,
    reducers: {
        setSearchValue: (state, action: PayloadAction<string>) => {
            state.searchValue = action.payload
            state.currentPage = 0
        },
        setIsVisibleAddSideBar: (state, action: PayloadAction<boolean>) => {
            state.isVisibleAddSideBar = action.payload
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        setRowsPerPage: (state, action: PayloadAction<number>) => {
            state.rowsPerPage = action.payload
            state.currentPage = 0
        },
        setIsTableLoading: (state, action: PayloadAction<boolean>) => {
            state.isTableLoading = action.payload
        },
        setSortBy: (state, action: PayloadAction<string>) => {
            state.sortBy = action.payload
        },
        setFilterBy: (state, action: PayloadAction<string>) => {
            state.filterBy = action.payload
        },
        setId: (state, action: PayloadAction<string>) => {
            state.selectedId = action.payload
        },
        setIsVisibleDeleteConfirmationDialog: (state, action: PayloadAction<boolean>) => {
            state.isVisibleDeleteConfirmationDialog = action.payload
        },
        setSelectedId: (state, action: PayloadAction<string>) => {
            state.selectedId = action.payload
        },
        setIsVisibleEditSideBar: (state, action: PayloadAction<boolean>) => {
            state.isVisibleEditSideBar = action.payload
        },
        setIsVisibleViewSideBar: (state, action: PayloadAction<boolean>) => {
            state.isVisibleViewSideBar = action.payload
        },
        setIsVisibleActiveDeactiveConfirmationDialog: (state, action: PayloadAction<boolean>) => {
            state.isVisibleActiveDeactiveConfirmationDialog = action.payload
        },
        setItems: (state, action: PayloadAction<AdminUserItemsTypes[] | []>) => {
            state.items = action.payload
        },
        setTotalItems: (state, action: PayloadAction<number>) => {
            state.totalItems = action.payload
        },


    }
})

export const {
    setSearchValue,
    setIsVisibleAddSideBar,
    setCurrentPage,
    setRowsPerPage,
    setIsTableLoading,
    setSortBy,
    setFilterBy,
    setId,
    setIsVisibleDeleteConfirmationDialog,
    setSelectedId,
    setIsVisibleEditSideBar,
    setIsVisibleViewSideBar,
    setIsVisibleActiveDeactiveConfirmationDialog,
    setItems,
    setTotalItems,
} = adminUserSlice.actions
export default adminUserSlice.reducer