  
import { object } from "yup"
import { isString} from "../../validation/validation"

  export const InstitutionalProductValidationSchema = object({
    name:isString("Institutional Product Name is"),
    imageUrl:isString("Image is"),
    shortDescription : isString("Short Description is"),
    longDescription : isString("Long Description is"),
    
  })