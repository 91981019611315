import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiEnquiryAdd } from "../../models/Enquires.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddEnquiry from "./AddEnquires";
import { EnquiryValidationSchema } from "../validationSchema/EnquiresValidationSchema";
import { useAddEnquiryMutation, useGetEnquiryByIdQuery, useUpdateEnquiryMutation } from "../../services/EnquiresService";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";


const AddEnquiryFormik = withFormik<OtherProps & formikApiProps,ApiEnquiryAdd>({
  enableReinitialize : true,
    mapPropsToValues: (props) => {
      let values: ApiEnquiryAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues
          break;
        case "Edit":
            values = props.intialValues
          break;
        case "View":
            values = props.intialValues
          break;
        default:
            values = props.intialValues
      }

      return values;
    },

    validationSchema : EnquiryValidationSchema,
    handleSubmit(values ,{props,setValues,setSubmitting}) {
      switch (props.formType) {
        case 'Edit':
          let reqBody = {
            name:values.name,
            email:values.email,
            mobile:values.mobile,
            message:values.message,
            
          }
  
          setValues({ ...values })
  
          props.editApi({ body: reqBody, id: props.selectedId }).then((response: any) => {
            if (response?.data?.status) {
              showToast(response?.data?.message, "success")
              setSubmitting(true)
              props.handleClose()
            } else {
              if (response?.error) {
                props.setInitialValues(values)
                showToast(response?.error?.data?.message, "error")
              } else {
                props.setInitialValues(values)
                showToast(response?.data?.message, "error")
              }
            }
          }
          )
          break
        default: values = props.intialValues
  
      }
    },
  })(AddEnquiry);


const AddEnquiryWrapper = React.forwardRef((props: OtherProps, ref) => {
  const {open=false, handleClose, formType } = props;
  const [addEnquiryApi,addEnquiryInfoApi ] = useAddEnquiryMutation()
  const [updateEnquiryApi, updateEnquiryApiInfo] = useUpdateEnquiryMutation()
  const enquiry: any = useSelector((state: RootState) => state.enquiry)
  const { selectedId } = enquiry
  const { data: enquiryData, isFetching: enquiryDataIsFetching, isLoading: enquiryDataIsLoading } = useGetEnquiryByIdQuery(selectedId, { skip: !selectedId })

  const initialData : ApiEnquiryAdd = {
    name: "",
    email: "",
    mobile : "",
    message :"",
  };
  const [intialValues,setInitialValues ] = useState(initialData)
  useEffect(() => {
    if (formType === "View" || formType === "Edit") {
      if (!enquiryDataIsFetching && !enquiryDataIsLoading) {
       
        const dataEnquiry = enquiryData?.data

        let enquiryDataList: ApiEnquiryAdd = {
          name: dataEnquiry?.name,
          email: dataEnquiry?.email,
          mobile: dataEnquiry?.mobile,
          message: dataEnquiry?.message,
        }
        setInitialValues(enquiryDataList)
      }
    }
  }, [enquiryDataIsLoading, enquiryDataIsFetching, enquiryData, formType])


  return (
    <div>
      <Modal
          className="h-screen "
          open={open}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
        >
          <Fade in={open}>
      <Box className="fade-box">
        <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
          {formType} Inquiry
        </div>
        <AddEnquiryFormik
          formType={formType}
          handleClose={() => handleClose()}
          isLoading={addEnquiryInfoApi.status === "pending" || updateEnquiryApiInfo.isLoading}
          intialValues={intialValues}
          setInitialValues={setInitialValues}
          addApi={addEnquiryApi}
          editApi={updateEnquiryApi}
          selectedId={selectedId}
          
        />
      </Box>
      </Fade>
        </Modal> 
    </div>
  );
});

export default AddEnquiryWrapper;
