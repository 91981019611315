import React from 'react'
import { ATMInput,ATMPassword,ATMButton } from '../../../components/UI'
import { LoginAPiDataType,LoginFormOtherProps } from '../../../models/auth.model'
import { Form, FormikProps } from 'formik';


const LoginForm = (props: LoginFormOtherProps & FormikProps<LoginAPiDataType>) => {
  const { touched, errors,  setFieldValue,handleSubmit, values } = props;

  return (
    <>
     <Form onSubmit={handleSubmit} className="mt-5" >
     <div className='mt-5'>
        <ATMInput
        autofocus={true}
          size="medium"
          placeholder="Enter Email here "
          variant="outlined"
          extraClasses='w-full'
          onChange={(e) => setFieldValue("userName", e.target.value)}
          name="userName"
          inputType="text"
          label="Email"
          required={true}
          isValid={touched.userName ? !(touched.userName) : true}
          errorMessage={errors.userName}
          value={values.userName}
        />
      </div>

      <div className='mt-5'>
        <ATMPassword
          size="medium"
          placeholder="Enter password here"
          variant="outlined"
          extraClasses='w-full'
          onChange={(e) => setFieldValue("password", e.target.value)}
          name="password"
          label="Password"
          required={true}
          isValid={touched.password ? !(touched.password) : true}
          errorMessage={errors.password}
          value={values.password}
        />
      </div>

      <div className='mt-5 py-4'>
           
              <ATMButton
                        extraClasses='border rounded drop-shadow-xl hover:drop-shadow-2xl  bg-blue-500 text-white py-3'
                        text="Login"
                        type="submit"
                        // disabled={
                        //     isSubmitting ? true : false
                        // }
                        // loading={dataLoading}
                    />
       </div>
      {/* <div className='text-blue-600  inline flex'> 
         <div className='mr-20 ml-24'><Link to={'/register'}> Register</Link> </div>
        <div className='mr-1 ml-28'> <Link to={'/'}> Forget Password</Link></div>
      </div> */}
            
     </Form>
    </>
  )
}

export default LoginForm

