import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ORGTableActionMenu from "../../components/UI/organisms/ORGTableActionMenu";
import { AppDispatch, RootState } from "../../redux/store";
import showToast from "../../utils/toast";
import MOLDeleteConfirmationDialog from "../../components/UI/molecules/MOLDeleteConfirmationDialog";
import {
  CommonTableColumnsPropTypes,
  CommonTableHeaderPropTypes,
} from "../../models/commonTypes";

import {
  setSelectedId,
  setCurrentPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setIsVisibleViewSideBar,
  setItems,
  setTotalItems,
  setIsVisibleDeleteConfirmationDialog,
} from "../../redux/slices/TransactionSlices";
import {
  useChangeTransactionStatusMutation,
  useDeleteTransactionByIdMutation,
  useGetTransactionWithPaginationQuery,
} from "../../services/TransactionService";
import { TransactionItemsTypes } from "../../models/transaction.model";
import TransactionList from "./Transaction";
import AddTransactionWrapper from "../../forms/transaction/AddTransactionWrapper";

const TransactionWrapper = () => {
  // **** State

  // **** Hooks
  const transaction: any = useSelector((state: RootState) => state.transaction);
  const {
    searchValue,
    currentPage,
    rowsPerPage,
    isTableLoading,
    isVisibleViewSideBar,
    items,
    totalItems,
    selectedId,
    isVisibleDeleteConfirmationDialog,
  } = transaction;

  const dispatch = useDispatch<AppDispatch>();

  // **** Services
  const { data, isFetching, isLoading } = useGetTransactionWithPaginationQuery({
    page: currentPage + 1,
    limit: rowsPerPage,
    searchValue: searchValue.length >= 2 ? searchValue : "",
    params: ["payment_gateway"],
    orderBy: "",
  });
  const [deleteFile] = useDeleteTransactionByIdMutation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [changeStatus, changeStatusInfo] = useChangeTransactionStatusMutation();

  // **** Delete Handler
  const deleteHandler = () => {
    deleteFile(selectedId)
      .then((res: any) => {
        if (res.data) {
          dispatch(setSelectedId(""));
          showToast(res.data?.message, res.data?.status ? "success" : "error");
        }
        if (res?.error) {
          showToast(res.error?.data?.message, "error");
        }
      })
      .catch((err: any) => {
        // console.log(err)
      });
    dispatch(setIsVisibleDeleteConfirmationDialog(false));
  };


  // **** Use Effect for set Playground Data
  useEffect(() => {
    if (!isFetching && !isLoading) {
      dispatch(setItems(data?.data || []));
      dispatch(setTotalItems(data?.totalItem || 0));
      dispatch(setIsTableLoading(false));
    } else {
      dispatch(setIsTableLoading(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, isLoading, data]);

  // **** Table Columns Header
  const headers: CommonTableColumnsPropTypes[] = [

    {
      field: "userName",
      headerName: "User Name",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "payment_gateway",
      headerName: "Payment Gateway",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 40,
      extraClasses: " flex justify-end",
      renderCell: (row: TransactionItemsTypes) => (
        <div className="flex justify-end pr-3 ">
          <ORGTableActionMenu
            actions={{
              view: {
                onClick: () => {
                  dispatch(setSelectedId(row._id));
                  dispatch(setIsVisibleViewSideBar(true));
                },
                text: "View  ",
              },
            }}
          />
        </div>
      ),
    },
  ];

  // **** Table Header Props
  const tableHeaderProps: CommonTableHeaderPropTypes = {
    search: {
      searchValue: searchValue,
      setSearchValue: (newValue: string) => {
        dispatch(setSearchValue(newValue));
        changeStatusInfo.originalArgs = "";
      },
    },
  };

  // **** Table Props
  const tableProps = {
    headers,
    rows: items,
    page: currentPage,
    onPageChange: (newPage: number) => {
      dispatch(setCurrentPage(newPage));
      changeStatusInfo.originalArgs = "";
    },
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (newRowsPerPage: number) => {
      dispatch(setRowsPerPage(newRowsPerPage));
      dispatch(setCurrentPage(0));
      changeStatusInfo.originalArgs = "";
    },
    rowCount: totalItems,
    tableHeaderProps,
    isTableLoading,
  };

  return (
    <>
      <TransactionList {...tableProps} />
      {isVisibleDeleteConfirmationDialog && (
        <MOLDeleteConfirmationDialog
          onAccept={deleteHandler}
          onDismiss={() => {
            dispatch(setIsVisibleDeleteConfirmationDialog(false));
            dispatch(setSelectedId(""));
          }}
        />
      )}
      {isVisibleViewSideBar && (
        <AddTransactionWrapper
          open={isVisibleViewSideBar}
          handleClose={() => dispatch(setIsVisibleViewSideBar(false))}
          formType="View"
        />
      )}
    </>
  );
};

export default TransactionWrapper;
