import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiJobApplicationAdd } from "../../models/jobApplication.model ";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddJobApplication from "./AddJobApplication";
import { JobApplicationValidationSchema } from "../validationSchema/JobApplicationValidationSchema";
import {  useGetJobApplicationByIdQuery, useUpdateJobApplicationMutation } from "../../services/JobApplicationService";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useGetJobWithPaginationQuery } from "../../services/JobService";


const AddJobApplicationFormik = withFormik<OtherProps & formikApiProps,ApiJobApplicationAdd>({
  enableReinitialize : true,
    mapPropsToValues: (props) => {
      let values: ApiJobApplicationAdd;
      switch (props.formType) {
        case "Edit":
            values = props.intialValues
          break;
        case "View":
            values = props.intialValues
          break;
        default:
            values = props.intialValues
      }

      return values;
    },

    validationSchema : JobApplicationValidationSchema,

    handleSubmit(values ,{props,setValues,setSubmitting}) {
        switch (props.formType) {
          case 'Edit':
            let reqBody = {
              name:values.name,
              email:values.email,
              permanentAddress: values.permanentAddress,
              temporaryAddress: values.temporaryAddress,
              mobile: values.mobile,
              age: values.age,
              resume : values.resume,
              jobId :values.jobId,

            }
    
            setValues({ ...values })
    
            props.editApi({ body: reqBody, id: props.selectedId }).then((response: any) => {
              if (response?.data?.status) {
                showToast(response?.data?.message, "success")
                setSubmitting(true)
                props.handleClose()
              } else {
                if (response?.error) {
                  props.setInitialValues(values)
                  showToast(response?.error?.data?.message, "error")
                } else {
                  props.setInitialValues(values)
                  showToast(response?.data?.message, "error")
                }
              }
            }
            )
            break
          default: values = props.intialValues
    
        }
      },
  })(AddJobApplication);


const AddJobApplicationWrapper = React.forwardRef((props: OtherProps, ref) => {
  const {open=false, handleClose, formType } = props;
  const [updateJobApplicationApi, updateJobApplicationApiInfo] = useUpdateJobApplicationMutation()
  const jobApplication: any = useSelector((state: RootState) => state.jobApplication)
    const { selectedId } = jobApplication
    const { data: jobApplicationData, isFetching: jobApplicationDataIsFetching, isLoading: jobApplicationDataIsLoading } = useGetJobApplicationByIdQuery(selectedId, { skip: !selectedId })
    const {
      data: jobData,
      isLoading: jobDataIsLoading,
      isFetching: jobDataIsFetching,
    } = useGetJobWithPaginationQuery({ 
      page: 0,
      limit: 0,
      searchValue: "",
      params: [
        "jobTitle",
      "description"
      ],
      orderBy: "",
          isPaginationRequired: false})
  
  const initialData : ApiJobApplicationAdd = {
    name: "",
    email: "",
    job_Title:"",
    permanentAddress: "",
    temporaryAddress: "",
    mobile: "",
    age: "",
    resume : "",
    jobId :"",
  };
  const [intialValues,setInitialValues ] = useState(initialData)
  const [jobOption, setJobOption] = useState([
    {
      selectLabel: "",
      value: "",
    },
  ]);


  useEffect(() => {
    if (!jobDataIsLoading && !jobDataIsFetching) {
      let jobOptions = jobData?.data?.filter((data: { is_active: boolean; }) => data.is_active === true).map((ele :any)=>{

        return {
          selectLabel: ele.jobTitle,
            value: ele._id
        }
      })  
      setJobOption(jobOptions);
    }

  }, [jobData, jobDataIsLoading, jobDataIsFetching]);

  useEffect(() => {
    if (formType === "View" || formType === "Edit") {
      if (!jobApplicationDataIsFetching && !jobApplicationDataIsLoading) {
        const dataJobApplication = jobApplicationData?.data
        let jobApplicationDataList: ApiJobApplicationAdd = {
          name: dataJobApplication?.name,
          email: dataJobApplication?.email,
          job_Title: dataJobApplication?.job_Title,
          permanentAddress: dataJobApplication?.permanentAddress,
          temporaryAddress: dataJobApplication?.temporaryAddress,
          resume: dataJobApplication?.resume,
          mobile: dataJobApplication?.mobile,
          age: dataJobApplication?.age,
          jobId : dataJobApplication?.jobId,
          
        }
        setInitialValues(jobApplicationDataList)
      }
    }
  }, [jobApplicationDataIsLoading, jobApplicationDataIsFetching, jobApplicationData, formType])



  return (
    <div>
      <Modal
          className="h-screen "
          open={open}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
        >
          <Fade in={open}>
      <Box className="fade-box">
        <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
          {formType} Job Application
        </div>
        <AddJobApplicationFormik
          formType={formType}
          handleClose={() => handleClose()}
          isLoading={updateJobApplicationApiInfo.isLoading}
          intialValues={intialValues}
          setInitialValues={setInitialValues}
          selectedId = {selectedId}
          editApi={updateJobApplicationApi}
          jobListSingle ={jobOption}
        />
      </Box>
      </Fade>
        </Modal> 
    </div>
  );
});

export default AddJobApplicationWrapper;
