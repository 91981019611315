import React from "react";
import { Form, FormikProps } from "formik";
import {  ATMButton, ATMInput } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiJobAdd } from "../../models/job.model";
import ATMTextArea from "../../components/UI/atoms/ATMTextArea/ATMTextArea";

const AddJob = (props: OtherProps & FormikProps<ApiJobAdd>) => {
  const { touched, errors,  handleChange, handleClose,setFieldValue, formType, isLoading,values } =
    props;


  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Job Title"
              variant="outlined"
              extraClasses="w-full"
              value={values.jobTitle}
              onChange={handleChange}
              name="jobTitle"
              label="Job Title "
              required={true}
              readOnly={formType === "View" ? true : false }
              isValid={touched.jobTitle ? !touched.jobTitle : true}
              errorMessage={errors.jobTitle}
            />
          </div>

          <div className="mb-4">
            <ATMTextArea
              size="medium"
              placeholder="Job Description"
              variant="outlined"
              extraClasses="w-full"
              disabled={formType === "View" ? true : false}
              onChange={(e) => setFieldValue("description", e.target.value)}
              name="description"
              label="Job Description"
              value={values.description}
              required={true}
              isValid={touched.description ? !touched.description : true}
              errorMessage={errors.description}
            />
          </div>
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses='hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer'
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton

            extraClasses='py-3 border bg-red-500 hover:bg-red-600 text-white'
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddJob;
