  
import { object } from "yup"
import { isString,} from "../../validation/validation"

  export const FileManagerValidationSchema = object({
    fileName:isString("File Name is"),
    filePath: isString("File is"),
    fileType: isString("File Type is"),


    
  })