import React from 'react'
import Swal from 'sweetalert2'

interface MOLDeleteConfirmationDialogPropTypes {
    onAccept: () => void;
    onDismiss: () => void;
    dismissBtnText?: string;
    acceptBtnText?: string;
    message?: string;
    title?: string;
}

const MOLDeleteConfirmationDialog = ({
    onAccept,
    onDismiss,
    acceptBtnText = 'Yes',
    dismissBtnText = 'No',
    message = "Do you really wish to delete ?",
    title = "Delete Confirmation"
}: MOLDeleteConfirmationDialogPropTypes
) => {

    Swal.fire({
        title: title,
        text: message,
        icon: 'error',
        confirmButtonText: acceptBtnText,
        cancelButtonText: dismissBtnText,
        showCancelButton: true

    }).then((result) => {
        if (result.isConfirmed) {
            onAccept()
        }
        else if (result.isDismissed) {
            onDismiss()
        }
    })

    return (
        <></>
    )
}

export default MOLDeleteConfirmationDialog

