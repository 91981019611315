import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ORGTableActionMenu from "../../components/UI/organisms/ORGTableActionMenu";
import { AppDispatch, RootState } from "../../redux/store";
import showToast from "../../utils/toast";
import MOLDeleteConfirmationDialog from "../../components/UI/molecules/MOLDeleteConfirmationDialog";
import {
  CommonTableColumnsPropTypes,
  CommonTableHeaderPropTypes,
} from "../../models/commonTypes";

import {
  setSelectedId,
  setCurrentPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setIsVisibleAddSideBar,
  setItems,
  setTotalItems,
  setIsVisibleDeleteConfirmationDialog,
  setIsVisibleViewSideBar,
  setIsVisibleEditSideBar,
} from "../../redux/slices/CompanyPolicySlices";
import {
  useDeleteCompanyPolicyByIdMutation,
  useGetCompanyPolicyWithPaginationQuery,
} from "../../services/CompanyPolicyService";
import { CompanyPolicyItemsTypes } from "../../models/companyPolicyProduct.model";
import CompanyPolicyList from "./CompanyPolicy";
import AddCompanyPolicyWrapper from "../../forms/companyPolicy/AddCompanyPolicyWrapper";
import ATMMarkdownContainer from "../../components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";

const CompanyPolicyWrapper = () => {
  // **** State

  // **** Hooks
  const CompanyPolicy: any = useSelector((state: RootState) => state.content);
  const {
    searchValue,
    currentPage,
    rowsPerPage,
    isTableLoading,
    isVisibleAddSideBar,
    isVisibleEditSideBar,
    isVisibleViewSideBar,
    items,
    totalItems,
    selectedId,
    isVisibleDeleteConfirmationDialog,
  } = CompanyPolicy;

  const dispatch = useDispatch<AppDispatch>();

  // **** Services
  const { data, isFetching, isLoading } =
    useGetCompanyPolicyWithPaginationQuery({
      page: currentPage + 1,
      limit: rowsPerPage,
      searchValue: searchValue.length >= 2 ? searchValue : "",
      params: ["header", "content"],
      orderBy: "",
    });
  const [deleteFile] = useDeleteCompanyPolicyByIdMutation();

  // **** Delete Handler
  const deleteHandler = () => {
    deleteFile(selectedId)
      .then((res: any) => {
        if (res.data) {
          dispatch(setSelectedId(""));
          showToast(res.data?.message, res.data?.status ? "success" : "error");
        }
        if (res?.error) {
          showToast(res.error?.data?.message, "error");
        }
      })
      .catch((err: any) => {
        // console.log(err)
      });
    dispatch(setIsVisibleDeleteConfirmationDialog(false));
  };

  // **** Use Effect for set Playground Data
  useEffect(() => {
    if (!isFetching && !isLoading) {
      dispatch(setItems(data?.data || []));
      dispatch(setTotalItems(data?.totalItem || 0));
      dispatch(setIsTableLoading(false));
    } else {
      dispatch(setIsTableLoading(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, isLoading, data]);

  // **** Table Columns Header
  const headers: CommonTableColumnsPropTypes[] = [
    {
      field: "header",
      headerName: "Header",
      flex: 1,
    },
    {
      field: "content",
      headerName: "Content",
      flex: 1,
      renderCell: (row) => (
        <ATMMarkdownContainer
          markdown={row?.content}
          className="border-[1px] border-white"
        />
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 40,
      extraClasses: " flex justify-end",
      renderCell: (row: CompanyPolicyItemsTypes) => (
        <div className="flex justify-end pr-3 ">
          <ORGTableActionMenu
            actions={{
              delete: {
                onClick: () => {
                  dispatch(setSelectedId(row._id));
                  dispatch(setIsVisibleDeleteConfirmationDialog(true));
                },
                text: "Delete ",
              },
              view: {
                onClick: () => {
                  dispatch(setSelectedId(row._id));
                  dispatch(setIsVisibleViewSideBar(true));
                },
                text: "View ",
              },
              edit: {
                onClick: () => {
                  dispatch(setSelectedId(row._id));
                  dispatch(setIsVisibleEditSideBar(true));
                },
                text: "Edit ",
              },
            }}
          />
        </div>
      ),
    },
  ];

  // **** Table Header Props
  const tableHeaderProps: CommonTableHeaderPropTypes = {
    addBtn: {
      onClickHandler: () => {
        dispatch(setIsVisibleAddSideBar(true));
      },
      btnName: "Add",
    },
    search: {
      searchValue: searchValue,
      setSearchValue: (newValue: string) => {
        dispatch(setSearchValue(newValue));
      },
    },
  };

  // **** Table Props
  const tableProps = {
    headers,
    rows: items,
    page: currentPage,
    onPageChange: (newPage: number) => {
      dispatch(setCurrentPage(newPage));
    },
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (newRowsPerPage: number) => {
      dispatch(setRowsPerPage(newRowsPerPage));
      dispatch(setCurrentPage(0));
    },
    rowCount: totalItems,
    tableHeaderProps,
    isTableLoading,
  };

  return (
    <>
      <CompanyPolicyList {...tableProps} />
      {isVisibleDeleteConfirmationDialog && (
        <MOLDeleteConfirmationDialog
          onAccept={deleteHandler}
          onDismiss={() => {
            dispatch(setIsVisibleDeleteConfirmationDialog(false));
            dispatch(setSelectedId(""));
          }}
        />
      )}

      {isVisibleAddSideBar && (
        <AddCompanyPolicyWrapper
          open={isVisibleAddSideBar}
          handleClose={() => dispatch(setIsVisibleAddSideBar(false))}
          formType="Add"
        />
      )}
      {isVisibleEditSideBar && (
        <AddCompanyPolicyWrapper
          open={isVisibleEditSideBar}
          handleClose={() => dispatch(setIsVisibleEditSideBar(false))}
          formType="Edit"
        />
      )}
      {isVisibleViewSideBar && (
        <AddCompanyPolicyWrapper
          open={isVisibleViewSideBar}
          handleClose={() => dispatch(setIsVisibleViewSideBar(false))}
          formType="View"
        />
      )}
    </>
  );
};

export default CompanyPolicyWrapper;
