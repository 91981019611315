import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiCouponAdd } from "../../models/coupon.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddCoupon from "./AddCoupon";
import { CouponValidationSchema } from "../validationSchema/CouponValidationSchema";
import {
  useAddCouponMutation,
  useGetCouponByIdQuery,
  useUpdateCouponMutation,
} from "../../services/CouponService";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { setCurrentPage } from "../../redux/slices/CouponSlices";

const AddCouponFormik = withFormik<OtherProps & formikApiProps, ApiCouponAdd>({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    let values: ApiCouponAdd;
    switch (props.formType) {
      case "Add":
        values = props.intialValues;
        break;
      case "Edit":
        values = props.intialValues;
        break;
      case "View":
        values = props.intialValues;
        break;
      default:
        values = props.intialValues;
    }

    return values;
  },

  validationSchema: CouponValidationSchema,

  handleSubmit(values, { props, setValues, setSubmitting }) {
    switch (props.formType) {
      case "Add":
        props.addApi(values).then((response: any) => {
          if (response?.data?.status) {
            // props.setInitialValues(props.intialValues)
            showToast(response?.data?.message, "success");
            store.dispatch(setCurrentPage(0));

            // setSubmitting(true)
            props.handleClose(false);
          } else {
            if (response?.error) {
              props.setInitialValues(values);
              showToast(response?.error?.data?.message, "error");
            } else {
              props.setInitialValues(values);
              showToast(response?.data?.message, "error");
            }
          }
        });
        break;
      case "Edit":
        let reqBody = {
          coupon_code: values.coupon_code,
          description: values.description,
          max_discount_amount: values.max_discount_amount,
          min_order_value: values.min_order_value,
          discount_type: values.discount_type,
          coupon_discount: values.coupon_discount,
          start_date: values.start_date,
          end_date: values.end_date,
        };

        setValues({ ...values });

        props
          .editApi({ body: reqBody, id: props.selectedId })
          .then((response: any) => {
            if (response?.data?.status) {
              showToast(response?.data?.message, "success");
              setSubmitting(true);
              props.handleClose();
            } else {
              if (response?.error) {
                props.setInitialValues(values);
                showToast(response?.error?.data?.message, "error");
              } else {
                props.setInitialValues(values);
                showToast(response?.data?.message, "error");
              }
            }
          });
        break;
      default:
        values = props.intialValues;
    }
  },
})(AddCoupon);

const AddCouponWrapper = React.forwardRef((props: OtherProps, ref) => {
  const { open = false, handleClose, formType } = props;
  const [addCouponApi, addCouponInfoApi] = useAddCouponMutation();
  const [updateCouponApi, updateCouponApiInfo] = useUpdateCouponMutation();
  const coupon: any = useSelector((state: RootState) => state.coupon);
  const { selectedId } = coupon;
  const {
    data: couponData,
    isLoading: couponDataIsLoading,
    isFetching: couponDataIsFetching,
  } = useGetCouponByIdQuery(selectedId, { skip: !selectedId });
  const initialData: ApiCouponAdd = {
    description: "",
    coupon_code: "",
    max_discount_amount: "",
    min_order_value: "",
    discount_type: "",
    coupon_discount: "",
    start_date: "",
    end_date: "",
  };
  const [intialValues, setInitialValues] = useState(initialData);
  useEffect(() => {
    if (formType === "View" || formType === "Edit") {
      if (!couponDataIsFetching && !couponDataIsLoading) {
        const dataCoupon = couponData?.data;

        let couponDataList: ApiCouponAdd = {
          description: dataCoupon?.description,
          coupon_code: dataCoupon?.coupon_code,
          max_discount_amount: dataCoupon?.max_discount_amount,
          min_order_value: dataCoupon?.min_order_value,
          discount_type: dataCoupon?.discount_type,
          coupon_discount: dataCoupon?.coupon_discount,
          start_date : dataCoupon?.start_date,
          end_date : dataCoupon?.end_date
        };
        setInitialValues(couponDataList);
      }
    }
  }, [couponDataIsLoading, couponDataIsFetching, couponData, formType]);

  return (
    <div>
      <Modal
        className="h-screen "
        open={open}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="fade-box">
            <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
              {formType} Coupon
            </div>
            <AddCouponFormik
              formType={formType}
              handleClose={() => handleClose()}
              isLoading={
                addCouponInfoApi.status === "pending" ||
                updateCouponApiInfo.isLoading
              }
              intialValues={intialValues}
              setInitialValues={setInitialValues}
              addApi={addCouponApi}
              editApi={updateCouponApi}
              selectedId={selectedId}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
});

export default AddCouponWrapper;
