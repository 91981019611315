import React from 'react'
import Swal from 'sweetalert2'

interface MOLChangeStatusConfirmationDialogPropTypes {
    onAccept: () => void;
    onDismiss: () => void;
    dismissBtnText?: string;
    acceptBtnText?: string;
    message: string;
    title: string;
}

const MOLChangeStatusConfirmationDialog = ({
    onAccept,
    onDismiss,
    acceptBtnText = 'Yes',
    dismissBtnText = 'No',
    message,
    title
}: MOLChangeStatusConfirmationDialogPropTypes
) => {

    Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        confirmButtonText: acceptBtnText,
        cancelButtonText: dismissBtnText,
        showCancelButton: true

    }).then((result) => {
        if (result.isConfirmed) {
            onAccept()
        }
        else if (result.isDismissed) {
            onDismiss()
        }
    })

    return (
        <></>
    )
}

export default MOLChangeStatusConfirmationDialog
