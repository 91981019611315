import { Table, TableContainer } from '@mui/material';
import React from 'react'
import { CommonTableHeaderPropTypes } from '../../../models/commonTypes';
import ATMPagination from '../atoms/ATMPagination';
import ATMSkeleton from '../atoms/ATMSkeleton';
import ATMTableHeader from '../atoms/ATMTableHeader'
import ATMTableRows from '../atoms/ATMTableRows';


interface ColumnsPropTypes {
    field: string;
    headerName?: string;
    renderHeader?: () => React.ReactNode | string;
    flex?: number;
    renderCell?: (row: any) => React.ReactNode | string;
    extraClasses?: string;


}

interface renderRowTypes {
    extraClasses?: string;
}


interface MOLTablePropTypes {
    headers: ColumnsPropTypes[]
    rows: any[];
    renderRow?: null | ((rowData: any) => renderRowTypes);
    hidePagination?: boolean;
    page: number;
    onPageChange: (newPage: number) => void
    rowsPerPage: number;
    onRowsPerPageChange: (newRowsPerPage: number) => void;
    rowCount: number;
    tableHeaderProps?: CommonTableHeaderPropTypes;
    isTableLoading?: boolean;
    onRowClick?: (row: any) => void;
    maxHeight?: string | number;
    isCheckbox?: boolean;
    selectedRows?: any[];
    setSelectedRows?: (newSelectedRows: any) => void;
    size?: "small" | "medium",

}





const MOLTable = (
    {
        headers,
        rows,
        renderRow,
        hidePagination = false,
        rowCount,
        page,
        onPageChange,
        rowsPerPage,
        onRowsPerPageChange,
        tableHeaderProps,
        isTableLoading,
        onRowClick,
        maxHeight = "",
        isCheckbox = false,
        selectedRows = [],
        setSelectedRows,
        size = "medium",

    }: MOLTablePropTypes
) => {




    const columns = headers.map(header => {
        return (
            {
                field: header.field,
                headerName: header.headerName,
                renderHeader: header.renderHeader,
                flex: header.flex,
                extraClasses: header.extraClasses
            }
        )
    }

    )

    return (
        <>
            <TableContainer className='p-3' sx={{ maxHeight: maxHeight }} >

                <Table size={size}  >
                    <ATMTableHeader columns={columns} isCheckbox={isCheckbox} selectedRows={selectedRows} setSelectedRows={setSelectedRows} rows={rows} />

                    {
                        isTableLoading ?
                            (
                                <tbody>
                                    <tr>
                                        <td colSpan={isCheckbox ? headers.length + 1 : headers.length}>
                                            <div style={{ flex: 1 }} >

                                                <ATMSkeleton count={rowsPerPage} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                            )
                            :

                            rows.length ?
                                <ATMTableRows
                                    onRowClick={onRowClick}
                                    columns={headers}
                                    rows={rows}
                                    renderRow={renderRow}
                                    isCheckbox={isCheckbox}
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                />
                                :
                                (
                                    <tbody>
                                        <tr>
                                            <td colSpan={isCheckbox ? headers.length + 1 : headers.length}>
                                                <h5 className='text-center py-3 text-slate-600' style={{ flex: 1 }} >
                                                    No Records Found
                                                </h5>
                                            </td>
                                        </tr>
                                    </tbody>

                                )
                    }




                </Table>

                {
                    !hidePagination && (rows.length || page > 0) ?
                        <ATMPagination
                            page={page}
                            onPageChange={onPageChange}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={onRowsPerPageChange}
                            rowCount={rowCount}
                        />
                        :
                        null
                }

            </TableContainer>
        </>
    )
}

export default MOLTable
