import React from "react";
import { Form, FormikProps } from "formik";
import {  ATMButton, ATMInput } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiEnquiryAdd } from "../../models/Enquires.model";
import ATMTextArea from "../../components/UI/atoms/ATMTextArea/ATMTextArea";

const AddEnquiry = (props: OtherProps & FormikProps<ApiEnquiryAdd>) => {
  const { touched, errors,  handleChange, handleClose, formType,values, setFieldValue, isLoading } =
    props;


  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Name"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="name"
              label="Name "
              value={values.name}
              required={true}
              readOnly={formType === "View" ? true : false }
              isValid={touched.name ? !touched.name : true}
              errorMessage={errors.name}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Email"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="email"
              label="Email"
              value={values.email}
              required={true}
              readOnly={formType === "View" ? true : false }
              isValid={touched.email ? !touched.email : true}
              errorMessage={errors.email}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Mobile Number"
              variant="outlined"
              extraClasses="w-full"
              value={values.mobile}
              onChange={handleChange}
              name="mobile"
              label="Mobile Number"
              required={true}
              readOnly={formType === "View" ? true : false }
              isValid={touched.mobile ? !touched.mobile : true}
              errorMessage={errors.mobile}
            />
          </div>
          <div className="mb-4">
          <ATMTextArea
              size="medium"
              placeholder="Message"
              variant="outlined"
              extraClasses="w-full"
              onChange={(e)=>setFieldValue("message",e.target.value)}
              name="message"
              label=" Message"
              value={values.message}
              disabled={formType === "View" ? true : false }
              required={true}
              isValid={
                touched.message
                  ? !(touched.message)
                  : true
              }
              errorMessage={errors.message}
            />
          </div>
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses='hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer'
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton

            extraClasses='py-3 border bg-red-500 hover:bg-red-600 text-white'
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddEnquiry;
