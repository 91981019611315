import Dashboard from './Dashboard';
import {  Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';


const DashboardTodayEnquiryList = () => {

    return (
        <>
            <div className="container-lg mx-10 mt-10">
                <div>
                    <div className='text-d2 mt-2'>
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Dashboard
                    </div>
                </div>

                <div className='py-3'></div>

                {/* Table */}
                <div  >
                    <Paper>
                        <Dashboard />
                    </Paper>
                </div>
            </div>
        </>

    )
}

export default DashboardTodayEnquiryList
