import { object, ref } from "yup"
import { isBoolean, isNumber, isString } from "../../validation/validation"
import * as Yup from "yup"

export const ProductValidationSchema = object({
  title: isString("Product Name is"),
  mrp: isNumber("MRP is").positive("Negative values are not allowed").integer("Must be more than 0"),
  sellingPrice: isNumber("Selling Price is").positive("Negative values are not allowed").integer("Must be more than 0").max(ref("mrp"), "Please enter Selling Price equal or less than MRP"),
  images: Yup.array().of(Yup.string().min(1, "Please select atleast one image").required("At least one image is required")),
  stockQuantity: isNumber("Quantity is").positive("Negative values are not allowed"),
  categoryId: isString("Category is"),
  brandId: isString("Brand is"),
  // packSize: isString("Package size is").matches(/^[A-Za-z1-9_]*$/, "Please enter more than 0"),
  packSize: isString("Product Size  is"),
  productGroup: isString("Product Group  is"),
  productCode: isString("Product Code is"),
  shortDescription: isString("Short Description is"),
  longDescription: isString("Long Description is"),
  is_combo: isBoolean("Is Combo  "),
  in_stock: isString("In Stock is"),
  is_featured: isBoolean("Is Featured"),
  in_offer: isBoolean("In Offer"),
  // combo_offer_text : isString("Combo offer text is"),
})

