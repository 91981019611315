import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiDownloadAdd } from "../../models/Download.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddDownload from "./AddDownload";
import {
  useAddDownloadMutation,
  useGetDownloadByIdQuery,
  useUpdateDownloadMutation,
} from "../../services/DownloadService";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { setCurrentPage } from "../../redux/slices/DownloadSlices";
import { DownloadValidationSchema } from "../validationSchema/DownloadValidationSchema";

const AddDownloadFormik = withFormik<
  OtherProps & formikApiProps,
  ApiDownloadAdd
>({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    let values: ApiDownloadAdd;
    switch (props.formType) {
      case "Add":
        values = props.intialValues;
        break;
      case "Edit":
        values = props.intialValues;
        break;
      case "View":
        values = props.intialValues;
        break;
      default:
        values = props.intialValues;
    }

    return values;
  },

  validationSchema : DownloadValidationSchema,

  handleSubmit(values: any, { props, setValues, setSubmitting }) {
   
    switch (props.formType) {
      case "Add":
        let formData = new FormData();
        for (let key in values) {
          formData.append(key, values[key]);
        }
        
        
        props.addApi(formData).then((response: any) => {
          if (response?.data?.status) {
            // props.setInitialValues(props.intialValues)
            showToast(response?.data?.message, "success");
            store.dispatch(setCurrentPage(0));

            // setSubmitting(true)
            props.handleClose(false);
          } else {
            if (response?.error) {
              props.setInitialValues(values);
              showToast(response?.error?.data?.message, "error");
            } else {
              props.setInitialValues(values);
              showToast(response?.data?.message, "error");
            }
          }
        });
        break;
      case "Edit":
        let reqBody = {
          fileName: values.fileName,
          filePath: values.filePath,
          fileType: values.fileType,
        };

        setValues({ ...values });

        props
          .editApi({ body: reqBody, id: props.selectedId })
          .then((response: any) => {
            if (response?.data?.status) {
              showToast(response?.data?.message, "success");
              setSubmitting(true);
              props.handleClose();
            } else {
              if (response?.error) {
                props.setInitialValues(values);
                showToast(response?.error?.data?.message, "error");
              } else {
                props.setInitialValues(values);
                showToast(response?.data?.message, "error");
              }
            }
          });
        break;
      default:
        values = props.intialValues;
    }
  },
})(AddDownload);

const AddDownloadWrapper = React.forwardRef((props: OtherProps, ref) => {
  const { open = false, handleClose, formType } = props;
  const [addDownloadApi, addDownloadInfoApi] =
    useAddDownloadMutation();
  const [updateDownloadApi, updateDownloadApiInfo] =
    useUpdateDownloadMutation();
  const download: any = useSelector((state: RootState) => state.download);
  const { selectedId } = download;
  const {
    data: downloadData,
    isLoading: downloadDataIsLoading,
    isFetching: downloadDataIsFetching,
  } = useGetDownloadByIdQuery(selectedId, { skip: !selectedId });
  const initialData: ApiDownloadAdd = {
    fileName: "",
    filePath: "",
    fileType: "",
  };
  const [intialValues, setInitialValues] = useState(initialData);
  useEffect(() => {
    if (formType === "View" || formType === "Edit") {
      if (!downloadDataIsFetching && !downloadDataIsLoading) {
        const dataDownload = downloadData?.data;

        let downloadDataList: ApiDownloadAdd = {
          fileName: dataDownload?.fileName,
          filePath: dataDownload?.filePath,
          fileType: dataDownload?.fileType,
        };
        setInitialValues(downloadDataList);
      }
    }
  }, [
    downloadDataIsLoading,
    downloadDataIsFetching,
    downloadData,
    formType,
  ]);

  return (
    <div>
      <Modal
        className="h-screen "
        open={open}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="fade-box">
            <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
              {formType} Download
            </div>
            <AddDownloadFormik
              formType={formType}
              handleClose={() => handleClose()}
              isLoading={
                addDownloadInfoApi.status === "pending" ||
                updateDownloadApiInfo.isLoading
              }
              intialValues={intialValues}
              setInitialValues={setInitialValues}
              addApi={addDownloadApi}
              editApi={updateDownloadApi}
              selectedId={selectedId}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
});

export default AddDownloadWrapper;


