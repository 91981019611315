import React from "react";
import { Form, FormikProps } from "formik";
import {  ATMButton, ATMInput, ATMSelect } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiFranchiseAdd } from "../../models/franchise.model";

const AddFranchise = (props: OtherProps & FormikProps<ApiFranchiseAdd>) => {
  const { touched, errors,  handleChange, handleClose, formType,setFieldValue, isLoading, values } =
    props;

    const tagOptions=[
      { selectLabel: "True", value: "TRUE" },
    { selectLabel: "False", value: "FALSE" },
    ]
  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Name"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="name"
              label="Name "
              value={values.name}
              required={true}
              readOnly={formType === "View" ? true : false }
              isValid={touched.name ? !touched.name : true}
              errorMessage={errors.name}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Contact Number"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="mobile"
              value={values.mobile}
              label="Contact Number"
              required={true}
              readOnly={formType === "View" ? true : false }
              isValid={touched.mobile ? !touched.mobile : true}
              errorMessage={errors.mobile}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Email"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="email"
              value={values.email}
              label="Email"
              required={true}
              readOnly={formType === "View" ? true : false }
              isValid={touched.email ? !touched.email : true}
              errorMessage={errors.email}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="City"
              value={values.city}
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="city"
              label="City"
              required={false}
              readOnly={formType === "View" ? true : false }
              isValid={touched.city ? !touched.city : true}
              errorMessage={errors.city}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Occupation"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="occupation"
              label="Occupation"
              value={values.occupation}
              readOnly={formType === "View" ? true : false }
              required={false}
              isValid={touched.occupation ? !touched.occupation : true}
              errorMessage={errors.occupation}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Experience"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="experience"
              label="Experience"
              value={values.experience}
              required={false}
              readOnly={formType === "View" ? true : false }
              isValid={touched.experience ? !touched.experience : true}
              errorMessage={errors.experience}
            />
          </div>
          <div className="mb-4">
            <ATMSelect
            
              size="medium"
              defaultValue={values.have_fssai_licence}
              placeholder="Is Combo"
             extraClasses="w-full"
              onChange={(e) => setFieldValue("have_fssai_licence", e.target.value)}
              name="have_fssai_licence"
              label=" Is Combo" 
              required={false}
              value={values.have_fssai_licence}
              isValid={touched.have_fssai_licence ? !(touched.have_fssai_licence) : true}
              errorMessage={errors.have_fssai_licence}
              disabled={formType === "View" ? true : false} 
              options={tagOptions}            />
          </div>
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses='hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer'
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton

            extraClasses='py-3 border bg-red-500 hover:bg-red-600 text-white'
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddFranchise;
