import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonExportApiType, CommonPaginationApiDataType } from '../models/commonTypes';
import { ApiFileManagerAdd, ApiFileManagerUpdate } from '../models/fileManager.model';
import { BASE_URL } from "../utils/constants";
const AUTH_TOKEN = localStorage.getItem("authToken")

const TOKEN = AUTH_TOKEN ? AUTH_TOKEN : ""

export const fileManagerApi = createApi({
    reducerPath: 'fileManagerApi',
    tagTypes: ['file', 'allFiles'],
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/file-manager` }),
    endpoints: (builder) => ({

        // **** GET WITH PAGINATION
        getFileManagerWithPagination: builder.query({
            providesTags: ['file'],
            query: (body: CommonPaginationApiDataType) => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body
            }),
        }),

        // **** ADD FileManager
        addFileManager: builder.mutation({
            invalidatesTags: ['file'],
            query: (body: ApiFileManagerAdd
            ) => (
                {
                    url: `/add`,
                    headers: {
                        "x-access-token": TOKEN,
                    },
                    method: "POST",
                    body
                }
            ),
        }),

        // **** UPDATE FileManager
        updateFileManager: builder.mutation({
            invalidatesTags: ['file'],
            query: ({ body, id }: ApiFileManagerUpdate) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "PUT",
                body,
            }),
        }),


        // **** GET ALL FileManagers
        getAllFileManager: builder.query({
            providesTags: ['allFiles'],
            query: () => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "GET",

            }),
        }),

        // **** GET BY ID
        getFileManagerById: builder.query({
            providesTags: ['file'],
            query: (id: string) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "GET",

            }),
        }),

        // **** GET BY ID
        changeFileManagerStatus: builder.mutation({
            invalidatesTags: ['file'],
            query: (id: string) => ({
                url: `/change-status/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "PUT",

            }),
        }),

        // **** DELETE BY ID
        DeleteFileManagerById: builder.mutation({
            invalidatesTags: ['file'],
            query: (id: string) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "DELETE",

            }),
        }),

        /** EXPORT ALL FileManager DATA */
        exportFileManagerData: builder.query({
            query: ({ searchValue, params, filterBy, rangeFilterBy, orderBy, orderByValue }: CommonExportApiType) => ({
                url: `/export-all`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body: { searchValue, params, filterBy, rangeFilterBy, orderBy, orderByValue },
            }),
        }),

    }),
})

export const
    {
        useGetAllFileManagerQuery,
        useGetFileManagerWithPaginationQuery,
        useGetFileManagerByIdQuery,
        useAddFileManagerMutation,
        useChangeFileManagerStatusMutation,
        useUpdateFileManagerMutation,
        useExportFileManagerDataQuery,
        useDeleteFileManagerByIdMutation,

    } = fileManagerApi