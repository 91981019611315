import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiTransactionAdd } from "../../models/transaction.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddTransaction from "./AddTransaction";
// import { TransactionValidationSchema } from "../validationSchema/TransactionValidationSchema";
import { useAddTransactionMutation, useGetTransactionByIdQuery, useUpdateTransactionMutation } from "../../services/TransactionService";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";


const AddTransactionFormik = withFormik<OtherProps & formikApiProps,ApiTransactionAdd>({
  enableReinitialize:true,
    mapPropsToValues: (props) => {
      let values: ApiTransactionAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues
          break;
        case "Edit":
            values = props.intialValues
          break;
        case "View":
            values = props.intialValues
          break;
        default:
            values = props.intialValues
      }

      return values;
    },

    // validationSchema : TransactionValidationSchema,

    handleSubmit(values ,{props,setValues,setSubmitting}) {
      switch (props.formType) {
          case 'Edit':
            let reqBody = {
              status: values.status,
              amount: values.amount,
              paymentId : values.paymentId,
              type : values.type,
              payment_gateway : values.payment_gateway,
              userId : values.userId,
              orderId : values.orderId,
              userName : values.userName,
              payment_status_flow : values.payment_status_flow,
            }
            setValues({ ...values })
    
            props.editApi({ body: reqBody, id: props.selectedId }).then((response: any) => {
              if (response?.data?.status) {
                showToast(response?.data?.message, "success")
                setSubmitting(true)
                props.handleClose()
              } else {
                if (response?.error) {
                  props.setInitialValues(values)
                  showToast(response?.error?.data?.message, "error")
                } else {
                  props.setInitialValues(values)
                  showToast(response?.data?.message, "error")
                }
              }
            }
            )
            break
          default: values = props.intialValues
    
        }
      },
  })(AddTransaction);


const AddTransactionWrapper = React.forwardRef((props: OtherProps, ref) => {
  const {open=false, handleClose, formType } = props;
  const [addTransactionApi,addTransactionInfoApi ] = useAddTransactionMutation()
  const [updateTransactionApi, updateTransactionApiInfo]= useUpdateTransactionMutation()
  const transaction : any = useSelector((state:RootState) => state.transaction)
  const {selectedId} = transaction
  const {data: transactionData, isLoading: transactionDataIsLoading, isFetching: transactionDataIsFetching} = useGetTransactionByIdQuery(selectedId, {skip:!selectedId})
  const initialData : ApiTransactionAdd = {
    paymentId: "",
    amount: "",
    status: "",
    userId: "",
    type: "",
    orderId: "",
    userName: "",
    payment_gateway:"",
    payment_status_flow:  [""],
    }
  const [intialValues,setInitialValues ] = useState(initialData)
  useEffect(() =>{
    if(formType === "View" || formType === "Edit"){
      if(!transactionDataIsFetching && !transactionDataIsLoading){

        const dataTransaction = transactionData?.data

        let transactionDataList: ApiTransactionAdd = {
          paymentId : dataTransaction?.paymentId ,
          amount: dataTransaction?.amount,
          orderId : dataTransaction?.orderId,
          userId : dataTransaction?.userId,
          type:dataTransaction?.type,
          status : dataTransaction?.status,
          payment_gateway : dataTransaction?.payment_gateway,
          payment_status_flow : dataTransaction?.payment_status_flow,
          userName : dataTransaction?.userName,
        }
        setInitialValues(transactionDataList)
      }
    }
  }, [transactionDataIsLoading, transactionDataIsFetching, transactionData, formType])

  return (
    <div>
      <Modal
          className="h-screen "
          open={open}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
        >
          <Fade in={open}>
      <Box className="fade-box">
        <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
          {formType} Transaction
        </div>
        <AddTransactionFormik
          formType={formType}
          handleClose={() => handleClose()}
          isLoading={addTransactionInfoApi.status === "pending" || updateTransactionApiInfo.isLoading}
          intialValues={intialValues}
          setInitialValues={setInitialValues}
          addApi={addTransactionApi}
          editApi={updateTransactionApi}
          selectedId={selectedId}
        
          
        />
      </Box>
      </Fade>
        </Modal> 
    </div>
  );
});

export default AddTransactionWrapper;
