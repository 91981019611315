import { configureStore } from '@reduxjs/toolkit'
import { authApi } from '../services/AuthService';
import {  productGridApi } from '../services/ProductGridService';
import { categoryApi } from '../services/CategoryService';
import { contactUsApi } from '../services/ContactUsService';
import { couponApi } from '../services/CouponService';
import { enquiryApi } from '../services/EnquiresService';
import { feedbackApi } from '../services/FeedbackService';
import { fileManagerApi } from '../services/FileManagerService';
import { franchiseApi } from '../services/FranchiseService';
import { jobApplicationApi } from '../services/JobApplicationService';
import { jobApi } from '../services/JobService';
import { orderApi } from '../services/OrderService';
import { productApi } from '../services/ProductService';
import { reviewApi } from '../services/ReviewService';
import { recipeCategoryApi } from '../services/RecipeCategoryService';
import { transactionApi } from '../services/TransactionService';
import AuthSlice from './slices/AuthSlice';
import BannerSlices from './slices/BannerSlices';
import BrandSlice from './slices/BrandSlices';
import CategorySlices from './slices/CategorySlices';
import ContactUsSlice from './slices/ContactUsSlice';
import CouponSlices from './slices/CouponSlices';
import EnquiresSlices from './slices/EnquiresSlices';
import FeedbackSlices from './slices/FeedbackSlices';
import FileManagerSlice from './slices/FileManagerSlice';
import FranchiseSlices from './slices/FranchiseSlices';
import JobSlices from './slices/JobSlices';
import OrderSlice from './slices/OrderSlice';
import ProductGridSlices from './slices/ProductGridSlices';
import ProductSlice from './slices/ProductSlice';
import ReviewSlices from './slices/ReviewSlices';
import recipeCategorySlice from './slices/RecipeCategory';
import TransactionSlices from './slices/TransactionSlices';
import {homesliderApi } from '../services/BannerService';
import { brandApi } from '../services/BrandService';
import RecipeSlices from './slices/RecipeSlices';
import { recipeApi } from '../services/RecipeService';
import UserSlices from './slices/UserSlices';
import { userApi } from '../services/UserService';
import JobApplicationSlices from './slices/JobApplicationSlices';
import RegisterSlice from './slices/RegisterSlice';
import AdminUserSlices from './slices/AdminUserSlices';
import { adminUserApi } from '../services/AdminUserService';
import GallerySlices from './slices/GallerySlices';
import { galleryApi } from '../services/GalleryService';
import InstitutionalProductSlices from './slices/InstitutionalProductSlices';
import { institutionalProductApi } from '../services/InstitutionalProductService';
import GalleryCategorySlices from './slices/GalleryCategorySlices';
import { galleryCategoryApi } from '../services/GalleryCategoryService';
import DownloadSlices from './slices/DownloadSlices';
import { downloadApi } from '../services/DownloadService';
import DashboardSlices from './slices/DashboardSlices';
import { dashboardApi } from '../services/DashboardService';
import NewsletterSlices from './slices/NewsletterSlices';
import { newsletterApi } from '../services/NewsletterService';
import CompanyPolicySlices from './slices/CompanyPolicySlices';
import { companyPolicyApi } from '../services/CompanyPolicyService';
import ComboOffersSlice from './slices/ComboOffersSlice';
import { comboOfferApi } from '../services/ComboOffersService';

// import showToast from '../utils/toast';


const authMiddelware = () => (next: any) => (action: any) => {
  if (action.type.includes("rejected") && action.payload?.status === 401) {
    localStorage.clear()
    window.location.href = "/"
  }
  // if(action.type.includes("rejected") && action.payload?.status === 500){
  //   showToast("Internal Server Error")
  // }
  next(action)
}

const store = configureStore({
  reducer: {
    auth: AuthSlice,
    category: CategorySlices,
    contactUs: ContactUsSlice,
    fileManager : FileManagerSlice,
    dashboard : DashboardSlices,
    order : OrderSlice,
    recipeCategory : recipeCategorySlice,
    brand : BrandSlice,
    product : ProductSlice,
    newsletter : NewsletterSlices,
    review : ReviewSlices,
    job : JobSlices,
    jobApplication : JobApplicationSlices,
    feedback : FeedbackSlices,
    franchise : FranchiseSlices,
    enquiry : EnquiresSlices,
    transaction : TransactionSlices,
    coupon : CouponSlices,
    productGrid : ProductGridSlices,
    homeslider : BannerSlices,
    recipe :RecipeSlices,
    user : UserSlices,
    comboOffers : ComboOffersSlice,
    adminUser : AdminUserSlices,
    gallery : GallerySlices,
    institutionalProduct : InstitutionalProductSlices,
    galleryCategory : GalleryCategorySlices,
    download : DownloadSlices,
    content : CompanyPolicySlices,
    register : RegisterSlice,
    [authApi.reducerPath]: authApi.reducer,
    [categoryApi.reducerPath] : categoryApi.reducer,
    [fileManagerApi.reducerPath] : fileManagerApi.reducer,
    [recipeCategoryApi.reducerPath] : recipeCategoryApi.reducer,
    [contactUsApi.reducerPath] : contactUsApi.reducer,
    [orderApi.reducerPath] : orderApi.reducer,
    [brandApi.reducerPath] : brandApi.reducer,
    [productApi.reducerPath] : productApi.reducer,
    [reviewApi.reducerPath] : reviewApi.reducer,
    [jobApi.reducerPath] : jobApi.reducer,
    [jobApplicationApi.reducerPath] : jobApplicationApi.reducer,
    [franchiseApi.reducerPath]  : franchiseApi.reducer,
    [feedbackApi.reducerPath] : feedbackApi.reducer,
    [enquiryApi.reducerPath] : enquiryApi.reducer,
    [couponApi.reducerPath] : couponApi.reducer,
    [transactionApi.reducerPath] :transactionApi.reducer,
    [productGridApi.reducerPath]: productGridApi.reducer,
    [homesliderApi.reducerPath]: homesliderApi.reducer,
    [recipeApi.reducerPath] :recipeApi.reducer,
    [userApi.reducerPath] : userApi.reducer,
    [adminUserApi.reducerPath] : adminUserApi.reducer,
    [galleryApi.reducerPath] : galleryApi.reducer,
    [institutionalProductApi.reducerPath] : institutionalProductApi.reducer,
    [galleryCategoryApi.reducerPath] : galleryCategoryApi.reducer,
    [downloadApi.reducerPath] : downloadApi.reducer,
    [dashboardApi.reducerPath] : dashboardApi.reducer,
    [newsletterApi.reducerPath] : newsletterApi.reducer,
    [companyPolicyApi.reducerPath] : companyPolicyApi.reducer,
    [comboOfferApi.reducerPath] : comboOfferApi.reducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      authMiddelware,
      authApi.middleware,
      categoryApi.middleware,
      fileManagerApi.middleware,
      recipeCategoryApi.middleware,
      contactUsApi.middleware,
      orderApi.middleware,
      brandApi.middleware,
      productApi.middleware,
      reviewApi.middleware,
      jobApi.middleware,
      jobApplicationApi.middleware,
      franchiseApi.middleware,
      feedbackApi.middleware,
      enquiryApi.middleware,
      couponApi.middleware,
      transactionApi.middleware,
      productGridApi.middleware,
      homesliderApi.middleware,
      recipeApi.middleware,
      userApi.middleware,
      adminUserApi.middleware,
      galleryApi.middleware,
      institutionalProductApi.middleware,
      galleryCategoryApi.middleware,
      dashboardApi.middleware,
      downloadApi.middleware,
      companyPolicyApi.middleware,
      newsletterApi.middleware,
      comboOfferApi.middleware,
    ]),


})

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

