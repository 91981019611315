import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonExportApiType, CommonPaginationApiDataType } from '../models/commonTypes';
import { ApiRecipeCategoryAdd, ApiRecipeCategoryUpdate } from '../models/recipeCategoryModel';
import { BASE_URL } from "../utils/constants";
const AUTH_TOKEN = localStorage.getItem("authToken")

const TOKEN = AUTH_TOKEN ? AUTH_TOKEN : ""

export const recipeCategoryApi = createApi({
    reducerPath: 'recipeCategoryApi',
    tagTypes: ['file', 'allFiles'],
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/recipe-category` }),
    endpoints: (builder) => ({

        // **** GET WITH PAGINATION
        getRecipeCategoryWithPagination: builder.query({
            providesTags: ['file'],
            query: (body: CommonPaginationApiDataType) => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body
            }),
        }),

        // **** ADD SocialMedia
        addRecipeCategory: builder.mutation({
            invalidatesTags: ['file'],
            query: (body: ApiRecipeCategoryAdd
            ) => (
                {
                    url: `/add`,
                    headers: {
                        "x-access-token": TOKEN,
                    },
                    method: "POST",
                    body
                }
            ),
        }),

        // **** UPDATE RecipeCategory
        updateRecipeCategory: builder.mutation({
            invalidatesTags: ['file'],
            query: ({ body, id }: ApiRecipeCategoryUpdate) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "PUT",
                body,
            }),
        }),


        // **** GET ALL RecipeCategorys
        getAllRecipeCategory: builder.query({
            providesTags: ['allFiles'],
            query: () => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "GET",

            }),
        }),

        // **** GET BY ID
        getRecipeCategoryById: builder.query({
            providesTags: ['file'],
            query: (id: string) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "GET",

            }),
        }),

        // **** GET BY ID
        changeRecipeCategoryStatus: builder.mutation({
            invalidatesTags: ['file'],
            query: (id: string) => ({
                url: `/change-status/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "PUT",

            }),
        }),

        // **** DELETE BY ID
        DeleteRecipeCategoryById: builder.mutation({
            invalidatesTags: ['file'],
            query: (id: string) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "DELETE",

            }),
        }),

        /** EXPORT ALL RecipeCategory DATA */
        exportRecipeCategoryData: builder.query({
            query: ({ searchValue, params, filterBy, rangeFilterBy, orderBy, orderByValue }: CommonExportApiType) => ({
                url: `/export-all`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body: { searchValue, params, filterBy, rangeFilterBy, orderBy, orderByValue },
            }),
        }),

    }),
})

export const
    {
        useGetAllRecipeCategoryQuery,
        useGetRecipeCategoryWithPaginationQuery,
        useGetRecipeCategoryByIdQuery,
        useAddRecipeCategoryMutation,
        useChangeRecipeCategoryStatusMutation,
        useUpdateRecipeCategoryMutation,
        useExportRecipeCategoryDataQuery,
        useDeleteRecipeCategoryByIdMutation,

    } = recipeCategoryApi