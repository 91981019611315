import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from "../utils/constants";
import { LoginAPiDataType } from "../models/auth.model";

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/admin` }),
    endpoints: (builder) => ({
        //***** Login User *****/

        signIn: builder.mutation({
            query: ({ userName, password }: LoginAPiDataType) => ({
                url: "/login",
                method: "POST",
                body: { userName, password }
            })
        })
    })
})

export const { useSignInMutation } = authApi