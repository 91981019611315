import React from "react";
import { Form, FormikProps } from "formik";
import { ATMButton, ATMInput, ATMSelect } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiCategoryAdd } from "../../models/category.model";
import ATMFilePicker from "../../components/UI/atoms/ATMFilePicker";
import { WithContext as ReactTags } from "react-tag-input";

const AddCategory = (props: OtherProps & FormikProps<ApiCategoryAdd>) => {
  const {
    touched,
    errors,
    handleChange,
    handleClose,
    formType,
    setFieldValue,
    CategoryListSingle = null,
    values,
    isLoading,
  } = props;
  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          <div className="mb-4">
            <ATMFilePicker
              onSelect={(value) => setFieldValue("imageUrl", value)}
              onUnSelect={() => setFieldValue("imageUrl", "")}
              selected={values.imageUrl}
              label="Image "
              required={true}
              hidden={formType === "View" ? true : false}
              isValid={touched.imageUrl ? !touched.imageUrl : true}
              errorMessage={errors.imageUrl}
            >
              Select Image
            </ATMFilePicker>
          </div>
          <div className="mb-4">
            <ATMSelect
              options={CategoryListSingle}
              size="medium"
              placeholder="Parent Category"
              extraClasses="w-full"
              onChange={(e) =>
                setFieldValue("parent_category_id", e.target.value)
              }
              name="parent_category_id"
              label="Parent Category"
              value={values.parent_category_id}
              defaultValue={values.parent_category_id}
              required={false}
              disabled={formType === "View" ? true : false}
              isValid={
                touched.parent_category_id ? !touched.parent_category_id : true
              }
              errorMessage={errors.parent_category_id}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Category Name"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              value={values.title}
              name="title"
              label="Category Name"
              required={true}
              isValid={touched.title ? !touched.title : true}
              errorMessage={errors.title}
              readOnly={formType === "View" ? true : false}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Order"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              value={values.order}
              name="order"
              label="Order"
              required={true}
              isValid={touched.order ? !touched.order : true}
              errorMessage={errors.order}
              readOnly={formType === "View" ? true : false}
            />
          </div>

          <p className="mr-2 mb-2 text-bold">Tags</p>
          <div className="mb-4 border  w-full  h-20 overflow-y-auto">
            <ReactTags
              handleAddition={(tag) => {
                setFieldValue("tags", [...values.tags, tag]);
              }}
              handleDelete={(i) => {
                const newTags = values.tags.filter((ele, index) => {
                  return index !== i;
                });

                setFieldValue("tags", newTags);
              }}
              tags={values.tags}
              readOnly={formType === "View" ? true : false}
            />
          </div>
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses="hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer"
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton
            extraClasses="py-3 border bg-red-500 hover:bg-red-600 text-white"
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddCategory;
