import React from 'react'
import { Breadcrumbs, Link as MuiLink, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../../components/LayoutTemplates/headerLayout/Header';
import TMPListView from '../../components/UI/template/TMPListView';
import { TMPListViewPropType } from '../../models/commonTypes';


const ReviewList = (
    {
        headers,
        rows,
        renderRow,
        page,
        onPageChange,
        rowsPerPage,
        onRowsPerPageChange,
        rowCount,
        tableHeaderProps,
        isTableLoading = false,
        onRowClick,

    }: TMPListViewPropType
) => {
    return (
        <>
            <Header />
            <div className="container-lg mx-10 mt-10">

                {/* Breadcrumb & title */}
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <MuiLink
                            underline="hover"
                            className="flex items-center"
                            color="inherit"
                            href="/"
                        >
                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Inventory
                        </MuiLink>

                        <MuiLink
                            underline="hover"
                            className="flex items-center"
                            color="inherit"
                            href="/review"
                        >
                            Review
                        </MuiLink>
                    </Breadcrumbs>
                    <div className='text-d2 mt-2'>
                        Review
                    </div>
                </div>

                <div className='py-3'></div>

                {/* Table */}
                <div  >
                    <Paper>

                        <TMPListView
                            headers={headers}
                            rows={rows}
                            page={page}
                            onPageChange={(newPage) => { onPageChange(newPage) }}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={(newRowsPerPage) => { onRowsPerPageChange(newRowsPerPage); onPageChange(0) }}
                            rowCount={rowCount}
                            tableHeaderProps={tableHeaderProps}
                            isTableLoading={isTableLoading}
                            onRowClick={onRowClick}
                            renderRow={renderRow}

                        />
                    </Paper>
                </div>


            </div>


        </>
    )
}

export default ReviewList
