import { Form, FormikProps } from "formik";
import React from "react";
import { ATMButton, ATMInput, ATMSelect } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiDownloadAdd } from "../../models/Download.model";

const AddDownload = (props: OtherProps & FormikProps<ApiDownloadAdd>) => {
  const {
    touched,
    errors,
    isLoading,
    formType,
    handleChange,
    handleClose,
    values,
    setFieldValue,
  } = props;

  let IsActive = [{ selectLabel: "PDF ", value: "PDF" }];

  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="File"
              variant="outlined"
              extraClasses="w-full"
              inputType="file"
              onChange={(e) => setFieldValue("filePath", e.target.files[0])}
              name="filePath"
              label=" File "
              disabled={formType === "View" ? true : false}
              required={true}
              isValid={touched.filePath && errors.filePath ? false : true}
              errorMessage={errors.filePath}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="File Name"
              variant="outlined"
              extraClasses="w-full"              
              readOnly={formType === "View" ? true : false}
              onChange={handleChange}
              name="fileName"
              label="File Name"
              value={values.fileName}
              required={true}
              isValid={touched.fileName ? !touched.fileName : true}
              errorMessage={errors.fileName}
            />
          </div>

          <div className="mb-4">
            <ATMSelect
              options={IsActive}
              size="small"
              placeholder="File Type"
              extraClasses="w-full"
              onChange={handleChange}
              defaultValue={values.fileType}
              name="fileType"
              disabled={formType === "View" ? true : false}
              label=" File Type "
              required={true}
              isValid={touched.fileType ? !touched.fileType : true}
              errorMessage={errors.fileType}
            />
          </div>
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses="hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer"
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton
            extraClasses="py-3 border bg-red-400 hover:bg-red-500 text-white"
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddDownload;
