import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiProductGridAdd } from "../../models/productGrid.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddProductGrid from "./AddProductGrid";
import {
  useAddProductGridMutation,
  useGetProductGridByIdQuery,
  useUpdateProductGridMutation,
} from "../../services/ProductGridService";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { setCurrentPage } from "../../redux/slices/ProductGridSlices";
import { useGetProductWithPaginationQuery } from "../../services/ProductService";
import { ProductGridValidationSchema } from "../validationSchema/ProductGridValidationSchema";

const AddProductGridFormik = withFormik<
  OtherProps & formikApiProps,
  ApiProductGridAdd
>({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    let values: ApiProductGridAdd;
    switch (props.formType) {
      case "Add":
        values = props.intialValues;
        break;
      case "Edit":
        values = props.intialValues;
        break;
      case "View":
        values = props.intialValues;
        break;
      default:
        values = props.intialValues;
    }

    return values;
  },

  validationSchema: ProductGridValidationSchema,

  handleSubmit(values, { props, setValues, setSubmitting }) {
    switch (props.formType) {
      case "Add":
        props.addApi(values).then((response: any) => {
          if (response?.data?.status) {
            // props.setInitialValues(props.intialValues)
            showToast(response?.data?.message, "success");
            store.dispatch(setCurrentPage(0));
            // setSubmitting(true)
            props.handleClose(false);
          } else {
            if (response?.error) {
              props.setInitialValues(values);
              showToast(response?.error?.data?.message, "error");
            } else {
              props.setInitialValues(values);
              showToast(response?.data?.message, "error");
            }
          }
        });
        break;
      case "Edit":
        let reqBody = {
          grid_name: values.grid_name,
          order: values.order,
          products: values.products?.map((el: any) => {
            return {
              productId: el.productId,
              product_order: el.product_order,
            };
          }),
        };
        props
          .editApi({ body: reqBody, id: props.selectedId })
          .then((response: any) => {
            if (response?.data?.status) {
              showToast(response?.data?.message, "success");
              setSubmitting(true);
              props.handleClose();
            } else {
              if (response?.error) {
                props.setInitialValues(values);
                showToast(response?.error?.data?.message, "error");
              } else {
                props.setInitialValues(values);
                showToast(response?.data?.message, "error");
              }
            }
          });
        break;
      default:
        values = props.intialValues;
    }
  },
})(AddProductGrid);

const AddProductGridWrapper = React.forwardRef((props: OtherProps, ref) => {
  const { open = false, handleClose, formType } = props;
  const [addProductGridApi, addProductGridInfoApi] =
    useAddProductGridMutation();
  const [updateProductGridApi, updateProductGridApiInfo] =
    useUpdateProductGridMutation();
  const productGrid: any = useSelector((state: RootState) => state.productGrid);
  const { selectedId } = productGrid;
  const {
    data: productGridData,
    isLoading: productGridDataIsLoading,
    isFetching: productGridDataIsFetching,
  } = useGetProductGridByIdQuery(selectedId, { skip: !selectedId });
  const initialData: ApiProductGridAdd = {
    grid_name: "",
    order: "",
    products: [
      {
        productId: "",
        product_order: "",
      },
    ],
  };
  const {
    data: productData,
    isLoading: productDataIsLoading,
    isFetching: productDataIsFetching,
  } = useGetProductWithPaginationQuery({
    page: 0,
    limit: 0,
    searchValue: "",
    params: ["categoryTitle"],
    orderBy: "",
    isPaginationRequired: false,
  });
  const [intialValues, setInitialValues] = useState(initialData);
  useEffect(() => {
    if (formType === "View" || formType === "Edit") {
      if (!productGridDataIsFetching && !productGridDataIsLoading) {
        // const productDataId = productGridData?.data?.products?.map((el: any) => {

        //   return {
        //     selectLabel: el.title,
        //     value: el._id,
        //   };

        // })
        const dataProductGrid = productGridData?.data;
        let productGridList: ApiProductGridAdd = {
          grid_name: dataProductGrid?.grid_name,
          order: dataProductGrid?.order,
          products: dataProductGrid?.products?.map((product: any) => {
            return {
              productId: product._id,
              product_order: product.product_order,
            };
          }),
        };
        setInitialValues(productGridList);
      }
    }
  }, [
    productGridDataIsLoading,
    productGridDataIsFetching,
    productGridData,
    formType,
  ]);
  const [productOption, setCategoryOption] = useState([
    {
      selectLabel: "",
      value: "",
    },
  ]);
  useEffect(() => {
    if (!productDataIsLoading && !productDataIsFetching) {
      let productOptions = productData?.data
        ?.filter((data: { is_active: boolean }) => data.is_active === true)
        .map((ele: any) => {
          return {
            selectLabel: ele.title,
            value: ele._id,
          };
        });
      setCategoryOption(productOptions);
    }
  }, [productData, productDataIsLoading, productDataIsFetching]);
  return (
    <div>
      <Modal
        className="h-screen "
        open={open}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="fade-box">
            <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
              {formType} Product Grid
            </div>
            <AddProductGridFormik
              formType={formType}
              handleClose={() => handleClose()}
              isLoading={
                addProductGridInfoApi.status === "pending" ||
                updateProductGridApiInfo.isLoading
              }
              intialValues={intialValues}
              setInitialValues={setInitialValues}
              addApi={addProductGridApi}
              editApi={updateProductGridApi}
              selectedId={selectedId}
              productOption={productOption}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
});

export default AddProductGridWrapper;
