import React from 'react';
import { Provider } from 'react-redux'
import './App.css';
import { Toaster } from 'react-hot-toast'
import store from './redux/store';
import Navigation from './navigation'
import "./assets/css/extras.css";

function App() {
  return (
   
    <Provider store={store}>
      <Navigation/>
      <Toaster
        position="top-right"
        reverseOrder={false} /> 
    </Provider>
  );
}

export default App;
