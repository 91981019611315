import { toast } from "react-hot-toast";


const DURATION = 3000;

const showToast = (msg="Feature Under Development", type = "success", duration=DURATION) =>{
  
  switch(type){
    case "success":
      toast.success(msg, {duration})
      break;

      case "error":
        toast.error(msg, {duration})
        break;

      default :
        toast(msg, {duration})
  }

}

export default showToast;