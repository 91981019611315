import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiContactUsAdd } from "../../models/contactUs.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddContactUs from "./AddContactUs";
import { useAddContactUsMutation, useGetContactUsByIdQuery, useUpdateContactUsMutation } from "../../services/ContactUsService";
import {  useSelector } from "react-redux";
import store, {  RootState } from "../../redux/store";
import { setCurrentPage } from "../../redux/slices/ContactUsSlice";
import { ContactUsValidationSchema } from "../validationSchema/ContactUsValidationSchema";



const AddContactUsFormik = withFormik<OtherProps & formikApiProps,ApiContactUsAdd>({
  enableReinitialize:true,
    mapPropsToValues: (props) => {
      let values: ApiContactUsAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues
          break;
        case "Edit":
            values = props.intialValues
          break;
        case "View":
            values = props.intialValues
          break;
        default:
            values = props.intialValues
      }

      return values;
    },

    validationSchema : ContactUsValidationSchema,
    
    handleSubmit(values ,{props,setValues,setSubmitting}) {
      switch (props.formType) {
        case 'Add':
        props.addApi(values).then((response: any) => {
          if (response?.data?.status) {
            // props.setInitialValues(props.intialValues)
            showToast(response?.data?.message, "success")
              store.dispatch(setCurrentPage(0))
            // setSubmitting(true)
            props.handleClose(false)
          } else {
            if (response?.error) {
              props.setInitialValues(values)
              showToast(response?.error?.data?.message, "error")
            } else {
              props.setInitialValues(values)
              showToast(response?.data?.message, "error")
            }
          }

        }

        )
          break
          case 'Edit':

            let reqBody = {
              mobile: values.mobile,
              email: values.email,
              address : values.address,
              mapLink : values.mapLink,
              offerSticker : values.offerSticker,
              social_network_and_links: values.social_network_and_links?.map((el:any)=>{
                return{
                  name : el.name,
                  order : el.order,
                  icon : el.icon,
                  link : el.link,
                }
              })
            }
    
            props.editApi({ body: reqBody, id: props.selectedId }).then((response: any) => {
              if (response?.data?.status) {
                showToast(response?.data?.message, "success")
                setSubmitting(true)
                props.handleClose()
              } else {
                if (response?.error) {
                  props.setInitialValues(values)
                  showToast(response?.error?.data?.message, "error")
                } else {
                  props.setInitialValues(values)
                  showToast(response?.data?.message, "error")
                }
              }
            }
            )
            break
          default: values = props.intialValues
    
        }
      },
  })(AddContactUs);


const AddContactUsWrapper = React.forwardRef((props: OtherProps, ref) => {
  const {open=false, handleClose, formType } = props;
  const [addContactUsApi,addContactUsInfoApi ] = useAddContactUsMutation()
  const [updateContactUsApi, updateContactUsApiInfo]= useUpdateContactUsMutation()
  const contactUs : any = useSelector((state:RootState) => state.contactUs)
  const {selectedId} = contactUs
  const {data: contactUsData, isLoading: contactUsDataIsLoading, isFetching: contactUsDataIsFetching} = useGetContactUsByIdQuery(selectedId, {skip:!selectedId})
  const initialData : ApiContactUsAdd = {
    mobile: "",
    email: "",
    address: "",
    mapLink: "",
    offerSticker :"",
    social_network_and_links:[{
      order : "",
      link :"",
      icon :"",
      name : "",
    }],
  };
  const [intialValues,setInitialValues ] = useState(initialData)
  useEffect(() =>{
    if(formType === "View" || formType === "Edit"){
      if(!contactUsDataIsFetching && !contactUsDataIsLoading){

        const dataContactUs = contactUsData?.data

        let contactUsDataList: ApiContactUsAdd = {
          mobile: dataContactUs?.mobile,
          email: dataContactUs?.email,
          address : dataContactUs?.address,
          mapLink : dataContactUs?.mapLink,
          offerSticker : dataContactUs?.offerSticker,
          social_network_and_links : dataContactUs?.social_network_and_links
        }
        setInitialValues(contactUsDataList)
      }
    }
  }, [contactUsDataIsLoading, contactUsDataIsFetching, contactUsData, formType])

  return (
    <div>
      <Modal
          className="h-screen " 
          open={open}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
        >
          <Fade in={open}>
      <Box className="fade-box"sx={{ width: '40%' }}>
        <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
          {formType} Contact Us
        </div>
        <AddContactUsFormik
          formType={formType}
          handleClose={() => handleClose()}
          isLoading={addContactUsInfoApi.status === "pending" || updateContactUsApiInfo.isLoading}
          intialValues={intialValues}
          setInitialValues={setInitialValues}
          addApi={addContactUsApi}
          editApi={updateContactUsApi}
          selectedId={selectedId}
        />
      </Box>
      </Fade>
        </Modal> 
    </div>
  );
});

export default AddContactUsWrapper;
