import { ATMSelectPropTypes } from './ATMSelectPropTypes';


const ATMSelect = ({
    options = null,
    onChange,
    size = 'small',
    placeholder = "",
    extraClasses = "",
    label,
    isValid,
    errorMessage = "",
    required = false,
    disabled = false,
    helperText = "",
    name,
    defaultValue = "",
}: ATMSelectPropTypes) => {


    return (
        <>
            <div className="mb-2">
                {required ?
                    <p className="font-medium">{label}<span className='text-danger ps-3'>*</span></p>
                    :
                    <p className="font-medium">{label}</p>
                }
            </div>
            <select
                disabled={disabled}
                onChange={(e)=>onChange(e)}
                name={name}
                value={defaultValue}
                className={`block w-full mt-2 border px-3 rounded  hover:border-black shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 h-10 ${extraClasses}`}
            >
                <option value={''} disabled>
                    {placeholder}
                </option>

                {
                    options !== null ? options?.map(val => {
                        return (
                            <option key={val.value} value={val.value}>
                                {val.selectLabel}
                            </option>
                        );
                    }) : null
                }
            </select>

            <p className='text-red-400'>{isValid ? <span></span> : errorMessage}</p>
        </>
    );
};
export default ATMSelect;