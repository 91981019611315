import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonExportApiType, CommonPaginationApiDataType } from '../models/commonTypes';
import { ApiJobAdd, ApiJobUpdate } from '../models/job.model';
import { BASE_URL } from "../utils/constants";
const AUTH_TOKEN = localStorage.getItem("authToken")

const TOKEN = AUTH_TOKEN ? AUTH_TOKEN : ""

export const jobApi = createApi({
    reducerPath: 'jobApi',
    tagTypes: ['file', 'allFiles'],
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/Job` }),
    endpoints: (builder) => ({

        // **** GET WITH PAGINATION
        GetJobWithPagination: builder.query({
            providesTags: ['file'],
            query: (body: CommonPaginationApiDataType) => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body
            }),
        }),

        // **** ADD FileManager
        addJob: builder.mutation({
            invalidatesTags: ['file'],
            query: (body: ApiJobAdd
            ) => (
                {
                    url: `/add`,
                    headers: {
                        "x-access-token": TOKEN,
                    },
                    method: "POST",
                    body
                }
            ),
        }),

        // **** UPDATE FileManager
        updateJob: builder.mutation({
            invalidatesTags: ['file'],
            query: ({ body, id }: ApiJobUpdate) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "PUT",
                body,
            }),
        }),


        // **** GET ALL FileManagers
        getAllJob: builder.query({
            providesTags: ['allFiles'],
            query: () => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "GET",

            }),
        }),

        // **** GET BY ID
        getJobById: builder.query({
            providesTags: ['file'],
            query: (id: string) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "GET",

            }),
        }),

        // **** GET BY ID
        changeJobStatus: builder.mutation({
            invalidatesTags: ['file'],
            query: (id: string) => ({
                url: `/change-status/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "PUT",

            }),
        }),

        // **** DELETE BY ID
        DeleteJobById: builder.mutation({
            invalidatesTags: ['file'],
            query: (id: string) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "DELETE",

            }),
        }),

        /** EXPORT ALL FileManager DATA */
        exportJobData: builder.query({
            query: ({ searchValue, params, filterBy, rangeFilterBy, orderBy, orderByValue }: CommonExportApiType) => ({
                url: `/export-all`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body: { searchValue, params, filterBy, rangeFilterBy, orderBy, orderByValue },
            }),
        }),

    }),
})

export const
    {
        useGetAllJobQuery,
        useGetJobWithPaginationQuery,
        useGetJobByIdQuery,
        useAddJobMutation,
        useChangeJobStatusMutation,
        useUpdateJobMutation,
        useExportJobDataQuery,
        useDeleteJobByIdMutation,

    } = jobApi