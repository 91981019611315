import React from "react";
import { Form, FormikProps } from "formik";
import { ATMButton, ATMDatePicker, ATMInput, ATMSelect } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiCouponAdd } from "../../models/coupon.model";
import ATMTextArea from "../../components/UI/atoms/ATMTextArea/ATMTextArea";

const AddCoupon = (props: OtherProps & FormikProps<ApiCouponAdd>) => {
  const {
    touched,
    errors,
    handleChange,
    handleClose,
    setFieldValue,
    values,
    formType,
    isLoading,
  } = props;
  const couponDiscountType = [
    { selectLabel: "Flat", value: "FLAT" },
    { selectLabel: "Percentage", value: "PERCENTAGE" },
  ];


  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Coupon Code"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="coupon_code"
              label="Coupon Code "
              value={values.coupon_code}
              required={true}
              readOnly={formType === "View" ? true : false}
              isValid={touched.coupon_code ? !touched.coupon_code : true}
              errorMessage={errors.coupon_code}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Maximum Amount"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="max_discount_amount"
              value={values.max_discount_amount}
              label="Maximum Amount"
              required={true}
              readOnly={formType === "View" ? true : false}
              isValid={
                touched.max_discount_amount
                  ? !touched.max_discount_amount
                  : true
              }
              errorMessage={errors.max_discount_amount}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Minimum Order Value"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="min_order_value"
              label="Minimum Order Value"
              readOnly={formType === "View" ? true : false}
              value={values.min_order_value}
              required={true}
              isValid={
                touched.min_order_value ? !touched.min_order_value : true
              }
              errorMessage={errors.min_order_value}
            />
          </div>
          <div className="mb-4">
            <ATMTextArea
              size="medium"
              placeholder="Description"
              variant="outlined"
              extraClasses="w-full"
              disabled={formType === "View" ? true : false}
              onChange={(e) => setFieldValue("description", e.target.value)}
              name="description"
              label=" Description"
              value={values.description}
              required={true}
              isValid={touched.description ? !touched.description : true}
              errorMessage={errors.description}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Coupon Discount"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              readOnly={formType === "View" ? true : false}
              name="coupon_discount"
              label="Coupon Discount"
              value={values.coupon_discount}
              required={true}
              isValid={
                touched.coupon_discount ? !touched.coupon_discount : true
              }
              errorMessage={errors.coupon_discount}
            />
          </div>
          <div className="mb-4">
            <ATMSelect
              options={couponDiscountType || null}
              size="medium"
              placeholder="Discount Type"
              extraClasses="w-full"
              onChange={(e) => setFieldValue("discount_type", e.target.value)}
              name="discount_type"
              label="Discount Type"
              required={true}
              isValid={touched.discount_type ? !touched.discount_type : true}
              errorMessage={errors.discount_type}
              defaultValue={values.discount_type}
              value={values.discount_type}
              disabled={formType === "View" ? true : false}
            />
          </div>
          <div className="mb-4">
            <ATMDatePicker
              name="start_date"
              label="Start Date"
              views={['day', 'month', 'year']}
              inputFormat="dd/MM/yy"
              value={values?.start_date ? new Date(values.start_date) : null}
              onChange={(value) => { setFieldValue("start_date", value) }}
              required={true}
              size="medium"
              minDate={new Date()}
              isValid={touched.start_date ? !(touched.start_date) : true}
              errorMessage={errors.start_date}
              disabled={formType === "View" ? true : false}
            />
          </div>
          <div className="mb-4">
            <ATMDatePicker
              name="end_date"
              label="End Date"
              views={['day', 'month', 'year']}
              inputFormat="dd/MM/yy"
              value={values?.end_date ? new Date(values.end_date) : null}
              onChange={(value) => { setFieldValue("end_date", value) }}
              isValid={touched.end_date ? !(touched.end_date) : true}
              errorMessage={errors.end_date}
              disabled={formType === "View" ? true : false}
              required={true}
              size="medium"
              minDate={values?.start_date ? new Date(values?.start_date) : new Date()}
            />
          </div>
          {/* <div className="mb-4">
            <ATMSelect
              options={viewToAll || null}
              size="medium"
              placeholder="View To all"
              extraClasses="w-full"
              onChange={(e) => setFieldValue("view_to_all", e.target.value)}
              name="view_to_all"
              label="View To all"
              required={false}
              isValid={touched.view_to_all ? !touched.view_to_all : true}
              errorMessage={errors.view_to_all}
              defaultValue={values.view_to_all}
              value={values.view_to_all}
              disabled={formType === "View" ? true : false}
            />
          </div> */}
        
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses="hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer"
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton
            extraClasses="py-3 border bg-red-500 hover:bg-red-600 text-white"
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddCoupon;
