import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from "@reduxjs/toolkit";


export interface UserDetailType {
    email: string;
    name: string;
    userType:string
}
interface AuthSliceStateType {
    token: string | null | undefined;
    user: UserDetailType |  null
}

const initialState : AuthSliceStateType = {
    token: null,
    user: null
}
const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        changeAuth: (state, action: PayloadAction<AuthSliceStateType>) => {
                state.token = action.payload.token
                state.user = action.payload.user
        },
        setCurrentUser:(state, action : PayloadAction<UserDetailType>) => {
            state.user = action.payload
        }

    }
})

export const {changeAuth, setCurrentUser} = authSlice.actions
export default authSlice.reducer

