import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { dashboardDataModel } from '../models/dashboard.model';
import { BASE_URL } from "../utils/constants";
const AUTH_TOKEN = localStorage.getItem("authToken")

const TOKEN = AUTH_TOKEN ? AUTH_TOKEN : ""

export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    tagTypes: ['file', 'allFiles'],
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/dashboard` }),
    endpoints: (builder) => ({

        // **** GET WITH PAGINATION
        GetDashboardData: builder.mutation({
            query: (body:dashboardDataModel ) => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body
            }),
        }),    

    })
})

export const
    {
        useGetDashboardDataMutation,
    } = dashboardApi