import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiBannerAdd } from "../../models/banner.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddBanner from "./AddBanner";
import { BannerValidationSchema } from "../validationSchema/BannerValidtionSchema";
import { useAddBannerMutation, useGetBannerByIdQuery, useUpdateBannerMutation } from "../../services/BannerService";
import store, { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { setCurrentPage } from "../../redux/slices/BannerSlices";


const AddBannerFormik = withFormik<OtherProps & formikApiProps,ApiBannerAdd>({
   enableReinitialize: true,
    mapPropsToValues: (props) => {
      let values: ApiBannerAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues
          break;
        case "Edit":
            values = props.intialValues
          break;
        case "View":
            values = props.intialValues
          break;
        default:
            values = props.intialValues
      }

      return values;
    },

    validationSchema : BannerValidationSchema,

    handleSubmit(values ,{props,setValues,setSubmitting}) {
      switch (props.formType) {
        case 'Add':
        props.addApi(values).then((response: any) => {
          if (response?.data?.status) {
            // props.setInitialValues(props.intialValues)
            showToast(response?.data?.message, "success")
            store.dispatch(setCurrentPage(0))

            // setSubmitting(true)
            props.handleClose(false)
          } else {
            if (response?.error) {
              props.setInitialValues(values)
              showToast(response?.error?.data?.message, "error")
            } else {
              props.setInitialValues(values)
              showToast(response?.data?.message, "error")
            }
          }

        }

        )
          break
          case 'Edit':
            let reqBody = {
              imageUrl: values.imageUrl,
              order: values.order,
            }
    
            setValues({ ...values })
    
            props.editApi({ body: reqBody, id: props.selectedId }).then((response: any) => {
              if (response?.data?.status) {
                showToast(response?.data?.message, "success")
                setSubmitting(true)
                props.handleClose()
              } else {
                if (response?.error) {
                  props.setInitialValues(values)
                  showToast(response?.error?.data?.message, "error")
                } else {
                  props.setInitialValues(values)
                  showToast(response?.data?.message, "error")
                }
              }
            }
            )
            break
          default: values = props.intialValues
    
        }
      },
  })(AddBanner);



const AddBannerWrapper = React.forwardRef((props: OtherProps, ref) => {
  const {open=false, handleClose, formType } = props;
  const [addBannerApi,addBannerInfoApi ] = useAddBannerMutation()
  const [updateBannerApi,updateBannerInfoApi] = useUpdateBannerMutation()
 const Banner: any = useSelector((state: RootState) => state.homeslider)
  const { selectedId } = Banner
  const { data: bannerData, isFetching: bannerDataIsFetching, isLoading: bannerDataIsLoading } = useGetBannerByIdQuery(selectedId, { skip: !selectedId }) 
  const initialData : ApiBannerAdd = {
    imageUrl: "",
    order : 0,
  };
  const [intialValues,setInitialValues ] = useState(initialData)

  useEffect(() => {
    if (formType === "View" || formType === "Edit") {
      if (!bannerDataIsFetching && !bannerDataIsLoading) {
       
        const dataBanner = bannerData?.data

        let bannerDataList: ApiBannerAdd = {
          imageUrl: dataBanner?.imageUrl,
          order: dataBanner?.order,         
        }
        setInitialValues(bannerDataList)
      }
    }
  }, [bannerDataIsLoading, bannerDataIsFetching, bannerData, formType])

  

  return (
    <div>
      <Modal
          className="h-screen "
          open={open}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
        >
          <Fade in={open}>
      <Box className="fade-box">
        <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
          {formType} Banner
        </div>
        <AddBannerFormik
          formType={formType}
          handleClose={() => handleClose()}
          isLoading={addBannerInfoApi.status === "pending" || updateBannerInfoApi.isLoading}
          intialValues={intialValues}
          setInitialValues={setInitialValues}
          addApi={addBannerApi}
          selectedId= {selectedId}
          editApi={updateBannerApi}

          
        />
      </Box>
      </Fade>
        </Modal> 
    </div>
  );
});

export default AddBannerWrapper;
