import React from "react";
import { ErrorMessage, Form, FormikProps } from "formik";
import { ATMButton, ATMSelect } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiCompanyPolicyAdd } from "../../models/companyPolicyProduct.model";
import MOLMarkdownEditor from "../../components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";

const AddCompanyPolicy = (
  props: OtherProps & FormikProps<ApiCompanyPolicyAdd>
) => {
  const {
    touched,
    errors,
    setFieldValue,
    handleClose,
    formType,
    isLoading,
    values,
  } = props;
  const headerOptions = [
    { selectLabel: "Shipping", value: "SHIPPING" },
    { selectLabel: "Payment", value: "PAYMENT" },
    { selectLabel: "Refunds", value: "REFUNDS" },
    { selectLabel: "Terms and Conditions", value: "TERMS AND CONDITIONS" },
    { selectLabel: "Our Certificates", value: "OUR CERTIFICATES" },
  ];
  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          <div className="mb-4">
            <ATMSelect
              size="medium"
              defaultValue={values.header}
              placeholder="Header"
              extraClasses="w-full"
              onChange={(e) => setFieldValue("header", e.target.value)}
              name="header"
              label=" Header"
              required={true}
              value={values.header}
              isValid={touched.header ? !touched.header : true}
              errorMessage={errors.header}
              disabled={
                formType === "View" || formType === "Edit" ? true : false
              }
              options={headerOptions}
            />
          </div>

          <div className="mb-4">
            <MOLMarkdownEditor
              label="Content"
              required
              value={values.content}
              placeholder="Write description or drag your files here...."
              readonly={formType === "View" ? true : false}
              extraClassName="border-red-300 min-h-[250px] border-[1px] border-slate-200"
              onChange={(newValue: any) => {
                setFieldValue("content", newValue);
              }}
            />

            <ErrorMessage name="content">
              {(errorMessage) => (
                <p className="text-red-400 mt-1">{errorMessage}</p>
              )}
            </ErrorMessage>
          </div>
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses="hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer"
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton
            extraClasses="py-3 border bg-red-500 hover:bg-red-600 text-white"
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddCompanyPolicy;
