import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TextField } from '@mui/material'
import { ATMDatePickerPropType } from './ATMDatePickerPropType';
 


const ATMDatePicker = ({
    name,
    label="",
    views=['day', 'month', 'year'],
    inputFormat="dd/MM/yyyy",
    openTo="day",
    value,
    onChange=()=>{},
    isValid=true,
    errorMessage="",
    required=false,
    maxDate,
    minDate,
    size="small",
    disabled=false,
    readOnly=false,
    disableFuture=false
}:ATMDatePickerPropType) => {
    return (
        <>
         <div className="mb-2">
                {required ?
                <p className='font-medium'>{label}<span className='text-danger ps-3'>*</span></p>
                    :
                    <p className='font-medium'>{label}</p>
                }
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    value={value}
                    views={views}
                    disabled={disabled}
                    inputFormat={inputFormat}
                    readOnly={readOnly}
                    maxDate={maxDate}
                    minDate={minDate}
                    disableFuture={disableFuture}
                    openTo={openTo}
                    onChange={(newValue) => onChange(newValue)}
                    renderInput={(params:any) =>
                        <TextField id={name}
                            {...params}
                            variant="outlined"
                            size={size}
                            className="border-slate-300"
                            fullWidth />}
                />

            </LocalizationProvider>
            <p className='text-red-400'>{isValid ? "" : errorMessage}</p>
        </>
    )
}
export default ATMDatePicker
