import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { CommonTableColumnsPropTypes } from '../../models/commonTypes';

import {  setCurrentPage, setRowsPerPage,  setIsTableLoading,  setItems, setTotalItems } from '../../redux/slices/NewsletterSlices'
import {   useGetNewsletterWithPaginationQuery } from '../../services/NewsletterService';
import { NewsletterItemsTypes } from '../../models/Newsletter.model';
import NewsletterList from './Newsletter';

const NewsletterWrapper = () => {


    // **** Hooks
    const Newsletter: any = useSelector((state: RootState) => state.newsletter)
    const {
        searchValue,
        currentPage,
        rowsPerPage,
        isTableLoading,
        items,
        totalItems,      
    } = Newsletter

    const dispatch = useDispatch<AppDispatch>()

    // **** Services
    const { data, isFetching, isLoading } = useGetNewsletterWithPaginationQuery({
        page: currentPage + 1,
        limit: rowsPerPage,
        searchValue: searchValue.length >= 2 ? searchValue : "",
        params: [
            "emailId",          
        ],
        orderBy: ""
    })
 

    // **** Use Effect for set Playground Data
    useEffect(() => {
        if (!isFetching && !isLoading) {
            dispatch(setItems(data?.data || []))
            dispatch(setTotalItems(data?.totalItem || 0))
            dispatch(setIsTableLoading(false))

        } else {
            dispatch(setIsTableLoading(true))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, isLoading, data])

    // **** Table Columns Header
    const headers: CommonTableColumnsPropTypes[] = [
        {
            field: 'emailId',
            headerName: 'Email ID',
            flex: 1,

        },
    ]

    // **** Table Props
    const tableProps = {
        headers,
        rows: items,
        page: currentPage,
        onPageChange: (newPage: number) => { dispatch(setCurrentPage(newPage)) },
        rowsPerPage: rowsPerPage,
        onRowsPerPageChange: (newRowsPerPage: number) => { dispatch(setRowsPerPage(newRowsPerPage)); dispatch(setCurrentPage(0)) },
        rowCount: totalItems,
        isTableLoading,
        renderRow: (row: NewsletterItemsTypes) => ({
            extraClasses: ` ${!row.is_active && "bg-slate-300"} `,
            }),
    }

    return (
        <>
            <NewsletterList
                {...tableProps}
            />
        </>
    )
}
export default NewsletterWrapper
