import React from 'react'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Menu, MenuItem } from '@mui/material';


interface DeleteTypes {
    onClick: () => void;
    text: string;
    hideButton?: boolean;
}

interface ViewTypes {
    onClick: () => void;
    text: string;
    hideButton?: boolean;

}
interface EditTypes {
    onClick: () => void;
    text: string;
    hideButton?: boolean;

}

interface ActiveDeactiveTypes {
    onClick: () => void;
    text: string;
    hideButton?: boolean;

}

interface ActionType {
    delete?: DeleteTypes;
    view?: ViewTypes;
    edit?: EditTypes;
    activeDeactive?: ActiveDeactiveTypes
}

interface ORGTableActionMenuPropTypes {
    actions: ActionType
}




const ORGTableActionMenu = ({
    actions,
}: ORGTableActionMenuPropTypes
) => {


    // **** States
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    return (
        <>
            <button
                id="basic-button"
                aria-controls={openMenu ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={(event) => { event.stopPropagation(); setAnchorEl(event.currentTarget) }}
            >
                <MoreVertOutlinedIcon />
            </button>
            <Menu elevation={2}
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => { setAnchorEl(null) }}
                onClick={(event) => { event.stopPropagation() }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {actions.activeDeactive && !actions.activeDeactive.hideButton &&
                    <MenuItem onClick={(e) => { e.stopPropagation(); actions.activeDeactive?.onClick(); setAnchorEl(null) }} >
                        {actions.activeDeactive.text}
                    </MenuItem>
                }
                {actions.view && !actions.view.hideButton &&
                    <MenuItem onClick={(e) => { e.stopPropagation(); actions.view?.onClick(); setAnchorEl(null) }} >
                        {actions.view.text}
                    </MenuItem>
                }
                {actions.edit && !actions.edit.hideButton &&
                    <MenuItem onClick={(e) => { e.stopPropagation(); actions.edit?.onClick(); setAnchorEl(null) }} >
                        {actions.edit.text}
                    </MenuItem>
                }
                {
                    actions.delete && !actions.delete.hideButton &&
                    < MenuItem onClick={(e) => { e.stopPropagation(); actions.delete?.onClick(); setAnchorEl(null) }}  >
                        <div className='text-red-400' >
                            {actions.delete.text}
                        </div>
                    </MenuItem>
                }



            </Menu>
        </>
    )
}

export default ORGTableActionMenu
