import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';

interface ATMPaginationPropTypes {
    page: number;
    onPageChange: (newPage: number) => void
    rowsPerPage: number;
    onRowsPerPageChange: (newRowsPerPage: number) => void;
    rowCount: number
}

const ATMPagination = (
    {
        rowCount,
        page,
        onPageChange,
        rowsPerPage,
        onRowsPerPageChange,
    }: ATMPaginationPropTypes
) => {

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        onPageChange(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        onRowsPerPageChange(parseInt(event.target.value, 10));
    };

    return (
        <TablePagination
            component="div"
            count={rowCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
}

export default ATMPagination
