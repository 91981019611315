import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { AppBar, IconButton,  Avatar, ListItemIcon, Paper } from "@mui/material";
// import showToast from "../../../utils/toast";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Person2SharpIcon from '@mui/icons-material/Person2Sharp';
import Logout from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { setCurrentUser } from "../../../redux/slices/AuthSlice";


const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const clearLocalStorage = () => {
    localStorage.clear();
    window.location.href = "/"
  }
  const open = Boolean(anchorEl);
  const dispatch = useDispatch<AppDispatch>()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const authUser: any = useSelector((state: RootState) => state.auth);
  const { user } = authUser

  useEffect(() => {
    if (!user) {
      let data = localStorage.getItem("user")
      dispatch(setCurrentUser(data ? JSON.parse(data) : null))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])



  return (
    <div>
      <AppBar
        position="static"
        className="bg-gradient-to-r from-blue-100 to-blue-500"
      >
        <div className="flex justify-between m-2 grid-cols-2 align-center ">
          {/* LOGO */}
          <div className="text-h1 items-center flex align-center ">
            <img src="brownValleyLogo.png" className="h-10 w-half" alt="logo" />
          </div>

          {/* NAVIGATION */}
          <div className="flex ">
            <div className="flex items-center">
              <div className="mx-5">
                <Link to="/">Dashboard</Link>
              </div>
              <div className="mx-5 dropdown">
                <div  className=" inline    text-base  relative">
                <div className="text-white">Inventory</div>

                <div
                    className="dropdown-menu top-0 absolute hidden h-auto  w-auto"
                    style={{ zIndex: 100000 }}
                  >
                    <Paper className="">
                      <ul className="block mt-14">
                        <Link to="/brand">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Brand</p>
                          </li>
                        </Link>
                        <Link to="/category">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Category</p>
                          </li>
                        </Link>
                        <Link to="/product">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Product</p>
                          </li>
                        </Link>
                        <Link to="/review">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Review</p>
                          </li>
                        </Link>
                        <Link to="/institutional-product">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Institutional Product</p>
                          </li>
                        </Link>
                       </ul>
                    </Paper>
                  </div>

                </div>
              </div>
              <div className="mx-5">
                <Link to="/web-user">Web Users</Link>
              </div>
              <div className="mx-5">
                <Link to="/file-manager">File Manager</Link>
              </div>
              <div className="mx-5">
                <Link to="/order">Orders</Link>
              </div>
              <div className="mx-5 dropdown">
                <div  className=" inline    text-base  relative">
                <div className="text-white">Recipes & Category</div>

                <div
                    className="dropdown-menu top-0 absolute hidden h-auto  w-auto"
                    style={{ zIndex: 100000 }}
                  >
                    <Paper className="">
                      <ul className="block mt-14">
                        <Link to="/recipe-category">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Category</p>
                          </li>
                        </Link>
                        <Link to="/recipe">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Recipe</p>
                          </li>
                        </Link>
                       </ul>
                    </Paper>
                  </div>

                </div>
              </div>
              <div className="mx-5 dropdown">
                <div  className=" inline    text-base  relative">
                <div className="text-white">Gallery & Category</div>

                <div
                    className="dropdown-menu top-0 absolute hidden h-auto  w-auto"
                    style={{ zIndex: 100000 }}
                  >
                    <Paper className="">
                      <ul className="block mt-14">
                       
                        <Link to="/gallery-category">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base "> Category</p>
                          </li>
                        </Link>
                        <Link to="/gallery">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Gallery</p>
                          </li>
                        </Link>
                       </ul>
                    </Paper>
                  </div>

                </div>
              </div>

                          
              <div className="mx-5 dropdown">
                <div className=" inline   text-base  relative">
                  <div className="text-white">CMS</div>
                  <div
                    className="dropdown-menu hidden top-0 absolute  h-auto  w-auto"
                    style={{ zIndex: 100000 }}
                  >
                    <Paper className="">
                      <ul className="block mt-14">
                        <Link to="/product-grid">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Product Grid</p>
                          </li>
                        </Link>
                        <Link to="/combo-offers">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Combo Offers</p>
                          </li>
                        </Link>
                        <Link to="/homeslider">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Banner</p>
                          </li>
                        </Link>
                        <Link to="/contact-us">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Contact Details & Social Links</p>
                          </li>
                        </Link>
                      
                        <Link to="/admin-user">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Admin User</p>
                          </li>
                        </Link>

               
                        <Link to="/downloads">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Downloads</p>
                          </li>
                        </Link>
                      </ul>
                    </Paper>
                  </div>
                </div>
              </div>
              <div className="mx-5 dropdown">

                <div  className=" inline   text-base  relative">
                <div className="text-white">Other</div>

                <div
                    className="dropdown-menu hidden top-0 absolute  h-auto  w-auto"
                    style={{ zIndex: 100000 }}
                  >
                    <Paper className="">
                      <ul className="block mt-14">
                        <Link to="/job">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Job</p>
                          </li>
                        </Link>
                        <Link to="/job-application">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Job Application</p>
                          </li>
                        </Link>
                        <Link to="/franchise">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Franchise</p>
                          </li>
                        </Link>
                        <Link to="/feedback">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Feedback</p>
                          </li>
                        </Link>
                        <Link to="/enquiry">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Inquiry</p>
                          </li>
                        </Link>
                        <Link to="/newsletter">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Newsletter</p>
                          </li>
                        </Link>
                        <Link to="/coupon">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Coupon</p>
                          </li>
                        </Link>
                        <Link to="/transaction">
                          <li className="py-2  pl-5 pr-20 hover:bg-blue-100 hover:text-white-700">
                            <p className="text-base ">Transaction</p>
                          </li>
                        </Link>
                        
                       </ul>
                    </Paper>
                  </div>

                </div>
              </div>
              <div className="mx-5">
                <Link to="/company-policy">Company Policy</Link>
              </div>
            </div>
          </div>

          {/* PROFILE */}
          <div className="flex">
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <IconButton
                  onClick={handleClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  size="small"
                >
                  <Avatar sx={{ width: 32, height: 32 }}>{user?.fullName ? user.fullName[0] : ""}</Avatar>
                </IconButton>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  Hello Admin
                </MenuItem>
                <Link to="/update-password">
                  <MenuItem>
                    <ListItemIcon>
                      <Person2SharpIcon fontSize="small" />
                    </ListItemIcon> Change Password
                  </MenuItem>
                </Link>
                <Divider />

                <Link to="/" onClick={() => clearLocalStorage()}>
                  <MenuItem >
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Link>
              </Menu>
            </React.Fragment>
          </div>
        </div>
      </AppBar>
    </div>
  );
};

export default Header;
