import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiProductAdd } from "../../models/product.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddProduct from "./AddProduct";
import {
  useAddProductMutation,
  useGetProductByIdQuery,
  useUpdateProductMutation,
} from "../../services/ProductService";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { useGetAllCategoryQuery } from "../../services/CategoryService";
import { useGetBrandWithPaginationQuery } from "../../services/BrandService";
import { ProductValidationSchema } from "../validationSchema/ProductValidationSchema.tsx";
import { setCurrentPage } from "../../redux/slices/ProductSlice";

const AddProductFormik = withFormik<OtherProps & formikApiProps, ApiProductAdd>(
  {
    enableReinitialize: true,
    mapPropsToValues: (props) => {
      let values: ApiProductAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues;
          break;
        case "Edit":
          values = props.intialValues;
          break;
        case "View":
          values = props.intialValues;
          break;
        default:
          values = props.intialValues;
      }

      return values;
    },
    validationSchema: ProductValidationSchema,
    handleSubmit(values, { props, setValues, setSubmitting }) {
      switch (props.formType) {
        case "Add":
          let reqData = {
            title: values.title,
            mrp: values.mrp,
            sellingPrice: values.sellingPrice,
            images: values.images,
            tags: values.tags,
            stockQuantity: values.stockQuantity,
            packSize: `${values.packSize}`,
            shortDescription: values.shortDescription,
            longDescription: values.longDescription,
            productGroup: values.productGroup,
            productCode: values.productCode,
            slug: values.slug,
            pageTitle: values.pageTitle,
            metaTitle: values.metaTitle,
            metaDescription: values.metaDescription,
            categoryId: values.categoryId,
            brandId: values.brandId,
            is_combo: values.is_combo,
            og_title: values.og_title,
            og_description: values.og_description,
            og_image: values.og_image,
            in_offer: values.in_offer,
            is_featured: values.is_featured,
            in_stock: values.in_stock,
            combo_offer_text: values.combo_offer_text,
          };
          props.addApi(reqData).then((response: any) => {
            if (response?.data?.status) {
              // props.setInitialValues(props.intialValues)
              showToast(response?.data?.message, "success");
              store.dispatch(setCurrentPage(0));

              // setSubmitting(true)
              props.handleClose(false);
            } else {
              if (response?.error) {
                props.setInitialValues(values);
                showToast(response?.error?.data?.message, "error");
              } else {
                props.setInitialValues(values);
                showToast(response?.data?.message, "error");
              }
            }
          });
          break;
        case "Edit":

          let reqBody = {
            
            title: values.title,
            mrp: values.mrp,
            sellingPrice: values.sellingPrice,
            images: values.images,
            tags: values.tags,
            stockQuantity: values.stockQuantity,
            packSize: `${values.packSize}`,
            shortDescription: values.shortDescription,
            longDescription: values.longDescription,
            productGroup: values.productGroup,
            productCode: values.productCode,
            slug: values.slug,
            pageTitle: values.pageTitle,
            metaTitle: values.metaTitle,
            metaDescription: values.metaDescription,
            categoryId: values.categoryId,
            brandId: values.brandId,
            is_combo: `${values.is_combo}`,
            og_title: values.og_title,
            og_description: values.og_description,
            og_image: values.og_image,
            in_offer: `${values.in_offer}`,
            is_featured: `${values.is_featured}`,
            in_stock: `${values.in_stock}`,
            combo_offer_text: values.combo_offer_text,
          };

          setValues({ ...values });

          props
            .editApi({ body: reqBody, id: props.selectedId })
            .then((response: any) => {
              if (response?.data?.status) {
                showToast(response?.data?.message, "success");
                setSubmitting(true);
                props.handleClose();
              } else {
                if (response?.error) {
                  props.setInitialValues(values);
                  showToast(response?.error?.data?.message, "error");
                } else {
                  props.setInitialValues(values);
                  showToast(response?.data?.message, "error");
                }
              }
            });
          break;
        default:
          values = props.intialValues;
      }
    },
  }
)(AddProduct);

const AddProductWrapper = React.forwardRef((props: OtherProps, ref) => {
  const { open = false, handleClose, formType } = props;
  const [addProductApi, addProductInfoApi] = useAddProductMutation();
  const [updateProductApi, updateProductApiInfo] = useUpdateProductMutation();
  const product: any = useSelector((state: RootState) => state.product);
  const { selectedId } = product;
  const {
    data: productData,
    isLoading: productDataIsLoading,
    isFetching: productDataIsFetching,
  } = useGetProductByIdQuery(selectedId, { skip: !selectedId });
  const {
    data: categoryData,
    isLoading: categoryDataIsLoading,
    isFetching: categoryDataIsFetching,
  } = useGetAllCategoryQuery("");
  const {
    data: brandData,
    isLoading: brandDataIsLoading,
    isFetching: brandDataIsFetching,
  } = useGetBrandWithPaginationQuery({
    page: 0,
    limit: 0,
    searchValue: "",
    params: ["is_active"],
    orderBy: "",
    isPaginationRequired: false,
  });

  
  const initialData: ApiProductAdd = {
    title: "",
    mrp: "",
    sellingPrice: "",
    images: [""],
    in_stock: "",
    combo_offer_text: "",
    tags: [],
    stockQuantity: "",
    packSize: "",
    is_combo: "",
    shortDescription: "",
    longDescription: "",
    productGroup: "",
    productCode: "",
    slug: "",
    pageTitle: "",
    metaTitle: "",
    metaDescription: "",
    categoryId: "",
    brandId: "",
    og_title: "",
    og_description: "",
    og_image: "",
    in_offer: "",
    is_featured: "",
  };
  const [intialValues, setInitialValues] = useState(initialData);

  const [brandOption, setBrandOption] = useState([
    {
      selectLabel: "",
      value: "",
    },
  ]);

  useEffect(() => {
    if (!brandDataIsLoading && !brandDataIsFetching) {
      let brandOptions = brandData?.data
        ?.filter((data: { is_active: boolean }) => data.is_active === true)
        .map((ele: any) => {
          return {
            selectLabel: ele.brandName,
            value: ele._id,
          };
        });
      setBrandOption(brandOptions);
    }
  }, [brandData, brandDataIsLoading, brandDataIsFetching]);

  const [option, setOption] = useState([
    {
      selectLabel: "",
      value: "",
    },
  ]);
  useEffect(() => {
    if (!categoryDataIsLoading && !categoryDataIsFetching) {
      let options = categoryData?.data
        ?.filter((data: { is_active: boolean }) => data.is_active === true)
        .map((ele: any) => {
          return {
            selectLabel: ele.title,
            value: ele._id,
          };
        });
      setOption(options);
    }
  }, [categoryData, categoryDataIsLoading, categoryDataIsFetching]);

  useEffect(() => {
    if (formType === "View" || formType === "Edit") {
      if (!productDataIsFetching && !productDataIsLoading) {
        const dataProduct = productData?.data;
        let jobDataList: ApiProductAdd = {
          title: dataProduct?.title,
          mrp: dataProduct?.mrp,
          sellingPrice: dataProduct?.sellingPrice,
          in_stock: dataProduct?.in_stock,
          combo_offer_text: dataProduct?.combo_offer_text,
          images: dataProduct?.images,
          tags: dataProduct?.tags,
          stockQuantity: dataProduct?.stockQuantity,
          packSize: dataProduct?.packSize,
          shortDescription: dataProduct?.shortDescription,
          longDescription: dataProduct?.longDescription,
          productGroup: dataProduct?.productGroup,
          productCode: dataProduct?.productCode,
          slug: dataProduct?.slug,
          pageTitle: dataProduct?.pageTitle,
          metaTitle: dataProduct?.metaTitle,
          metaDescription: dataProduct?.metaDescription,
          categoryId: dataProduct?.categoryId,
          brandId: dataProduct?.brandId,
          is_combo: dataProduct?.is_combo,
          og_title: dataProduct?.og_title,
          og_description: dataProduct?.og_description,
          og_image: dataProduct?.og_image,
          in_offer: dataProduct?.in_offer,
          is_featured: dataProduct?.is_featured,
        };
        setInitialValues(jobDataList);
      }
    }
  }, [productDataIsLoading, productDataIsFetching, productData, formType]);

  return (
    <div>
      <Modal
        className="h-screen "
        open={open}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="fade-box" sx={{ width: "100%" }}>
            <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-10 py-9 px-3 text-h3 text-white font-medium">
              {formType} Product
            </div>
            <AddProductFormik
              formType={formType}
              handleClose={() => handleClose()}
              isLoading={
                addProductInfoApi.status === "pending" ||
                updateProductApiInfo.isLoading
              }
              intialValues={intialValues}
              setInitialValues={setInitialValues}
              addApi={addProductApi}
              editApi={updateProductApi}
              selectedId={selectedId}
              categoryListSingle={option}
              brandListSingle={brandOption}
             
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
});

export default AddProductWrapper;
