import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'

interface ColumnsPropTypes {
    field: string;
    headerName?: string;
    renderHeader?: () => React.ReactNode | string;
    flex?: number;
    extraClasses?: string;


}

interface ATMTableHeaderPropTypes {
    columns: ColumnsPropTypes[];
    isCheckbox?: boolean;
    selectedRows?: any[];
    setSelectedRows?: (newSelectedRows: any) => void;
    rows: any[]
}

const ATMTableHeader = (
    {
        columns,
        isCheckbox = false,
        selectedRows = [],
        setSelectedRows = () => { },
        rows
    }: ATMTableHeaderPropTypes
) => {


    return (
        <>
            {/* <colgroup className='flex'>
                {
                    columns.map((column, index) => (

                        <col key={index} style={{ flexGrow: column.flex ? column.flex : 0, flexBasis: 0 }} />

                    ))
                }
            </colgroup> */}
            <TableHead  >
                <TableRow  >

                    {
                        isCheckbox && rows.length > 0 &&
                        <TableCell>
                            <div className={`text-h4 text-slate-400 font-semibold `} >

                                <Checkbox checked={selectedRows.length === rows.length}
                                    onChange={() => rows.length === selectedRows.length ? setSelectedRows([]) : setSelectedRows(rows)}
                                />
                                Select All
                            </div>
                        </TableCell>

                    }
                    {
                        columns.map((column, index) => {
                            return (
                                <TableCell key={index} >
                                    <div className={`text-h4 text-slate-400 font-semibold ${column.extraClasses || ""}`} >
                                        {
                                            column.renderHeader ? column.renderHeader() : column.headerName
                                        }
                                    </div>
                                </TableCell>
                            )
                        })
                    }
                </TableRow>
            </TableHead>
        </>
    )
}

export default ATMTableHeader
