import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ORGTableActionMenu from '../../components/UI/organisms/ORGTableActionMenu';
import { AppDispatch, RootState } from '../../redux/store';
import showToast from '../../utils/toast';
import MOLDeleteConfirmationDialog from '../../components/UI/molecules/MOLDeleteConfirmationDialog';
import { CommonTableColumnsPropTypes, CommonTableHeaderPropTypes } from '../../models/commonTypes';

import { setSelectedId, setCurrentPage, setRowsPerPage, setSearchValue, setIsTableLoading,  setItems, setTotalItems,  setIsVisibleDeleteConfirmationDialog, setIsVisibleViewSideBar, setIsVisibleEditSideBar } from '../../redux/slices/JobApplicationSlices'
import {  useDeleteJobApplicationByIdMutation, useGetJobApplicationWithPaginationQuery } from '../../services/JobApplicationService';
import { JobApplicationItemsTypes } from '../../models/jobApplication.model ';
import JobApplicationList from './JobApplication';
import AddJobApplicationWrapper from '../../forms/jobApplication/AddJobApplicationWrapper';
import { Button } from '@mui/material';

const JobApplicationWrapper = () => {

    // **** State

    // **** Hooks
    const JobApplication: any = useSelector((state: RootState) => state.jobApplication)
    const {
        searchValue,
        currentPage,
        rowsPerPage,
        isTableLoading,
        isVisibleEditSideBar,
        isVisibleViewSideBar,
        items,
        totalItems,
        selectedId,
        isVisibleDeleteConfirmationDialog,
        
    } = JobApplication

    const dispatch = useDispatch<AppDispatch>()

    // **** Services
    const { data, isFetching, isLoading } = useGetJobApplicationWithPaginationQuery({
        page: currentPage + 1,
        limit: rowsPerPage,
        searchValue: searchValue.length >= 2 ? searchValue : "",
        params: [
            "name"
               ],
        orderBy: ""
    })
    const [deleteFile] = useDeleteJobApplicationByIdMutation()

    // **** Delete Handler
    const deleteHandler = () => {
        deleteFile(selectedId).then((res: any) => {
            if (res.data) {

                dispatch(setSelectedId(""))
                showToast(res.data?.message, res.data?.status ? "success" : "error");

            }
            if (res?.error) {
                showToast(res.error?.data?.message, "error")

            }
        }).catch((err: any) => {
            // console.log(err)
        })
        dispatch(setIsVisibleDeleteConfirmationDialog(false))
    }


    
    // **** Use Effect for set Playground Data
    useEffect(() => {
        if (!isFetching && !isLoading) {
            dispatch(setItems(data?.data || []))
            dispatch(setTotalItems(data?.totalItem || 0))
            dispatch(setIsTableLoading(false))

        } else {
            dispatch(setIsTableLoading(true))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, isLoading, data])

    // **** Table Columns Header
    const headers: CommonTableColumnsPropTypes[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,

        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
        },
        {
            field: 'mobile',
            headerName: 'Mobile',
            flex: 1,
        },
        {
            field: 'job_Title',
            headerName: 'Job Title',
            flex: 1,
        },
        {
            field: 'permanentAddress',
            headerName: 'Permanent Address',
            flex: 1,
        },
       
        {
            field: 'resume',
            headerName: 'Resume Url',
            renderCell:(row : JobApplicationItemsTypes) =>{
                return ( <>
                 <a href= {row.resume}><Button> Download resume </Button></a>
                
                </> )
            },
            flex: 1,
        },
       

        {
            field: 'actions',
            headerName: 'Actions',
            flex: 40,
            extraClasses: " flex justify-end",
            renderCell: (row: JobApplicationItemsTypes) => (
                <div className='flex justify-end pr-3 '>

                    <ORGTableActionMenu actions={{
                        delete: {
                            onClick: () => { dispatch(setSelectedId(row._id)); dispatch(setIsVisibleDeleteConfirmationDialog(true)) },
                            text: "Delete"
                        },
                        view: {
                            onClick: () => { dispatch(setSelectedId(row._id)); dispatch(setIsVisibleViewSideBar(true)); },
                            text: "View"
                        },
                        edit: {
                            onClick: () => { dispatch(setSelectedId(row._id)); dispatch(setIsVisibleEditSideBar(true)); },
                            text: "Edit"
                        }

                    }}
                    />
                </div>
            )
        },
    ]

    // **** Table Header Props 
    const tableHeaderProps: CommonTableHeaderPropTypes = {
       
        search: {
            searchValue: searchValue,
            setSearchValue: (newValue: string) => { dispatch(setSearchValue(newValue))}
        }
    }

    // **** Table Props
    const tableProps = {
        headers,
        rows: items,
        page: currentPage,
        onPageChange: (newPage: number) => { dispatch(setCurrentPage(newPage))},
        rowsPerPage: rowsPerPage,
        onRowsPerPageChange: (newRowsPerPage: number) => { dispatch(setRowsPerPage(newRowsPerPage)); dispatch(setCurrentPage(0))},
        rowCount: totalItems,
        tableHeaderProps,
        isTableLoading,
    }

    return (
        <>
            <JobApplicationList
                {...tableProps}
            />
            {
                isVisibleDeleteConfirmationDialog &&
                <MOLDeleteConfirmationDialog
                    onAccept={deleteHandler}
                    onDismiss={() => { dispatch(setIsVisibleDeleteConfirmationDialog(false)); dispatch(setSelectedId("")) }}
                />
            }
           
            {isVisibleEditSideBar && <AddJobApplicationWrapper open={isVisibleEditSideBar} handleClose={() => dispatch(setIsVisibleEditSideBar(false))} formType="Edit" />}
            {isVisibleViewSideBar && <AddJobApplicationWrapper open={isVisibleViewSideBar} handleClose={() => dispatch(setIsVisibleViewSideBar(false))} formType="View" />}

        </>
    )
}

export default JobApplicationWrapper
