import React from 'react'
import moment from "moment";

const OrderViewList = ({ singleOrderData }: any) => {


  const getDiscount = (mrp: any, sellingPrice: any) => {
    let offer = "";
    if (mrp > sellingPrice) {
      let finalMrp = parseFloat(mrp || 0)
      let finalSelling = parseFloat(sellingPrice || 0)

      offer = (((finalMrp - finalSelling) / mrp) * 100).toFixed(1);
    }
    if (offer) {
      return <span>({offer}% OFF)</span>;
    }
  };

  const PriceSection = ({ orderInfo }: any) => {
    return (
      <div
        className={`    items-baseline gap-2 font-normal `}
      >
        <span className="  text-md font-semibold text-slate-600">&#x20B9;{orderInfo?.sellingPrice} </span>
        <span className=" ">
          <small className="text-muted font-semibold text-slate-600">
            <s>&#x20B9;{orderInfo?.mrp}</s>
          </small>
        </span>
        <p className="mb-0 text-sm">
          <small className="text-orange-500">
            {getDiscount(orderInfo?.mrp, orderInfo?.sellingPrice)}
          </small>
        </p>
      </div>
    );
  };

  const getOrderStatus = (status: any) => {
    switch (status) {
      case "SUCCESS":
        return <div className="text-green-700 text-xs">{status}</div>;
      case "PENDING":
        return <div className="text-yellow-700 text-xs">{status}</div>;
      case "FAILED":
        return <div className="text-red-700 text-xs">{status}</div>;
    }
  };
  return (
    <div className='p-2'>
      <h4 className="primary-color  heading-style text-center xs:text-xl lg:text-2xl">
        Order View
      </h4>
      <div
        className="grid grid-cols-12 gap-4 mt-3 py-3 px-1 bg-white border rounded"
      >
        <div className='col-span-8'>
          <div className="flex justify-between bg-slate-50 rounded px-3 py-2  mb-3 text-sm text-slate-600 font-semibold">
            <span>ORDER ID : {singleOrderData?.order_unique_id} </span>
            <span>
              {moment(singleOrderData?.createdAt).format("DD MMM yyyy")}
            </span>
          </div>
          <div
            className="flex flex-col gap-4 justify-between items-start my-2 px-3 bg-slate-50 rounded py-3 w-full"
          >
            {singleOrderData?.products?.map((product: any) => {
              return (
                <div
                  key={product._id}
                  className="flex gap-4 justify-between items-start my-2 px-3 bg-slate-50 py-3 w-full border-b"
                >
                  <div key={product._id}>
                    <div className="h-[50px] w-[50px]">
                      <img src={product?.images?.[0] || ''} className="h-[50px] w-half" alt="order" />
                    </div>
                  </div>
                  <div className="grid grid-cols-12 justify-between grow gap-6 ">
                    <div className="xs:col-span-9 lg:col-span-6">
                      <div >
                        <h6 className=" capitalize content-style ">
                          {product?.title}
                        </h6>
                      </div>

                    </div>
                    <div className="xs:col-span-3 lg:col-span-4">
                      <p className="mb-1 text-muted text-sm font-bold">
                        Qty: {product.product_quantity}
                      </p>
                    </div>
                    <div className='xs:col-span-3 lg:col-span-2'>
                      <PriceSection
                        orderInfo={product}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="col-span-4 gap-2">
          <div className="text-sm bg-slate-50 p-3 rounded box-shadow-2">
            <h5 className="text-lg mb-2 font-[500]">Order Summary</h5>
            <div className="flex justify-between">
              <p className="text-slate-600 mt-1">Quantity</p>
              <p>{singleOrderData?.total_product_quantity}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-slate-600 mt-1">Applied Coupon</p>
              <p>
                {singleOrderData?.applied_coupon ? singleOrderData?.applied_coupon : "-"}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-slate-600 mt-1"> Status</p>
              <p>{getOrderStatus(singleOrderData?.status)}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-slate-600 mt-1">Amount</p>
              <p>{singleOrderData?.order_total_amount}</p>
            </div>
            <div className="flex justify-between font-semibold mt-1">
              <p>Order Total</p>
              <p>
                &#x20B9;{" "}
                {singleOrderData?.after_discount_order_total !== "0"
                  ? singleOrderData?.after_discount_order_total
                  : singleOrderData?.order_total_amount}
              </p>
            </div>
          </div>
          <div className="   text-sm bg-slate-50 p-3 rounded box-shadow-2">
            <h5 className="text-lg mb-2 font-[500]">
              Shipping Details
            </h5>
            <div className="text-slate-600 mt-1  flex gap-10 justify-between">
              <p className="">Name </p>
              <p>{singleOrderData?.user_billing_details?.fullName}</p>
            </div>
            <div className="text-slate-600 mt-1  flex gap-10 justify-between">
              <p className="">Mobile </p>
              <p>{singleOrderData?.user_billing_details?.Phone}</p>
            </div>
            <div className="text-slate-600 mt-1  flex gap-10 justify-between">
              <p className="">Email </p>
              <p>{singleOrderData?.user_billing_details?.email} </p>
            </div>
            <div className="text-slate-600 mt-1  flex gap-10 justify-between">
              <p className="">Address Line 1 </p>
              <p>{singleOrderData?.user_billing_address?.addressline1}</p>
            </div>
            <div className="text-slate-600 mt-1  flex gap-10 justify-between">
              <p className="">Address Line 2 </p>
              <p>{singleOrderData?.user_billing_address?.addressline2}</p>
            </div>
            <div className="text-slate-600 mt-1  flex gap-10 justify-between">
              <p className="">City </p>
              <p> {singleOrderData?.user_billing_address?.city} </p>
            </div>
            <div className="text-slate-600 mt-1  flex gap-10 justify-between">
              <p className="">State</p>
              <p>{singleOrderData?.user_billing_address?.state}</p>
            </div>
            <div className="text-slate-600 mt-1  flex gap-10 justify-between">
              <p className="">Pincode</p>
              <p>{singleOrderData?.user_billing_address?.pincode}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderViewList