  
import { object } from "yup"
import { isNumber, isString , isDate} from "../../validation/validation"
import * as Yup from 'yup';

  export const CouponValidationSchema = object({
    min_order_value:isNumber("Minimum Discount is"),
    description: isString("Description is"),
    coupon_code: isString("Coupon Code is"),
    max_discount_amount:  isNumber("Maximum Discount is"),
    discount_type: isString("Discount Type is"),
    coupon_discount : isNumber("Coupon Discount is"),
    start_date: isDate("Start Date ").typeError("Please specify valid date"),
    end_date: isDate("End Date ").typeError("Please specify valid date").min(Yup.ref("start_date"),"Please select date after the start date"),
  })



