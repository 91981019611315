import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiFranchiseAdd } from "../../models/franchise.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddFranchise from "./AddFranchise";
import { useAddFranchiseMutation, useGetFranchiseByIdQuery, useUpdateFranchiseMutation } from "../../services/FranchiseService";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FranchiseValidationSchema } from "../validationSchema/FranchiseValidtionSchema";


const AddFranchiseFormik = withFormik<OtherProps & formikApiProps,ApiFranchiseAdd>({
  enableReinitialize:true,
    mapPropsToValues: (props) => {
      let values: ApiFranchiseAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues
          break;
        case "Edit":
            values = props.intialValues
          break;
        case "View":
            values = props.intialValues
          break;
        default:
            values = props.intialValues
      }

      return values;
    },

    validationSchema : FranchiseValidationSchema,

    handleSubmit(values ,{props,setValues,setSubmitting}) {
      switch (props.formType) {
           case 'Edit':
            let reqBody = {
              name: values.name,
              email: values.email,
              occupation : values.occupation,
              experience : values.experience,
              city: values.city,
              mobile: values.mobile,
              have_fssai_licence: values.have_fssai_licence,
            }
    
            setValues({ ...values })
    
            props.editApi({ body: reqBody, id: props.selectedId }).then((response: any) => {
              if (response?.data?.status) {
                showToast(response?.data?.message, "success")
                setSubmitting(true)
                
                props.handleClose()
              } else {
                if (response?.error) {
                  props.setInitialValues(values)
                  showToast(response?.error?.data?.message, "error")
                } else {
                  props.setInitialValues(values)
                  showToast(response?.data?.message, "error")
                }
              }
            }
            )
            break
          default: values = props.intialValues
    
        }
      },
  })(AddFranchise);


const AddFranchiseWrapper = React.forwardRef((props: OtherProps, ref) => {
  const {open=false, handleClose, formType } = props;
  const [addFranchiseApi,addFranchiseInfoApi ] = useAddFranchiseMutation()
  const [updateFranchiseApi, updateFranchiseApiInfo]= useUpdateFranchiseMutation()
  const franchise : any = useSelector((state:RootState) => state.franchise)
  const {selectedId} = franchise
  const {data: franchiseData, isLoading: franchiseDataIsLoading, isFetching: franchiseDataIsFetching} = useGetFranchiseByIdQuery(selectedId, {skip:!selectedId})
  const initialData : ApiFranchiseAdd = {
            name: " ",
            email: " ",
            occupation:" ",
            city: " ",
            mobile:" ",
            have_fssai_licence: "",
            experience:" ",
  };
  const [intialValues,setInitialValues ] = useState(initialData)
  useEffect(() =>{
    if(formType === "View" || formType === "Edit"){
      if(!franchiseDataIsFetching && !franchiseDataIsLoading){

        const dataFranchise = franchiseData?.data

        let dataFranchiseList: ApiFranchiseAdd = {
          name: dataFranchise?.name,
          email: dataFranchise?.email,
          city: dataFranchise?.city,
          occupation: dataFranchise?.occupation,
          experience: dataFranchise?.experience,
          mobile: dataFranchise?.mobile,
          have_fssai_licence : dataFranchise?.have_fssai_licence,
       
          
        }
        setInitialValues(dataFranchiseList)
      }
    }
  }, [franchiseDataIsLoading, franchiseDataIsFetching, franchiseData, formType])

  return (
    <div>
      <Modal
          className="h-screen "
          open={open}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
        >
          <Fade in={open}>
      <Box className="fade-box">
        <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
          {formType} Admin User
        </div>
        <AddFranchiseFormik
          formType={formType}
          handleClose={() => handleClose()}
          isLoading={addFranchiseInfoApi.status === "pending" || updateFranchiseApiInfo.isLoading}
          intialValues={intialValues}
          setInitialValues={setInitialValues}
          addApi={addFranchiseApi}
          editApi={updateFranchiseApi}
          selectedId={selectedId}
        
          
        />
      </Box>
      </Fade>
        </Modal> 
    </div>
  );
});

export default AddFranchiseWrapper;
