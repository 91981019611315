import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from "@reduxjs/toolkit";


export interface AdminUserDetailType {
    fullName:string;
    email: string;
    userName:string;
    mobile :string;
    password:string;
    confirm_password:string;
    address:string;
}
interface RegisterSliceStateType {
    token: string | null | undefined;
    user: AdminUserDetailType |  null
}

const initialState : RegisterSliceStateType = {
    token: null,
    user: null
}
const RegisterSlice = createSlice({
    name: "register",
    initialState,
    reducers: {
        changeAdminUser: (state, action: PayloadAction<RegisterSliceStateType>) => {
                state.token = action.payload.token
                state.user = action.payload.user
        },
        setCurrentAdminUser:(state, action : PayloadAction<AdminUserDetailType>) => {
            state.user = action.payload
        }

    }
})

export const {changeAdminUser, setCurrentAdminUser} = RegisterSlice.actions
export default RegisterSlice.reducer

