import { Paper } from "@mui/material";
import OnboardingLayout from "../../../components/OnboardingLayout";
import React, { useState } from "react";
import { withFormik } from "formik";
import { LoginAPiDataType } from "../../../models/auth.model";
import showToast from "../../../utils/toast";
import LoginForm from "./LoginForm";
import { useSignInMutation } from "../../../services/AuthService";
import { formikApiProps, OtherProps } from "../../../models/addUI.model";
import { changeAuth } from "../../../redux/slices/AuthSlice";
import { useDispatch } from "react-redux";
import { LoginValidationSchema } from "../../../forms/validationSchema/LoginValidationSchema";

const LoginFormik = withFormik<OtherProps & formikApiProps, LoginAPiDataType>(
  {
  mapPropsToValues: (props) => {
    
   
    return  props.intialValues;
    ;
  },

  validationSchema: LoginValidationSchema,

  handleSubmit(values, { props }) {
    props.addApi(values).then((response: any) => {
      if (response?.data?.status) {
        showToast(response?.data?.message, "success");
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.data));

        props.dispatch(
          changeAuth({
            token: response?.data?.token,
            user: response?.data?.data,
          })
        );

        window.location.href = "/";
      } else {
        if (response?.error) {
          showToast(response?.error?.data?.message, "error");
          props.setInitialValues({ ...values });
        } else {
          showToast(response?.data?.message, "error");
          props.setInitialValues({ ...values });
        }
      }
    });
  },
})(LoginForm);

const LoginWrapper = React.forwardRef((props: OtherProps, ref) => {
  const { handleClose, formType } = props;
  const [signIn, signInInfo] = useSignInMutation();
  const initialData = {
    userName: "",
    password: "",
  };
  const [intialValues, setInitialValues] = useState(initialData);
  const dispatch = useDispatch();

  return (
    <div>
      <OnboardingLayout>
        <div className="flex justify-center w-full h-5/6  ">
          <Paper className=" w-5/12 py-5">
            <div className="flex justify-center ">
              <img src="/logo.png" width={"20%"} alt="" />
            </div>
            <div className="w-full p-3 border-l-2 border-slate-300 items-center justify-center flex mt-3">
              <div className="w-3/4">
                <div className="text-xl font-semibold text-center">Login</div>
                {/* <RegisterFormik dataLoading={registerInfo?.status==="pending"}/>
                 */}
                <LoginFormik
                  formType={formType}
                  handleClose={() => handleClose()}
                  isLoading={signInInfo.isLoading}
                  intialValues={intialValues}
                  setInitialValues={setInitialValues}
                  addApi={signIn}
                  dispatch={dispatch}
                />
              </div>
            </div>
          </Paper>
        </div>
      </OnboardingLayout>
    </div>
  );
});

export default LoginWrapper;
