  
import { object } from "yup"
import { isConfirmPassword, isEmail, isPassword, isPhoneNumber, isString} from "../../validation/validation"

  export const UserValidationSchema = object({
    fullName:isString("Full Name is"),
    email : isEmail("Email  "),
    mobile:isPhoneNumber("Contact Number is"),
    userName:isString("User name is"),
    password : isPassword("Password"),
    confirm_password : isConfirmPassword("Confirm Password ", "password")

  })

