import React from "react";
import { Form, FormikProps } from "formik";
import { ATMButton, ATMInput } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiAdminUserAdd } from "../../models/adminUser.model";

const AddAdminUser = (props: OtherProps & FormikProps<ApiAdminUserAdd>) => {
  const { touched, errors, handleChange, handleClose, formType, isLoading ,values} = props;
  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Full Name"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="fullName"
              value = {values.fullName}
              label="Full Name"
              required={true}
              readOnly={formType === "View" ? true : false}
              isValid={touched.fullName ? !touched.fullName : true}
              errorMessage={errors.fullName}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Email"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="email"
              value = {values.email}
              label="Email"
              readOnly={formType === "View" ? true : false}
              required={true}
              isValid={touched.email ? !touched.email : true}
              errorMessage={errors.email}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Username"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="userName"
              readOnly={formType === "View" ? true : false}
              label="Username"
              value = {values.userName}
              required={true}
              isValid={touched.userName ? !touched.userName : true}
              errorMessage={errors.userName}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Mobile Number "
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="mobile"
              label="Mobile Number"
              readOnly={formType === "View" ? true : false}
              required={true}
              value = {values.mobile}
             isValid={touched.mobile ? !touched.mobile : true}
              errorMessage={errors.mobile}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Address"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              value = {values.address}
             name="address"
              label="Address"
              readOnly={formType === "View" ? true : false}
              required={false}
              isValid={touched.address ? !touched.address : true}
              errorMessage={errors.address}
            />
          </div>    
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses="hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer"
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton
            extraClasses="py-3 border bg-red-500 hover:bg-red-600 text-white"
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddAdminUser;
