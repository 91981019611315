import React from "react";
import { ErrorMessage, FieldArray, Form, FormikProps } from "formik";
import { ATMButton, ATMInput, ATMSelect } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiProductAdd } from "../../models/product.model";
import ATMFilePicker from "../../components/UI/atoms/ATMFilePicker";
import { Box, Grid } from "@mui/material";
import ATMTextArea from "../../components/UI/atoms/ATMTextArea/ATMTextArea";
import { WithContext as ReactTags } from "react-tag-input";
import MOLMarkdownEditor from "../../components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";

const AddProduct = (props: OtherProps & FormikProps<ApiProductAdd>) => {
  const {
    touched,
    errors,
    handleChange,
    handleClose,
    formType,
    isLoading,
    setFieldValue,
    categoryListSingle = null,
    brandListSingle = null,
    values,
  } = props;

  const isCombo = [
    { selectLabel: "True", value: "true" },
    { selectLabel: "False", value: "false" },
  ];
  const isFeatured = [
    { selectLabel: "True", value: "true" },
    { selectLabel: "False", value: "false" },
  ];
  const inOffer = [
    { selectLabel: "True", value: "true" },
    { selectLabel: "False", value: "false" },
  ];

  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "82vh" }}
        >
          <p className="font-medium	m-4 text-2xl text-center text-blue-600 dark:text-blue-500">
            {" "}
            Product Details :
          </p>
          <Grid
            container
            item
            xs={12}
            p={2}
            justifyContent="justify"
            sx={{ border: "10px" }}
          >
            <Grid item xs={4} p={1}>
              <ATMSelect
                options={categoryListSingle}
                size="medium"
                defaultValue={values.categoryId}
                placeholder="Category  "
                extraClasses="w-64 "
                onChange={(e) => setFieldValue("categoryId", e.target.value)}
                name="categoryId"
                label=" Category "
                value={values.categoryId}
                required={true}
                disabled={formType === "View" ? true : false}
                isValid={touched.categoryId ? !touched.categoryId : true}
                errorMessage={errors.categoryId}
              />
            </Grid>
            <Grid item xs={4} p={1}>
              <ATMSelect
                options={brandListSingle}
                size="medium"
                defaultValue={values.brandId}
                placeholder="Brand "
                extraClasses="w-64"
                onChange={(e) => setFieldValue("brandId", e.target.value)}
                name="brandId"
                label=" Brand "
                value={values.brandId}
                required={true}
                disabled={formType === "View" ? true : false}
                isValid={touched.brandId ? !touched.brandId : true}
                errorMessage={errors.brandId}
              />
            </Grid>
            <Grid item xs={4} p={1}>
              <ATMInput
                size="small"
                placeholder="Product Name "
                variant="outlined"
                extraClasses="w-64 bg-white"
                onChange={handleChange}
                name="title"
                label="Product Name  "
                required={true}
                value={values.title}
                readOnly={formType === "View" ? true : false}
                isValid={touched.title ? !touched.title : true}
                errorMessage={errors.title}
              />
            </Grid>

            <Grid
              container
              item
              xs={12}
              justifyContent="justify"
              sx={{ border: "10px" }}
            >
              <Grid item xs={4} p={1} mt={1}>
                <ATMInput
                  size="small"
                  placeholder="MRP"
                  variant="outlined"
                  extraClasses="w-64 bg-white "
                  onChange={handleChange}
                  name="mrp"
                  label="MRP"
                  value={values.mrp}
                  readOnly={formType === "View" ? true : false}
                  required={true}
                  isValid={touched.mrp ? !touched.mrp : true}
                  errorMessage={errors.mrp}
                />
              </Grid>
              <Grid item xs={4} p={1} mt={1}>
                <ATMInput
                  size="small"
                  placeholder="Selling Price"
                  variant="outlined"
                  extraClasses="w-64 bg-white"
                  onChange={handleChange}
                  name="sellingPrice"
                  value={values.sellingPrice}
                  label="Selling Price "
                  readOnly={formType === "View" ? true : false}
                  required={true}
                  isValid={touched.sellingPrice ? !touched.sellingPrice : true}
                  errorMessage={errors.sellingPrice}
                />
              </Grid>
              <Grid item xs={4} p={1} mt={1}>
                <ATMInput
                  size="small"
                  placeholder="Product Quantity"
                  variant="outlined"
                  extraClasses="w-64 bg-white"
                  value={values.stockQuantity}
                  onChange={handleChange}
                  name="stockQuantity"
                  label="Product Quantity "
                  required={true}
                  readOnly={formType === "View" ? true : false}
                  isValid={
                    touched.stockQuantity ? !touched.stockQuantity : true
                  }
                  errorMessage={errors.stockQuantity}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="justify"
              sx={{ border: "10px" }}
            >
              <Grid item xs={4} p={1} mt={1}>
                <ATMInput
                  size="small"
                  placeholder="Package Size"
                  variant="outlined"
                  extraClasses="w-64 bg-white"
                  onChange={handleChange}
                  name="packSize"
                  value={values.packSize}
                  label="Package Size"
                  required={true}
                  isValid={touched.packSize ? !touched.packSize : true}
                  readOnly={formType === "View" ? true : false}
                  errorMessage={errors.packSize}
                />
              </Grid>
              <Grid item xs={4} p={1} mt={1}>
                <ATMSelect
                  size="medium"
                  defaultValue={values.is_combo}
                  placeholder="Is Combo"
                  extraClasses="w-64"
                  onChange={(e) => setFieldValue("is_combo", e.target.value)}
                  name="is_combo"
                  label=" Is Combo"
                  required={true}
                  value={values.is_combo}
                  isValid={touched.is_combo ? !touched.is_combo : true}
                  errorMessage={errors.is_combo}
                  disabled={formType === "View" ? true : false}
                  options={isCombo}
                />
              </Grid>
              {values.is_combo === "true" ? (
                <Grid item xs={4} p={1} mt={1}>
                  <ATMInput
                    size="small"
                    placeholder="Combo Offer Text"
                    variant="outlined"
                    extraClasses="w-64 bg-white"
                    onChange={handleChange}
                    name="combo_offer_text"
                    label="Combo Offer Text "
                    value={values.combo_offer_text}
                    readOnly={formType === "View" ? true : false}
                    isValid={
                      touched.combo_offer_text
                        ? !touched.combo_offer_text
                        : true
                    }
                    errorMessage={errors.combo_offer_text}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="justify"
              sx={{ border: "10px" }}
            >
              <Grid item xs={4} p={1} mt={1}>
                <ATMInput
                  size="small"
                  placeholder="Product Code"
                  variant="outlined"
                  extraClasses="w-64 bg-white"
                  onChange={handleChange}
                  name="productCode"
                  label="Product Code"
                  value={values.productCode}
                  readOnly={formType === "View" ? true : false}
                  required={true}
                  isValid={touched.productCode ? !touched.productCode : true}
                  errorMessage={errors.productCode}
                />
              </Grid>
              <Grid item xs={4} p={1} mt={1}>
                <ATMInput
                  size="small"
                  placeholder="Product Group"
                  variant="outlined"
                  extraClasses="w-64 bg-white"
                  onChange={handleChange}
                  name="productGroup"
                  label="Product Group"
                  value={values.productGroup}
                  required={true}
                  readOnly={formType === "View" ? true : false}
                  isValid={touched.productGroup ? !touched.productGroup : true}
                  errorMessage={errors.productGroup}
                />
              </Grid>
              <Grid item xs={4} p={1} mt={1}>
                <ATMSelect
                  size="medium"
                  defaultValue={values.in_stock}
                  placeholder="In Stock"
                  extraClasses="w-64 bg-white"
                  onChange={(e) => setFieldValue("in_stock", e.target.value)}
                  name="in_stock"
                  label=" In Stock"
                  required={true}
                  value={values.in_stock}
                  isValid={touched.in_stock ? !touched.in_stock : true}
                  errorMessage={errors.in_stock}
                  disabled={formType === "View" ? true : false}
                  options={isCombo}
                />
              </Grid>

              <Grid item xs={4} p={1} mt={1}>
                <ATMSelect
                  size="medium"
                  defaultValue={values.in_offer}
                  placeholder="In Offer"
                  extraClasses="w-64 bg-white"
                  onChange={(e) => setFieldValue("in_offer", e.target.value)}
                  name="in_offer"
                  label=" In Offer"
                  required={true}
                  value={values.in_offer}
                  isValid={touched.in_offer ? !touched.in_offer : true}
                  errorMessage={errors.in_offer}
                  disabled={formType === "View" ? true : false}
                  options={inOffer}
                />
              </Grid>

              <Grid item xs={4} p={1} mt={1}>
                <ATMTextArea
                  size="medium"
                  placeholder="Short Description"
                  variant="outlined"
                  extraClasses="w-64"
                  onChange={(e) =>
                    setFieldValue("shortDescription", e.target.value)
                  }
                  name="shortDescription"
                  label=" Short Description"
                  value={values.shortDescription}
                  disabled={formType === "View" ? true : false}
                  required={true}
                  isValid={
                    touched.shortDescription ? !touched.shortDescription : true
                  }
                  errorMessage={errors.shortDescription}
                />
              </Grid>
            </Grid>
          </Grid>
          <p className="font-medium	m-4 text-2xl text-center text-blue-600 dark:text-blue-500">
            {" "}
            SEO :
          </p>

          <Grid
            container
            item
            xs={12}
            p={2}
            justifyContent="justify"
            sx={{ border: "10px" }}
          >
            <Grid item xs={4} p={1}>
              <ATMInput
                size="small"
                placeholder="Page Title"
                variant="outlined"
                extraClasses="w-64 bg-white"
                onChange={handleChange}
                value={values.pageTitle}
                name="pageTitle"
                label="Page Title"
                required={false}
                readOnly={formType === "View" ? true : false}
                isValid={touched.pageTitle ? !touched.pageTitle : true}
                errorMessage={errors.pageTitle}
              />
            </Grid>
            <Grid item xs={4} p={1}>
              <ATMInput
                size="small"
                placeholder="Meta Title"
                variant="outlined"
                extraClasses="w-64 bg-white"
                onChange={handleChange}
                name="metaTitle"
                readOnly={formType === "View" ? true : false}
                label="Meta Title"
                value={values.metaTitle}
                required={false}
                isValid={touched.metaTitle ? !touched.metaTitle : true}
                errorMessage={errors.metaTitle}
              />
            </Grid>
            <Grid item xs={4} p={1}>
              <ATMInput
                size="small"
                placeholder="Meta Description"
                variant="outlined"
                extraClasses="w-64 bg-white"
                onChange={handleChange}
                value={values.metaDescription}
                name="metaDescription"
                label="Meta Description"
                readOnly={formType === "View" ? true : false}
                required={false}
                isValid={
                  touched.metaDescription ? !touched.metaDescription : true
                }
                errorMessage={errors.metaDescription}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="justify"
              sx={{ border: "10px" }}
            >
              <Grid item xs={4} p={1} mt={1}>
                <ATMSelect
                  size="medium"
                  defaultValue={values.is_featured}
                  placeholder="Is Featured"
                  extraClasses="w-64 "
                  onChange={(e) => setFieldValue("is_featured", e.target.value)}
                  name="is_featured"
                  label=" Is Featured"
                  required={true}
                  value={values.is_featured}
                  isValid={touched.is_featured ? !touched.is_featured : true}
                  errorMessage={errors.is_featured}
                  disabled={formType === "View" ? true : false}
                  options={isFeatured}
                />
              </Grid>
              <Grid item xs={4} p={1} mt={1}>
                <ATMInput
                  size="small"
                  placeholder="Og title"
                  variant="outlined"
                  extraClasses="w-64 bg-white"
                  onChange={handleChange}
                  value={values.og_title}
                  name="og_title"
                  label="Og title"
                  readOnly={formType === "View" ? true : false}
                  required={false}
                  isValid={touched.og_title ? !touched.og_title : true}
                  errorMessage={errors.og_title}
                />
              </Grid>
              <Grid item xs={4} p={1} mt={1}>
                <ATMInput
                  size="small"
                  placeholder="Og Image"
                  variant="outlined"
                  extraClasses="w-64 bg-white"
                  onChange={handleChange}
                  value={values.og_image}
                  name="og_image"
                  label="Og Image"
                  readOnly={formType === "View" ? true : false}
                  required={false}
                  isValid={touched.og_image ? !touched.og_image : true}
                  errorMessage={errors.og_image}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="justify"
              sx={{ border: "10px" }}
            >
              <Grid item xs={4} p={1} mt={1}>
                <ATMInput
                  size="small"
                  placeholder="Og Description"
                  variant="outlined"
                  extraClasses="w-64 bg-white"
                  onChange={handleChange}
                  value={values.og_description}
                  name="og_description"
                  label="Og Description"
                  readOnly={formType === "View" ? true : false}
                  required={false}
                  isValid={
                    touched.og_description ? !touched.og_description : true
                  }
                  errorMessage={errors.og_description}
                />
              </Grid>
              <Grid item xs={4} p={1} mt={1}>
                <ATMInput
                  size="small"
                  placeholder="Slug"
                  variant="outlined"
                  extraClasses="w-64 bg-white"
                  onChange={handleChange}
                  name="slug"
                  value={values.slug}
                  label="Slug"
                  required={false}
                  readOnly={formType === "View" ? true : false}
                  isValid={touched.slug ? !touched.slug : true}
                  errorMessage={errors.slug}
                />
              </Grid>
              <Grid item xs={4} p={1} mt={1}>
                <p className="mr-2 mb-2 text-bold">Tags</p>
                <div className="mb-4 border  w-64  h-20 overflow-y-auto">
                  <ReactTags
                    autofocus={false}
                    handleAddition={(tag) => {
                      setFieldValue("tags", [...values.tags, tag]);
                    }}
                    handleDelete={(i) => {
                      const newTags = values.tags.filter((ele, index) => {
                        return index !== i;
                      });

                      setFieldValue("tags", newTags);
                    }}
                    tags={values.tags}
                    readOnly={formType === "View" ? true : false}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={1}>
              {/* <p className="font-medium	p-2">
                {" "}
                Long Description <span className="text-danger"> * </span>
              </p> */}

              <div className="z-0 border rounded-md">
                <MOLMarkdownEditor
                  label=""
                  value={values.longDescription}
                  placeholder="Write description or drag your files here...."
                  extraClassName="border-red-300 min-h-[250px] border-[1px] border-slate-200"
                  onChange={(newValue: any) => {
                    setFieldValue("longDescription", newValue);
                  }}
                />
              </div>

              <ErrorMessage name="longDescription">
                {(errorMessage) => (
                  <p className="text-red-400 mt-1">{errorMessage}</p>
                )}
              </ErrorMessage>
            </Grid>
            <p className="font-medium	mt-3 p-2 ">
              {" "}
              Images : <span className="text-danger">*</span>
            </p>
            <Grid
              container
              item
              xs={12}
              justifyContent="justify"
              sx={{ border: "10px" }}
              p={1}
              m={2}
            >
              <FieldArray
                name="images"
                render={(arrayHelpers) => (
                  <>
                    {values.images.map((friend, index) => (
                      <Box
                        className=""
                        sx={{ height: "50%", width: "20%" }}
                        key={index}
                      >
                        <div className="mb-4">
                          <ATMFilePicker
                            onSelect={(value) =>
                              setFieldValue(`images[${index}]`, value)
                            }
                            onUnSelect={() =>
                              setFieldValue(`images[${index}]`, "")
                            }
                            selected={values.images[index]}
                            hidden={formType === "View" ? true : false}
                          >
                            Select Images
                          </ATMFilePicker>
                          <ErrorMessage name={`images[${index}]`}>
                            {(errorMessage) => (
                              <p className="text-red-400 mt-1">
                                {errorMessage}
                              </p>
                            )}
                          </ErrorMessage>
                        </div>

                        <button
                          type="button"
                          className=" hover:bg-red-500  text-white text-xs ml-2 mt-2 bg-red-500 font-normal	 hover:text-white py-1 px-1 border border-black-300 hover:border-transparent rounded"
                          onClick={() => arrayHelpers.remove(index)}
                          hidden={formType === "View" ? true : false}
                        >
                          Remove
                        </button>
                      </Box>
                    ))}
                    <div>
                      <button
                        type="button"
                        className=" hover:bg-blue-500  text-white text-xs p-2 m-2  bg-blue-500 font-normal hover:text-white border border-black-300 hover:border-transparent rounded"
                        onClick={() =>
                          arrayHelpers.push({
                            images: "",
                          })
                        }
                        hidden={formType === "View" ? true : false}
                      >
                        Add
                      </button>
                    </div>
                  </>
                )}
              />
            </Grid>
          </Grid>
        </div>
        <div className="grid justify-items-end text-fields p-4 ">
          <div className="flex w-80">
            <ATMButton
              hidden={formType === "View" ? true : false}
              extraClasses="hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer"
              text={formType === "Edit" ? "Update" : formType}
              type="submit"
              loading={isLoading}
            />
            <ATMButton
              extraClasses="py-3 border bg-red-500 hover:bg-red-600 text-white"
              text="Cancel"
              disabled={false}
              onClick={() => handleClose(false)}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddProduct;
