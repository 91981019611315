import { object } from "yup";
import {
  isString,
  isPhoneNumber,
  isEmail,
  isLink,
} from "../../validation/validation";
import * as Yup from 'yup';

export const ContactUsValidationSchema = object({
  email: isEmail("Email "),
  mobile: isPhoneNumber("Contact Number is"),
  address: isString("Address is"),
  mapLink: isLink("Map Link is"),
  offerSticker : isString("Offer Sticker is"),
  social_network_and_links: Yup.array().of(Yup.object().shape({
    link : Yup.string().required("Social Link is required") ,
    icon : Yup.string().required("Social Icon is required") ,
    name : Yup.string().required("Social Name is required") ,
    order : Yup.number().typeError("Only numbers are allowed").required("Social Order is requried")
  })),
});
