import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
// import { CompanyPolicyValidationSchema } from "../validationSchema/CompanyPolicyValidationSchema";
import { useAddCompanyPolicyMutation, useGetCompanyPolicyByIdQuery, useUpdateCompanyPolicyMutation } from "../../services/CompanyPolicyService";
import {  useSelector } from "react-redux";
import store, {  RootState } from "../../redux/store";
import { setCurrentPage } from "../../redux/slices/CompanyPolicySlices";
import AddCompanyPolicy from "./AddCompanyPolicy";
import { ApiCompanyPolicyAdd } from "../../models/companyPolicyProduct.model";



const AddCompanyPolicyFormik = withFormik<OtherProps & formikApiProps,ApiCompanyPolicyAdd>({
  enableReinitialize:true,
    mapPropsToValues: (props) => {
      let values: ApiCompanyPolicyAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues
          break;
        case "Edit":
            values = props.intialValues
          break;
        case "View":
            values = props.intialValues
          break;
        default:
            values = props.intialValues
      }

      return values;
    },

    // validationSchema : CompanyPolicyValidationSchema,
    

    handleSubmit(values, { props, setValues, setSubmitting }) {
      switch (props.formType) {
        case "Add":
          props.addApi(values).then((response: any) => {
            if (response?.data?.status) {
              // props.setInitialValues(props.intialValues)
              showToast(response?.data?.message, "success");
              store.dispatch(setCurrentPage(0));
  
              // setSubmitting(true)
              props.handleClose(false);
            } else {
              if (response?.error) {
                props.setInitialValues(values);
                showToast(response?.error?.data?.message, "error");
              } else {
                props.setInitialValues(values);
                showToast(response?.data?.message, "error");
              }
            }
          });
          break;
        case "Edit":
          let reqBody = {
            content: values.content,
          };
  
          setValues({ ...values });
  
          props
            .editApi({ body: reqBody, id: props.selectedId })
            .then((response: any) => {
              if (response?.data?.status) {
                showToast(response?.data?.message, "success");
                setSubmitting(true);
                props.handleClose();
              } else {
                if (response?.error) {
                  props.setInitialValues(values);
                  showToast(response?.error?.data?.message, "error");
                } else {
                  props.setInitialValues(values);
                  showToast(response?.data?.message, "error");
                }
              }
            });
          break;
        default:
          values = props.intialValues;
      }
    },
  })(AddCompanyPolicy);


const AddCompanyPolicyWrapper = React.forwardRef((props: OtherProps, ref) => {
  const {open=false, handleClose, formType } = props;
  const [addCompanyPolicyApi,addCompanyPolicyInfoApi ] = useAddCompanyPolicyMutation()
  const [updateCompanyPolicyApi, updateCompanyPolicyApiInfo]= useUpdateCompanyPolicyMutation()
  const companyPolicy : any = useSelector((state:RootState) => state.content)
  const {selectedId} = companyPolicy
  const {data: companyPolicyData, isLoading: companyPolicyDataIsLoading, isFetching: companyPolicyDataIsFetching} = useGetCompanyPolicyByIdQuery(selectedId, {skip:!selectedId})
  const initialData : ApiCompanyPolicyAdd = {
    header: "",
    content: "",

  };
  const [intialValues,setInitialValues ] = useState(initialData)
  useEffect(() =>{
    if(formType === "View" || formType === "Edit"){
      if(!companyPolicyDataIsFetching && !companyPolicyDataIsLoading){

        const dataCompanyPolicy = companyPolicyData?.data

        let companyPolicyDataList: ApiCompanyPolicyAdd = {
          header: dataCompanyPolicy?.header,
          content: dataCompanyPolicy?.content,
        }
        setInitialValues(companyPolicyDataList)
      }
    }
  }, [companyPolicyDataIsLoading, companyPolicyDataIsFetching, companyPolicyData, formType])

  return (
    <div>
      <Modal
          className="h-screen "
          open={open}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
        >
          <Fade in={open}>
      <Box className="fade-box"  sx={{ width: "40%" }}>
        <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
          {formType} Company Policy
        </div>
        <AddCompanyPolicyFormik
          formType={formType}
          handleClose={() => handleClose()}
          isLoading={addCompanyPolicyInfoApi.status === "pending" || updateCompanyPolicyApiInfo.isLoading}
          intialValues={intialValues}
          setInitialValues={setInitialValues}
          addApi={addCompanyPolicyApi}
          editApi={updateCompanyPolicyApi}
          selectedId={selectedId}
        
          
        />
      </Box>
      </Fade>
        </Modal> 
    </div>
  );
});

export default AddCompanyPolicyWrapper;
