import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonExportApiType, CommonPaginationApiDataType } from '../models/commonTypes';
import {  ApiJobApplicationUpdate } from '../models/jobApplication.model ';
import { BASE_URL } from "../utils/constants";
const AUTH_TOKEN = localStorage.getItem("authToken")

const TOKEN = AUTH_TOKEN ? AUTH_TOKEN : ""

export const jobApplicationApi = createApi({
    reducerPath: 'jobApplicationApi',
    tagTypes: ['file', 'allFiles'],
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/job-application` }),
    endpoints: (builder) => ({

        // **** GET WITH PAGINATION
        GetJobApplicationWithPagination: builder.query({
            providesTags: ['file'],
            query: (body: CommonPaginationApiDataType) => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body
            }),
        }),

        // **** ADD FileManager
        // addJobApplication: builder.mutation({
        //     invalidatesTags: ['file'],
        //     query: (body: ApiJobApplicationAdd
        //     ) => (
        //         {
        //             url: `/add`,
        //             headers: {
        //                 "x-access-token": TOKEN,
        //             },
        //             method: "POST",
        //             body
        //         }
        //     ),
        // }),

        // **** UPDATE FileManager
        updateJobApplication: builder.mutation({
            invalidatesTags: ['file'],
            query: ({ body, id }: ApiJobApplicationUpdate) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "PUT",
                body,
            }),
        }),


        // **** GET ALL FileManagers
        getAllJobApplication: builder.query({
            providesTags: ['allFiles'],
            query: () => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "GET",

            }),
        }),

        // **** GET BY ID
        getJobApplicationById: builder.query({
            providesTags: ['file'],
            query: (id: string) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "GET",

            }),
        }),

        // **** GET BY ID
        changeJobApplicationStatus: builder.mutation({
            invalidatesTags: ['file'],
            query: (id: string) => ({
                url: `/change-status/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "PUT",

            }),
        }),

        // **** DELETE BY ID
        DeleteJobApplicationById: builder.mutation({
            invalidatesTags: ['file'],
            query: (id: string) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "DELETE",

            }),
        }),

        /** EXPORT ALL FileManager DATA */
        exportJobApplicationData: builder.query({
            query: ({ searchValue, params, filterBy, rangeFilterBy, orderBy, orderByValue }: CommonExportApiType) => ({
                url: `/export-all`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body: { searchValue, params, filterBy, rangeFilterBy, orderBy, orderByValue },
            }),
        }),

    }),
})

export const
    {
        useGetAllJobApplicationQuery,
        useGetJobApplicationWithPaginationQuery,
        useGetJobApplicationByIdQuery,
        // useAddJobApplicationMutation,
        useChangeJobApplicationStatusMutation,
        useUpdateJobApplicationMutation,
        useExportJobApplicationDataQuery,
        useDeleteJobApplicationByIdMutation,

    } = jobApplicationApi