import { Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useGetDashboardDataMutation } from '../../services/DashboardService';
import { ATMDatePicker } from "../../components/UI";
import { Button } from '@mui/material'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Link as MuiLink } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CategoryIcon from '@mui/icons-material/Category';
import DiamondIcon from '@mui/icons-material/Diamond';
import ReceiptIcon from '@mui/icons-material/Receipt';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import GroupIcon from '@mui/icons-material/Group';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ReviewsIcon from '@mui/icons-material/Reviews';
import moment from 'moment'


const Dashboard = () => {

  const [startDate, setStartDate] = useState<any>(moment().subtract(6, "days"))
  const [endDate, setEndDate] = useState<any>(moment())
  const [getDashboardData, getDashboardDataInfo] = useGetDashboardDataMutation()
  const [dashboardApiData, setDashboardApiData] = useState<any>(null)

  const handleStartDate = (e: any) => {
    setStartDate(e)
  }
  const handleEndDate = (e: any) => {
    setEndDate(e)
  }

  useEffect(() => {
    getDashboardData({
      start_date: startDate._d,
      end_date: endDate._d
    }).then((res: any) => {
      setDashboardApiData(res?.data?.data || null)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onApplyClickHandler = () => {
    getDashboardData({
      start_date: moment(startDate).format('yyyy-MM-DD'),
      end_date: moment(endDate).format('yyyy-MM-DD')
    }).then((res: any) => {
      setDashboardApiData(res?.data?.data || null)
    })

  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars


  return (
    <>
      <div className='grid grid-cols-6 gap-2  m-4 p-4' >
        <div>
          <ATMDatePicker
            name="start_date"
            label="Start Date"
            inputFormat="dd/MM/yy"
            value={startDate}
            isValid={true}
            size="medium"
            views={['day', 'month', 'year']}
            onChange={(e) => handleStartDate(e)}
          />
        </div>
        <div>
          <ATMDatePicker
            name="end_date"
            label="To"
            inputFormat="dd/MM/yyyy"
            isValid={true}
            size="medium"
            views={['day', 'year']}
            value={endDate}

            onChange={(e) => handleEndDate(e)}
          />
        </div>
        <div className='flex justify-center mt-10 gap-2' >
          <div>
            <Button variant='contained' onClick={() => onApplyClickHandler()} > Apply   </Button>
          </div>
        </div>
      </div>
      <div className='grid grid-rows-12  grid-cols-12 gap-10 p-5 mt-3 '>

        <div className='col-span-3' >
          <Paper elevation={8}  >
            <div className='py-5 mt-4 	 ' >
              <MuiLink
                underline="none"
                className=" items-center"
                color="inherit"
                href="/order"
              >
                <div className='text-center text-lg ' >
                  Total Orders <ReceiptLongIcon />
                </div>

                <div className='flex justify-center mt-2 text-sky-500 text-2xl' >
                  {
                    getDashboardDataInfo?.isLoading ?
                      <div className='animate-pulse w-[80px] h-[40px] bg-slate-200' ></div>
                      :
                      dashboardApiData?.allorderTotal
                  }
                </div>

              </MuiLink>
            </div>

          </Paper>
        </div>

        <div className='col-span-3 ' >
          <Paper elevation={8}  >
            <div className='py-5  mt-4 ' >
              <MuiLink
                underline="none"
                className=" items-center"
                color="inherit"
                href="/product"
              >
                <div className='text-center text-lg ' >
                  Total Products <AddShoppingCartIcon />
                </div>
                <div className='flex justify-center mt-2 text-sky-500 text-2xl' >
                  {
                    getDashboardDataInfo?.isLoading ?
                      <div className='animate-pulse w-[80px] h-[40px] bg-slate-200' ></div>
                      :
                      dashboardApiData?.allproductTotal
                  }
                </div>
              </MuiLink>

            </div>

          </Paper>
        </div>

        <div className='col-span-3 ' >
          <Paper elevation={8}  >
            <div className='py-5  mt-4 ' >
              <MuiLink
                underline="none"
                className=" items-center"
                color="inherit"
                href="/category"
              >
                <div className='text-center text-lg ' >
                  Total Categories <CategoryIcon />
                </div>
                <div className='flex justify-center mt-2 text-sky-500 text-2xl' >
                  {
                    getDashboardDataInfo?.isLoading ?
                      <div className='animate-pulse w-[80px] h-[40px] bg-slate-200' ></div>
                      :
                      dashboardApiData?.allcategoryTotal?.[0]?.order || 0
                  }
                </div>
              </MuiLink>

            </div>
          </Paper>
        </div>

        <div className='col-span-3 ' >
          <Paper elevation={8}  >
            <div className='py-5  mt-4 ' >
              <MuiLink
                underline="none"
                className=" items-center"
                color="inherit"
                href="/brand"
              >
                <div className='text-center text-lg ' >
                  Total Brands <DiamondIcon />
                </div>
                <div className='flex justify-center mt-2 text-sky-500 text-2xl' >
                  {
                    getDashboardDataInfo?.isLoading ?
                      <div className='animate-pulse w-[80px] h-[40px] bg-slate-200' ></div>
                      :
                      dashboardApiData?.allbrandTotal
                  }
                </div>
              </MuiLink>

            </div>
          </Paper>
        </div>

        <div className='col-span-3 ' >
          <Paper elevation={8}  >
            <div className='py-5  mt-4 ' >
              <MuiLink
                underline="none"
                className=" items-center"
                color="inherit"
                href="/recipe"
              >
                <div className='text-center text-lg ' >
                  Total Recipies <ReceiptIcon />
                </div>
                <div className='flex justify-center mt-2 text-sky-500 text-2xl' >
                  {
                    getDashboardDataInfo?.isLoading ?
                      <div className='animate-pulse w-[80px] h-[40px] bg-slate-200' ></div>
                      :
                      dashboardApiData?.allrecipeTotal
                  }
                </div>
              </MuiLink>

            </div>
          </Paper>
        </div>

        <div className='col-span-3 ' >
          <Paper elevation={8}  >
            <div className='py-5  mt-4' >
              <MuiLink
                underline="none"
                className=" items-center"
                color="inherit"
                href="/job-application"
              >
                <div className='text-center text-lg ' >
                  Total Job Applications <WorkHistoryIcon />
                </div>
                <div className='flex justify-center mt-2 text-sky-500 text-2xl' >
                  {
                    getDashboardDataInfo?.isLoading ?
                      <div className='animate-pulse w-[80px] h-[40px] bg-slate-200' ></div>
                      :
                      dashboardApiData?.alljobAppTotal
                  }
                </div>
              </MuiLink>

            </div>
          </Paper>
        </div>

        <div className='col-span-3 ' >
          <Paper elevation={8}  >
            <div className='py-5  mt-4 ' >
              <MuiLink
                underline="none"
                className=" items-center"
                color="inherit"
                href="/web-user"
              >
                <div className='text-center text-lg ' >
                  Total Web Users <GroupIcon />
                </div>
                <div className='flex justify-center mt-2 text-sky-500 text-2xl' >
                  {
                    getDashboardDataInfo?.isLoading ?
                      <div className='animate-pulse w-[80px] h-[40px] bg-slate-200' ></div>
                      :
                      dashboardApiData?.allUserTotal
                  }
                </div>
              </MuiLink>

            </div>
          </Paper>
        </div>

        <div className='col-span-3 ' >
          <Paper elevation={8}  >
            <div className='py-5  mt-4 ' >
              <MuiLink
                underline="none"
                className=" items-center"
                color="inherit"
                href="/feedback"
              >              <div className='text-center text-lg ' >
                  Feedback (Pending) <InsertCommentIcon />
                </div>
                <div className='flex justify-center mt-2 text-sky-500 text-2xl' >
                  {
                    getDashboardDataInfo?.isLoading ?
                      <div className='animate-pulse w-[80px] h-[40px] bg-slate-200' ></div>
                      :
                      dashboardApiData?.allfeedbackTotal
                  }
                </div>
              </MuiLink>

            </div>
          </Paper>
        </div>

        <div className='col-span-6' >
          <Paper elevation={8}  >
            <div className='py-5  mt-4 ' >
              <MuiLink
                underline="none"
                className=" items-center"
                color="inherit"
                href="/contact-us"
              >
                <div className='text-center text-lg ' >
                  Contact Us <ContactPhoneIcon />
                </div>
                <div className='flex justify-center mt-2 text-sky-500 text-2xl' >
                  {
                    getDashboardDataInfo?.isLoading ?
                      <div className='animate-pulse w-[80px] h-[40px] bg-slate-200' ></div>
                      :
                      dashboardApiData?.allContactDetails
                  }
                </div>
              </MuiLink>

            </div>
          </Paper>
        </div>

        <div className='col-span-6' >
          <Paper elevation={8}  >
            <div className='py-5  mt-4 ' >
              <MuiLink
                underline="none"
                className=" items-center"
                color="inherit"
                href="/review"
              >
                <div className='text-center text-lg ' >
                  Total Reviews <ReviewsIcon />
                </div>
                <div className='flex justify-center mt-2 text-sky-500 text-2xl' >
                  {
                    getDashboardDataInfo?.isLoading ?
                      <div className='animate-pulse w-[80px] h-[40px] bg-slate-200' ></div>
                      :
                      dashboardApiData?.allReviewTotal
                  }
                </div>
              </MuiLink>

            </div>
          </Paper>
        </div>

      </div>
    </>
  )
}

export default Dashboard