import React from "react";
import { ErrorMessage, FieldArray, Form, FormikProps } from "formik";
import {  ATMButton, ATMSelect } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiGalleryAdd } from "../../models/gallery.model";
import ATMFilePicker from "../../components/UI/atoms/ATMFilePicker";

const AddGallery = (props: OtherProps & FormikProps<ApiGalleryAdd>) => {
  const { touched, errors,  handleChange, handleClose, formType, gallerycategoryListSingle = null, setFieldValue,isLoading,values } =
    props;


  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        > <div className="mb-4">
        <ATMSelect
          options={gallerycategoryListSingle}
          size="medium"
          placeholder="Gallery Category"
          extraClasses="w-full"
          onChange={handleChange}
          name="category"
          label="Gallery Category"
          value={values.category}
          defaultValue={values.category}
          required={true}
          disabled={formType === "View" ? true : false}
          isValid={
            touched.category ? !touched.category : true
          }
          errorMessage={errors.category}
        />
      </div>
           <div className="mb-4">
           <p className="font-medium	 ">
            {" "}
            Images : <span className="text-danger"> * </span>{" "}
          </p>
          <FieldArray
            name="imageUrl"
            render={(arrayHelpers) => (
              <>
                {values.imageUrl.map((friend, index) => (
                  <div className="mb-4" key={index}>
                    <ATMFilePicker
                      onSelect={(value) =>
                        setFieldValue(`imageUrl[${index}]`, value)
                      }
                      onUnSelect={() => setFieldValue(`imageUrl[${index}]`, "")}
                      selected={values.imageUrl[index]}
                      hidden={formType === "View" ? true : false}
                      isValid={touched.imageUrl ? !touched.imageUrl : true}
                    >
                      Select Images
                    </ATMFilePicker>

                    <ErrorMessage name={`imageUrl[${index}]`}>
                            {(errorMessage) => (
                              <p className="text-red-400 mt-1">
                                {errorMessage}
                              </p>
                            )}
                          </ErrorMessage>
                    <button
                      type="button"
                      className=" hover:bg-red-500  text-white text-xs ml-2 mt-2 bg-red-500 font-normal	 hover:text-white py-1 px-1 border border-black-300 hover:border-transparent rounded"
                      onClick={() => arrayHelpers.remove(index)}
                      hidden={formType === "View" ? true : false}

                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className=" hover:bg-blue-500  text-white text-xs p-2 mt-2  bg-blue-500 font-normal	 hover:text-white border border-black-300 hover:border-transparent rounded"
                  onClick={() =>
                    arrayHelpers.push({
                      imageUrl: "",
                    })
                  }
                  hidden={formType === "View" ? true : false}

                >
                  Add
                </button>
              </>
            )}
          />
          </div>
         
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses='hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer'
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton

            extraClasses='py-3 border bg-red-500 hover:bg-red-600 text-white'
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddGallery;
