import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ORGTableActionMenu from "../../components/UI/organisms/ORGTableActionMenu";
import { AppDispatch, RootState } from "../../redux/store";
import showToast from "../../utils/toast";
import MOLDeleteConfirmationDialog from "../../components/UI/molecules/MOLDeleteConfirmationDialog";
import {
  CommonTableColumnsPropTypes,
  CommonTableHeaderPropTypes,
} from "../../models/commonTypes";

import {
  setSelectedId,
  setCurrentPage,
  setRowsPerPage,
  setIsTableLoading,
  setIsVisibleAddSideBar,
  setItems,
  setTotalItems,
  setIsVisibleActiveDeactiveConfirmationDialog,
  setIsVisibleDeleteConfirmationDialog,
  setIsVisibleViewSideBar,
  setIsVisibleEditSideBar,
} from "../../redux/slices/BannerSlices";
import {
  useChangeBannerStatusMutation,
  useDeleteBannerByIdMutation,
  useGetBannerWithPaginationQuery,
} from "../../services/BannerService";
import { BannerItemsTypes } from "../../models/banner.model";
import BannerList from "./Banner";
import MOLChangeStatusConfirmationDialog from "../../components/UI/molecules/MOLChangeStatusConfirmationDialog";
import AddBannerWrapper from "../../forms/banner/AddBannerWrapper";

const BannerWrapper = () => {
  // **** State
  const [currentStatus, setCurrentStatus] = useState(false);

  // **** Hooks
  const Banner: any = useSelector((state: RootState) => state.homeslider);
  const {
    searchValue,
    currentPage,
    rowsPerPage,
    isTableLoading,
    isVisibleAddSideBar,
    isVisibleEditSideBar,
    isVisibleViewSideBar,
    items,
    totalItems,
    selectedId,
    isVisibleDeleteConfirmationDialog,
    isVisibleActiveDeactiveConfirmationDialog,
  } = Banner;

  const dispatch = useDispatch<AppDispatch>();

  // **** Services
  const { data, isFetching, isLoading } = useGetBannerWithPaginationQuery({
    page: currentPage + 1,
    limit: rowsPerPage,
    searchValue: searchValue.length >= 1 ? searchValue : "",
    params: [    
      "order",
    "imageUrl"
  ],
    orderBy: "",
    isPaginationRequired: true,
  });
  const [deleteFile] = useDeleteBannerByIdMutation();
  const [changeStatus, changeStatusInfo] = useChangeBannerStatusMutation();

  // **** Delete Handler
  const deleteHandler = () => {
    deleteFile(selectedId)
      .then((res: any) => {
        if (res.data) {
          dispatch(setSelectedId(""));
          showToast(res.data?.message, res.data?.status ? "success" : "error");
        }
        if (res?.error) {
          showToast(res.error?.data?.message, "error");
        }
      })
      .catch((err: any) => {
        // console.log(err)
      });
    dispatch(setIsVisibleDeleteConfirmationDialog(false));
  };

  // **** Change Status Handler
  const changeStatusHandler = () => {
    changeStatus(selectedId);
    dispatch(setIsVisibleActiveDeactiveConfirmationDialog(false));
  };

  // **** Use Effect for set Playground Data
  useEffect(() => {
    if (!isFetching && !isLoading) {
      dispatch(setItems(data?.data || []));
      dispatch(setTotalItems(data?.totalItem || 0));
      dispatch(setIsTableLoading(false));
    } else {
      dispatch(setIsTableLoading(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, isLoading, data]);

  // **** Table Columns Header
  const headers: CommonTableColumnsPropTypes[] = [
    {
        field: 'imageUrl',
        headerName: 'Image ',
        renderCell:(row : BannerItemsTypes) =>{
            return (  <img width={"200px"} style={{height:"140px"}}   alt="Error" src= {row.imageUrl}/> )
        },
        flex: 1,
    },
    {
        field: 'order',
        headerName: 'Banner Order Number',
        flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (row: BannerItemsTypes) =>
        row.is_active ? "Active" : "Deactive",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 40,
      extraClasses: " flex justify-end",
      renderCell: (row: BannerItemsTypes) => (
        <div className="flex justify-end pr-3 ">
          <ORGTableActionMenu
            actions={{
              activeDeactive: {
                onClick: () => {
                  dispatch(setIsVisibleActiveDeactiveConfirmationDialog(true));
                  setCurrentStatus(row.is_active ? true : false);
                  dispatch(setSelectedId(row._id));
                },
                text: row.is_active ? "Deactive " : "Active ",
              },
              delete: {
                onClick: () => {
                  dispatch(setSelectedId(row._id));
                  dispatch(setIsVisibleDeleteConfirmationDialog(true));
                },
                text: "Delete ",
              },
              view: {
                onClick: () => {
                  dispatch(setSelectedId(row._id));
                  dispatch(setIsVisibleViewSideBar(true));
                },
                text: "View ",
              },
              edit: {
                onClick: () => {
                  dispatch(setSelectedId(row._id));
                  dispatch(setIsVisibleEditSideBar(true));
                },
                text: "Edit ",
              },
            }}
          />
        </div>
      ),
    },
  ];

  // **** Table Header Props
  const tableHeaderProps: CommonTableHeaderPropTypes = {
    addBtn: {
      onClickHandler: () => {
        dispatch(setIsVisibleAddSideBar(true));
      },
      btnName: "Add",
    },
  };

  // **** Table Props
  const tableProps = {
    headers,
    rows: items,
    page: currentPage,
    onPageChange: (newPage: number) => {
      dispatch(setCurrentPage(newPage));
      changeStatusInfo.originalArgs = "";
    },
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (newRowsPerPage: number) => {
      dispatch(setRowsPerPage(newRowsPerPage));
      dispatch(setCurrentPage(0));
      changeStatusInfo.originalArgs = "";
    },
    rowCount: totalItems,
    tableHeaderProps,
    isTableLoading,
    renderRow: (row: BannerItemsTypes) => ({
      extraClasses: `${!row.is_active && "bg-slate-300"} `,
    }),
  };

  return (
    <>
      <BannerList {...tableProps} />
      {isVisibleDeleteConfirmationDialog && (
        <MOLDeleteConfirmationDialog
          onAccept={deleteHandler}
          onDismiss={() => {
            dispatch(setIsVisibleDeleteConfirmationDialog(false));
            dispatch(setSelectedId(""));
          }}
        />
      )}
      {isVisibleActiveDeactiveConfirmationDialog && (
        <MOLChangeStatusConfirmationDialog
          onAccept={changeStatusHandler}
          onDismiss={() => {
            dispatch(setIsVisibleActiveDeactiveConfirmationDialog(false));
            dispatch(setSelectedId(""));
          }}
          message={`Do you really want to ${
            currentStatus ? "Deactivate" : "Active"
          }`}
          title={
            currentStatus ? "Deactivate confirmation" : "Active confirmation"
          }
        />
      )}
      {isVisibleAddSideBar && (
        <AddBannerWrapper
          open={isVisibleAddSideBar}
          handleClose={() => dispatch(setIsVisibleAddSideBar(false))}
          formType="Add"
        />
      )}
      {isVisibleEditSideBar && (
        <AddBannerWrapper
          open={isVisibleEditSideBar}
          handleClose={() => dispatch(setIsVisibleEditSideBar(false))}
          formType="Edit"
        />
      )}
      {isVisibleViewSideBar && (
        <AddBannerWrapper
          open={isVisibleViewSideBar}
          handleClose={() => dispatch(setIsVisibleViewSideBar(false))}
          formType="View"
        />
      )}
    </>
  );
};
export default BannerWrapper;
