import { Oval } from 'react-loader-spinner'

const ATMButtonLoader=()=>{
return (
    <>
    <Oval
  height={15}
  width={15}
  color="#ffff"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  ariaLabel='oval-loading'
  secondaryColor="#ffff"
  strokeWidth={5}
  strokeWidthSecondary={2}

/>
    </>
)
}
export default ATMButtonLoader
