import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiReviewAdd } from "../../models/review.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddReview from "./AddReview";
// import { ReviewValidationSchema } from "../validationSchema/ReviewValidationSchema";
import { useAddReviewMutation, useGetReviewByIdQuery, useUpdateReviewMutation } from "../../services/ReviewService";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";


const AddReviewFormik = withFormik<OtherProps & formikApiProps,ApiReviewAdd>({
  enableReinitialize:true,
    mapPropsToValues: (props) => {
      let values: ApiReviewAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues
          break;
        case "Edit":
            values = props.intialValues
          break;
        case "View":
            values = props.intialValues
          break;
        default:
            values = props.intialValues
      }

      return values;
    },

    // validationSchema : ReviewValidationSchema,

    handleSubmit(values ,{props,setValues,setSubmitting}) {
      switch (props.formType) {
          case 'Edit':
            let reqBody = {
              rating: values.rating,
              product_name: values.product_name,
              fullName : values.fullName,
              text : values.text,
              email : values.email,
            }
            setValues({ ...values })
    
            props.editApi({ body: reqBody, id: props.selectedId }).then((response: any) => {
              if (response?.data?.status) {
                showToast(response?.data?.message, "success")
                setSubmitting(true)
                props.handleClose()
              } else {
                if (response?.error) {
                  props.setInitialValues(values)
                  showToast(response?.error?.data?.message, "error")
                } else {
                  props.setInitialValues(values)
                  showToast(response?.data?.message, "error")
                }
              }
            }
            )
            break
          default: values = props.intialValues
    
        }
      },
  })(AddReview);


const AddReviewWrapper = React.forwardRef((props: OtherProps, ref) => {
  const {open=false, handleClose, formType } = props;
  const [addReviewApi,addReviewInfoApi ] = useAddReviewMutation()
  const [updateReviewApi, updateReviewApiInfo]= useUpdateReviewMutation()
  const review : any = useSelector((state:RootState) => state.review)
  const {selectedId} = review
  const {data: reviewData, isLoading: reviewDataIsLoading, isFetching: reviewDataIsFetching} = useGetReviewByIdQuery(selectedId, {skip:!selectedId})
  const initialData : ApiReviewAdd = {
    rating: "",
    product_name: "",
    fullName : "",
    text : "",   
    email : "",
  };
  const [intialValues,setInitialValues ] = useState(initialData)
  useEffect(() =>{
    if(formType === "View" || formType === "Edit"){
      if(!reviewDataIsFetching && !reviewDataIsLoading){

        const dataReview = reviewData?.data

        let reviewDataList: ApiReviewAdd = {
          product_name: dataReview?.product_name,
          rating: dataReview?.rating,
          fullName : dataReview?.fullName,
          text : dataReview?.text,
          email:dataReview?.email,

          
        }
        setInitialValues(reviewDataList)
      }
    }
  }, [reviewDataIsLoading, reviewDataIsFetching, reviewData, formType])

  return (
    <div>
      <Modal
          className="h-screen "
          open={open}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
        >
          <Fade in={open}>
      <Box className="fade-box">
        <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
          {formType} Review
        </div>
        <AddReviewFormik
          formType={formType}
          handleClose={() => handleClose()}
          isLoading={addReviewInfoApi.status === "pending" || updateReviewApiInfo.isLoading}
          intialValues={intialValues}
          setInitialValues={setInitialValues}
          addApi={addReviewApi}
          editApi={updateReviewApi}
          selectedId={selectedId}
        
          
        />
      </Box>
      </Fade>
        </Modal> 
    </div>
  );
});

export default AddReviewWrapper;
