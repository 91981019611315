import React from "react";
import { Form, FormikProps } from "formik";
import { ATMButton, ATMInput } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiGalleryCategoryAdd } from "../../models/galleryCategory.model";

const AddGalleryCategory = (props: OtherProps & FormikProps<ApiGalleryCategoryAdd>) => {
  const { touched, errors,  handleChange, handleClose ,formType,values, isLoading} =
    props;

  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >          
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Gallery Category "
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="category_name"
              value={values.category_name}
              readOnly={formType === "View" ? true : false }
             label="Gallery Category  "
              required={true}
              isValid={touched.category_name ? !touched.category_name : true}
              errorMessage={errors.category_name}
            />
          </div>        
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Gallery Category Order Number "
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="order"
              value={values.order}
              readOnly={formType === "View" ? true : false }
             label="Gallery Category Order Number  "
              required={true}
              isValid={touched.order ? !touched.order : true}
              errorMessage={errors.order}
            />
          </div>       
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses='hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer'
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton

            extraClasses='py-3 border bg-red-400 hover:bg-red-500 text-white'
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddGalleryCategory;
