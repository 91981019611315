import React from "react";
import { ErrorMessage, FieldArray, Form, FormikProps } from "formik";
import { ATMButton, ATMInput, ATMSelect } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { Box, Grid } from "@mui/material";
// import { OptionType } from "../../components/UI/atoms/ATMMultiSelect/ATMMultiSelectPropType";
// import { Autocomplete, Checkbox, TextField } from "@mui/material";
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';

const AddComboOffers = (props: OtherProps & FormikProps<any>) => {
  const {
    touched,
    errors,
    handleChange,
    handleClose,
    formType,
    productOption = null,
    isLoading,
    values,
  } = props;

  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Combo Offer Name"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="combo_offer_name"
              label="Combo Offer Name "
              value={values.combo_offer_name}
              required={false}
              readOnly={formType === "View" ? true : false}
              isValid={touched.combo_offer_name ? !touched.combo_offer_name : true}
              errorMessage={errors.combo_offer_name}
            />
          </div>

          <div>
            <p className="font-medium	 "> Products : </p>
            <FieldArray
              name="products"
              render={(arrayHelpers) => (
                <>
                  {values.products.map((friend: any, index: any) => (
                    <Box
                      key={index}
                      sx={{ borderWidth: "2px", width: "100%", p: 1, mt: 1 }}
                    >
                      {/* <Field
                    className="border border-xl w-full py-1 pl-1 my-2 hover:border-black"
                    sx={{backgroundColor : "black"}}
                    name={`social_network_and_links[${index}].name`}
                  />
                */}
                      <div className="mb-4">
                        <ATMSelect
                          options={productOption}
                          size="medium"
                          placeholder="Product Name"
                          extraClasses="w-full"
                          onChange={handleChange}
                          name={`products[${index}].productId`}
                          label="Product Name"
                          value={values.products[index].productId}
                          defaultValue={values.products[index].productId}
                          required={true}
                          disabled={formType === "View" ? true : false}
                          isValid={false} // isValid={touched.parent_category_id ? !touched.parent_category_id : true}
                        />
                      </div>
                      <ErrorMessage name={`products[${index}].productId`}>
                        {(errorMessage) => (
                          <p className="text-red-400 mt-1">{errorMessage}</p>
                        )}
                      </ErrorMessage>
                      <button
                        type="button"
                        className=" hover:bg-red-500  text-white text-xs ml-2 mt-2 bg-red-500 font-normal	 hover:text-white py-1 px-1 border border-black-300 hover:border-transparent rounded"
                        onClick={() => arrayHelpers.remove(index)}
                        hidden={formType === "View" ? true : false}

                      >
                        Remove
                      </button>
                    </Box>
                  ))}
                  <Grid item xs={12}>
                    <button
                      type="button"
                      className=" hover:bg-blue-500  text-white text-xs p-2 mt-2  bg-blue-500 font-normal	 hover:text-white border border-black-300 hover:border-transparent rounded"
                      onClick={() =>
                        arrayHelpers.push({
                          productId: "",
                          product_order: "",
                        })
                      }                          
                      hidden={formType === "View" ? true : false}

                    >
                      Add
                    </button>
                  </Grid>
                </>
              )}
            />
          </div>
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses="hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer"
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton
            extraClasses="py-3 border bg-red-500 hover:bg-red-600 text-white"
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddComboOffers;
