  
import { object } from "yup"
import { isEmail, isNo, isString,} from "../../validation/validation"

  export const JobApplicationValidationSchema = object({
    name:isString("Name is"),
    email: isEmail("Email is"),
    temporaryAddress : isString("Temporary Address is" ),
    age : isNo()
  })

