import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiCategoryAdd } from "../../models/category.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddCategory from "./AddCategory";
import { CategoryValidationSchema } from "../validationSchema/CategoryValidationSchema";
import { useAddCategoryMutation, useGetCategoryByIdQuery, useUpdateCategoryMutation } from "../../services/CategoryService";
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux/store';
import { setCurrentPage } from "../../redux/slices/CategorySlices";
import { useGetCategoryWithPaginationQuery } from "../../services/CategoryService";


const AddCateogryFormik = withFormik<OtherProps & formikApiProps,ApiCategoryAdd>({
  enableReinitialize: true,
    mapPropsToValues: (props) => {
      let values: ApiCategoryAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues
          break;
        case "Edit":
            values = props.intialValues
          break;
        case "View":
            values = props.intialValues
          break;
        default:
            values = props.intialValues
      }

      return values;
    },

    validationSchema : CategoryValidationSchema,

    handleSubmit(values ,{props,setValues,setSubmitting}) {
        switch (props.formType) {

         case 'Add':
          let reqData = {
            title: values.title,
            imageUrl: values.imageUrl,
            order: values.order,
            tags: values.tags,
            parent_category_id : values.parent_category_id,
          }
          props.addApi(reqData).then((response: any) => {
            if (response?.data?.status) {
              // props.setInitialValues(props.intialValues)
              showToast(response?.data?.message, "success")
              store.dispatch(setCurrentPage(0))

              // setSubmitting(true)
              props.handleClose(false)
            } else {
              if (response?.error) {
                props.setInitialValues(values)
                showToast(response?.error?.data?.message, "error")
              } else {
                props.setInitialValues(values)
                showToast(response?.data?.message, "error")
              }
            }
  
          }
  
          )
            break
            case 'Edit':
              let reqBody = {
                title: values.title,
                imageUrl: values.imageUrl,
                order: values.order,
                tags: values.tags,
                parent_category_id : values.parent_category_id,
              }
            
              setValues({ ...values })
      
              props.editApi({ body: reqBody, id: props.selectedId }).then((response: any) => {
                if (response?.data?.status) {
                  showToast(response?.data?.message, "success")
                  setSubmitting(true)
                  props.handleClose()
                } else {
                  if (response?.error) {
                    props.setInitialValues(values)
                    showToast(response?.error?.data?.message, "error")
                  } else {
                    props.setInitialValues(values)
                    showToast(response?.data?.message, "error")
                  }
                }
              }
              )
              break
            default: values = props.intialValues
      
          }
        },
  })(AddCategory);


const AddCategoryWraper = React.forwardRef((props: OtherProps, ref) => {
  const {open=false, handleClose, formType } = props;
  const [addCategoryApi,addCategoryInfoApi ] = useAddCategoryMutation()
  const [updateCategoryApi,updateCategoryInfoApi] = useUpdateCategoryMutation()
  const category: any = useSelector((state: RootState) => state.category)
  const { selectedId } = category
  const { data: categoryData, isFetching: categoryDataIsFetching, isLoading: categoryDataIsLoading } = useGetCategoryByIdQuery(selectedId, { skip: !selectedId })
  const {
    data: CategoryData,
    isLoading: CategoryDataIsLoading,
    isFetching: CategoryDataIsFetching,
  } = useGetCategoryWithPaginationQuery({ 
    page: 0,
    limit: 0,
    searchValue: "",
    params: [
      
      "title",
    ],
    orderBy: "",
        isPaginationRequired: false})

  const initialData  : ApiCategoryAdd ={
    imageUrl: "",
    title: "",
    parent_category_id:"",
    order:"",
    tags:[],
    
  };
  const [intialValues,setInitialValues ] = useState(initialData)

  const [categoryOption, setCategoryOption] = useState([
    {
      selectLabel: "",
      value: "",
    },
  ]);


  useEffect(() => {
    if (!CategoryDataIsLoading && !CategoryDataIsFetching) {
      let categoryOptions = CategoryData?.data?.filter((data: { is_active: boolean; }) => data.is_active === true).map((ele :any)=>{
        return {
          selectLabel: ele.title,
            value: ele._id
        }
      })  
      setCategoryOption(categoryOptions);
    }

  }, [CategoryData, CategoryDataIsLoading, CategoryDataIsFetching]);


  useEffect(() => {
    if (formType === "View" || formType === "Edit") {
      if (!categoryDataIsFetching && !categoryDataIsLoading) {
       
        const enqData = categoryData?.data

        let categoryDataList: ApiCategoryAdd = {
          imageUrl: enqData?.imageUrl,
          title: enqData?.title,
          order: enqData?.order,
          tags: enqData?.tags,
          parent_category_id : enqData?.parent_category_id,
          
        }
        setInitialValues(categoryDataList)
      }
    }
  }, [categoryDataIsLoading, categoryDataIsFetching, categoryData, formType])

  

  return (
    <div>
      <Modal
          className="h-screen "
          open={open}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
        >
          <Fade in={open}>
      <Box className="fade-box">
        <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
          {formType} Category
        </div>
        <AddCateogryFormik
          formType={formType}
          handleClose={() => handleClose()}
          isLoading={addCategoryInfoApi?.status === "pending" || updateCategoryInfoApi.isLoading}
          intialValues={intialValues}
          setInitialValues={setInitialValues}
          selectedId= {selectedId}
          addApi={addCategoryApi}
          editApi={updateCategoryApi}
          CategoryListSingle={categoryOption}

        />
      </Box>
      </Fade>
        </Modal> 
    </div>
  );
});

export default AddCategoryWraper;
