import React from "react";
import { Form, FormikProps } from "formik";
import { ATMButton, ATMInput, ATMSelect } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiJobApplicationAdd } from "../../models/jobApplication.model ";

const AddJobApplication = (
  props: OtherProps & FormikProps<ApiJobApplicationAdd>
) => {
  const {
    touched,
    errors,
    handleChange,
    setFieldValue,
    handleClose,
    formType,
    jobListSingle =null,
    isLoading,
    values,
  } = props;
  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Name"
              variant="outlined"
              extraClasses="w-full"
              value={values.name}
              onChange={handleChange}
              readOnly={formType === "View" ? true : false}
              name="name"
              label="Name "
              required={true}
              isValid={touched.name ? !touched.name : true}
              errorMessage={errors.name}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Email"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              value={values.email}
              name="email"
              label="Email"
              required={true}
              readOnly={formType === "View" ? true : false}
              isValid={touched.email ? !touched.email : true}
              errorMessage={errors.email}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Mobile Number"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              value={values.mobile}
              name="mobile"
              label="Mobile Number"
              required={false}
              readOnly={formType === "View" ? true : false}
              isValid={touched.mobile ? !touched.mobile : true}
              errorMessage={errors.mobile}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Age"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              value={values.age}
              name="age"
              label="Age"
              required={false}
              readOnly={formType === "View" ? true : false}
              isValid={touched.age ? !touched.age : true}
              errorMessage={errors.age}
            />
          </div>
          <div className="mb-4">
            <ATMSelect
              options={jobListSingle}
              size="medium"
              placeholder="Job Title"
              extraClasses="w-full"
              onChange={handleChange}
              name="job_Title"
              label="Job Title"
              value={values.job_Title}
              defaultValue={values.job_Title}
              required={false}
              disabled={formType === "View" ? true : false} 
              isValid={touched.job_Title ? !touched.job_Title : true}
              errorMessage={errors.job_Title}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Resume"
              variant="outlined"
              extraClasses="w-full"
              inputType="file"
              onChange={(e) => setFieldValue("resume", e.target.files[0])}
              name="resume"
              label=" Resume "
              required={true}
              isValid={touched.resume && errors.resume ? false : true}
              errorMessage={errors.resume}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Permanent Address "
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="permanentAddress"
              value={values.permanentAddress}
              label="Permanent Address"
              required={false}
              readOnly={formType === "View" ? true : false}
              isValid={
                touched.permanentAddress ? !touched.permanentAddress : true
              }
              errorMessage={errors.permanentAddress}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Temporary Address"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="temporaryAddress"
              label="Temporary Address"
              readOnly={formType === "View" ? true : false}
              required={true}
              value={values.temporaryAddress}
              isValid={
                touched.temporaryAddress ? !touched.temporaryAddress : true
              }
              errorMessage={errors.temporaryAddress}
            />
          </div>
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses="hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer"
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton
            extraClasses="py-3 border bg-red-500 hover:bg-red-600 text-white"
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddJobApplication;
