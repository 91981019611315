  
import {  object } from "yup"
import { isString} from "../../validation/validation"
import * as Yup from "yup"

  export const RecipeValidationSchema = object({
    title:isString("Title is"),
    recipe_category:isString("Recipe Category is"),
    imageUrl : isString("Image is "),
    ingredients : isString("Ingredients is "),
    method : isString("Method is"),
    images : Yup.array().of(Yup.string().min(1, "Please select atleast one image").required("At least one image is required")),


})
