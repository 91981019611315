import { TextField } from "@mui/material"
import { InputAdornment } from "@mui/material"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ATMPasswordPropType } from "./ATMPasswordPropType";
import IconButton from '@mui/material/IconButton';
import { useState } from "react";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ATMPassword = ({
    size="small",
    placeholder = "",
    variant="standard",
    extraClasses = "",
    onChange = () => {},
    name,
    value,
    required = false,
    label,
    isValid,
    errorMessage="",
    disabled=false,
}:ATMPasswordPropType) => {
    const [isShowPassowrd,setIsShowPassword] = useState(false)
    return (
        <>
         <div className="mb-2">
                {required ?
                <p className='font-medium'>{label}<span className='text-danger ps-3'>*</span></p>
                    :
                    <p className='font-medium'>{label}</p>
                }
            </div>
            <TextField
                size={size}
                placeholder={placeholder}
                variant={variant}
                className={extraClasses}
                onChange={(e)=>onChange(e)}
                name={name}
                value={value}
                type={isShowPassowrd ? "text" : "password"}
                disabled={disabled?true:false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                             <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setIsShowPassword(!isShowPassowrd)}
                  edge="end"
                >
                    {isShowPassowrd ? 
                     <VisibilityOutlinedIcon />
                     :
                    <VisibilityOffIcon/>
                    }
                    
                </IconButton>
                           
                        </InputAdornment>
                    ),
                }} />
                 <p className='text-red-400 mt-1'>{isValid ? <span></span>: errorMessage}</p>
        </>
    )
}

export default ATMPassword