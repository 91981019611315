  
import {  object } from "yup"
import { isNumber, isString} from "../../validation/validation"
import * as Yup from 'yup';

  export const ProductGridValidationSchema = object({
    grid_name:isString("Grid Name is"),
    order:isNumber("Grid Order Number is"),
    products: Yup.array().of(Yup.object().shape({
      productId : Yup.string().required(" Product is required") ,
      product_order : Yup.number().required("Product Order Number is required").typeError("Only numbers are allowed") ,
      
    })),
  })

