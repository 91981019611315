import { Dialog, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FileManagerItemsTypes } from '../../../models/fileManager.model';
import { AppDispatch, RootState } from '../../../redux/store';
import { useGetFileManagerWithPaginationQuery } from '../../../services/FileManagerService';
import loader from '../../../images/loader.gif';
import NoImageFound from '../../../../src/images/noImageFound.jpeg'
import MOLTableHeader from '../molecules/MOLTableHeader';
import { setCurrentPage, setSearchValue } from '../../../redux/slices/FileManagerSlice';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface ATMFilePickerPropTypes {
    children: React.ReactNode | string;
    selected: string ;
    onSelect: (value: string, title: string) => void
    onUnSelect: () => void
    heading?: string;
    required?:boolean;
    label?:string;
    disabled?:boolean;
    hidden?:boolean;
    isValid?:boolean;
    errorMessage?:string | string[] | number [];
}

const ATMFilePicker = (
    {
        children,
        selected,
        onSelect,
        onUnSelect,
        heading = "Select Image",
        required=false,
        disabled=false,
        hidden = false,
        label='',
        isValid=false,
        errorMessage="",
    }: ATMFilePickerPropTypes
) => {

    // **** Hooks
    const fileManager: any = useSelector((state: RootState) => state.fileManager)
    const {
        searchValue,
        currentPage,
    } = fileManager

    const dispatch = useDispatch<AppDispatch>()

    const [files, setFiles] = useState<any[]>([])
    const [imageName, setImageName] = useState("")
    const [isOpenFilePicker, setIsOpenFilePicker] = useState(false)
    const { data, isFetching, isLoading } = useGetFileManagerWithPaginationQuery({
        page: currentPage + 1,
        limit: 0,
        searchValue: searchValue.length >= 2 ? searchValue : "",
        params: [
            
            "fileName",
        ],
        orderBy: ""
    })

    useEffect(()=> {
        setCurrentPage(0)
    },[])

    useEffect(() => {
        if (!isFetching && !isLoading) {
            searchValue.length ?
                setFiles(data?.data || [])
                :
                setFiles(files => files.concat(data?.data || []))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, isLoading, data])


    return (
        <>
         <div className="mb-2">
                {required ?
                    <p className='font-medium'>{label}<span className='text-danger ps-3'>*</span></p>
                    :
                    <p className='font-medium'>{label}</p>
                }
            </div>
            {
                selected.length ?
                    <div className='w-1/2 border border-slate-300 rounded'>
                        <div className='flex justify-end px-2 py-1 border-b border-slate-300'>

                            <button type='button' disabled={disabled} hidden = {hidden} onClick={() => onUnSelect()} >
                                X
                            </button>
                        </div >
                        <div style={{ height: "100px" }} className="px-2 mt-2" >

                            <img style={{ height: "100%", width: "100%" }} src={selected} alt="" />
                        </div >
                        <div className="px-2 py-1 mt-2" > {imageName}  </div>
                    </div>
                    :
                   <>
                    <button type='button' onClick={() => setIsOpenFilePicker(true)} className={`border border-dashed p-3 rounded border-slate-500 text-slate-400 w-full`} >
                        {children}
                    </button>
                  
                   </>
            }
              <p className='text-red-400 mt-1'>{isValid ? "" : errorMessage}</p>

            {
                isOpenFilePicker &&
                <Dialog
                    open={isOpenFilePicker}
                    onClose={() => setIsOpenFilePicker(false)}
                    maxWidth="md"
                    fullWidth

                >
                    <DialogContent   >
                        <div className='text-2xl mb-2' > {heading} </div>

                        <MOLTableHeader
                            search={{ searchValue: searchValue, setSearchValue: (value) => dispatch(setSearchValue(value)) }}
                        />
                        <div className='mt-3' >

                            {
                                files.length ?
                                    <InfiniteScroll
                                        dataLength={files.length}
                                        next={() => dispatch(setCurrentPage(currentPage + 1))}
                                        hasMore={files.length !== data?.totalItem}
                                        loader={<div className='flex justify-center my-2' > <img src={loader} alt="" /> </div>}
                                        height='60vh'

                                    >
                                        <div className='grid grid-cols-5 auto-rows-auto gap-4 mt-4 '>
                                            {

                                                files.map((file: FileManagerItemsTypes, index) => (
                                                    <div key={index} onClick={() => { onSelect(file.filePath, file.fileName); setImageName(file.fileName); setIsOpenFilePicker(false) }} className=" rounded py-2 hover:cursor-pointer hover:scale-110 h-11/12 "  >
                                                        {
                                                            file.filePath ?
                                                                <div className='px-2 '>
                                                                    <img className='h-32 w-full rounded-lg ' src={file.filePath} alt='' />
                                                                    <p className="text-center " > {file.fileName}  </p>

                                                                </div>
                                                                :
                                                                <div className='px-2'>
                                                                    <img className='h-32 w-full rounded-lg' src={NoImageFound} alt="" />
                                                                </div>

                                                        }

                                                    </div>

                                                ))
                                            }
                                        </div>

                                    </InfiniteScroll>
                                    :
                                    <div className='text-center' > No Image Found </div>
                            }

                        </div>


                    </DialogContent>
                </Dialog>
            }
        </>
    )
}

export default ATMFilePicker
