import { Skeleton } from '@mui/material';
import React from 'react'

interface ATMSkeletonPropType {
    count?: number;
    extraClasses?: string;
    height?: string | number
}

const ATMSkeleton = (
    {
        count = 1,
        extraClasses = "",
        height = "80px"
    }: ATMSkeletonPropType
) => {

    return (

        <>
            {

                Array(count).fill(0).map((_, idx) => {
                    return <Skeleton key={_ + idx} animation='wave' className={`${extraClasses}`} height={height} width="100%" />
                })
            }

        </>
    )



}

export default ATMSkeleton
