import ATMButtonLoader from '../ATMButtonLoader/ATMButtonLoader'
import { ATMButtonPropType } from './ATMButttonProp'

const ATMButton = ({
    text="Cancel",
    extraClasses="",
    onClick=() => {},
    type="button",
    disabled= false, 
    loading= false,
    hidden=false,
  }:ATMButtonPropType) => {
    return (
        <button
        hidden={hidden}
            className={`${extraClasses} w-full `}
            type={type}
            onClick={(e)=>onClick(e)}
            disabled={disabled || loading}
            >
            {loading ? 
            <div className="flex justify-center align-center">
                {text}
                <ATMButtonLoader/>
            </div>
            : text}
        </button>
    )
}
export default ATMButton
