import React from "react";
import { Form, FormikProps } from "formik";
import { ATMButton, ATMInput } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiOrderAdd } from "../../models/order.model";

const AddOrder = (props: OtherProps & FormikProps<ApiOrderAdd>) => {
  const { touched, errors, handleChange,values,handleClose,formType,isLoading } =
    props;
    // const paymentStatusOptions=[
    //   {selectLabel:"Success", value: "SUCCESS"},
    //   {selectLabel:"Pending", value: "PENDING"},
    //   {selectLabel:"Failed", value: "FAILED"},
    // ]
  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Full Name"
              readOnly={formType === "View" ? true : false }
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="user_billing_details.fullname"
              label="Full Name "
              required={false}
              value ={values.user_billing_details.fullname}
              // isValid={touched.user_billing_details.fullname ? !touched.user_billing_details.fullname : true}
              // errorMessage={errors.user_billing_details.fullname}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Email"
              readOnly={formType === "View" ? true : false }
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="user_billing_details.email"
              label="Email "
              required={false}
              value ={values.user_billing_details.email}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Status"
              readOnly={formType === "View" ? true : false }
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="status"
              label="Status "
              required={false}
              value ={values.status}
              isValid={touched.status ? !touched.status : true}
              errorMessage={errors.status}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder=" Total Price"
              readOnly={formType === "View" ? true : false }
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="order_total_amount"
              label="Total Price "
              required={false}
              value ={values.order_total_amount}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder=" Order ID"
              readOnly={formType === "View" ? true : false }
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="order_unique_id"
              label="Order ID "
              required={false}
              value ={values.order_unique_id}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder=" City"
              readOnly={formType === "View" ? true : false }
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="user_billing_address.city"
              label="City "
              required={false}
              value ={values.user_billing_address.city}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder=" Phone"
              readOnly={formType === "View" ? true : false }
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="user_billing_details.Phone"
              label="Phone "
              required={false}
              value ={values.user_billing_details.Phone}
            />
          </div>
        </div>
        
        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses='hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer'
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton

            extraClasses='py-3 border bg-red-400 hover:bg-red-500 text-white'
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddOrder;
