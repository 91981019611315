import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiAdminUserAdd } from "../../models/adminUser.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddAdminUser from "./AddAdminUser";
import { useAddAdminUserMutation, useGetAdminUserByIdQuery, useUpdateAdminUserMutation } from "../../services/AdminUserService";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { setCurrentPage } from "../../redux/slices/AdminUserSlices";
import { AdminUserValidationSchema } from "../validationSchema/AdminUserValidationSchema";


const AddAdminUserFormik = withFormik<OtherProps & formikApiProps,ApiAdminUserAdd>({
  enableReinitialize:true,
    mapPropsToValues: (props) => {
      let values: ApiAdminUserAdd;
      switch (props.formType) {
        case "Add":
          values = props.intialValues
          break;
        case "Edit":
            values = props.intialValues
          break;
        case "View":
            values = props.intialValues
          break;
        default:
            values = props.intialValues
      }

      return values;
    },

    validationSchema : AdminUserValidationSchema,

    handleSubmit(values ,{props,setValues,setSubmitting}) {
      switch (props.formType) {
        case 'Add':
        props.addApi(values).then((response: any) => {
          if (response?.data?.status) {
            // props.setInitialValues(props.intialValues)
            showToast(response?.data?.message, "success")
            store.dispatch(setCurrentPage(0))

            // setSubmitting(true)
            props.handleClose(false)
          } else {
            if (response?.error) {
              props.setInitialValues(values)
              showToast(response?.error?.data?.message, "error")
            } else {
              props.setInitialValues(values)
              showToast(response?.data?.message, "error")
            }
          }

        }

        )
          break
          case 'Edit':
            let reqBody = {
              fullName: values.fullName,
              email: values.email,
              address: values.address,
              mobile: values.mobile,
              userName: values.userName,
            }
    
            setValues({ ...values })
    
            props.editApi({ body: reqBody, id: props.selectedId }).then((response: any) => {
              if (response?.data?.status) {
                showToast(response?.data?.message, "success")
                setSubmitting(true)
                props.handleClose()
              } else {
                if (response?.error) {
                  props.setInitialValues(values)
                  showToast(response?.error?.data?.message, "error")
                } else {
                  props.setInitialValues(values)
                  showToast(response?.data?.message, "error")
                }
              }
            }
            )
            break
          default: values = props.intialValues
    
        }
      },
  })(AddAdminUser);


const AddAdminUserWrapper = React.forwardRef((props: OtherProps, ref) => {
  const {open=false, handleClose, formType } = props;
  const [addAdminUserApi,addAdminUserInfoApi ] = useAddAdminUserMutation()
  const [updateAdminUserApi, updateAdminUserApiInfo]= useUpdateAdminUserMutation()
  const adminUser : any = useSelector((state:RootState) => state.adminUser)
  const {selectedId} = adminUser
  const {data: adminUserData, isLoading: adminUserDataIsLoading, isFetching: adminUserDataIsFetching} = useGetAdminUserByIdQuery(selectedId, {skip:!selectedId})
  const initialData : ApiAdminUserAdd = {
            fullName: " ",
            email: " ",
            address: " ",
            mobile:" ",
            userName: " ",
  };
  const [intialValues,setInitialValues ] = useState(initialData)
  useEffect(() =>{
    if(formType === "View" || formType === "Edit"){
      if(!adminUserDataIsFetching && !adminUserDataIsLoading){

        const dataAdminUser = adminUserData?.data

        let adminUserDataList: ApiAdminUserAdd = {
          fullName: dataAdminUser?.fullName,
          email: dataAdminUser?.email,
          address: dataAdminUser?.address,
          mobile: dataAdminUser?.mobile,
          userName : dataAdminUser?.userName,
       
          
        }
        setInitialValues(adminUserDataList)
      }
    }
  }, [adminUserDataIsLoading, adminUserDataIsFetching, adminUserData, formType])

  return (
    <div>
      <Modal
          className="h-screen "
          open={open}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
        >
          <Fade in={open}>
      <Box className="fade-box">
        <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
          {formType} Admin User
        </div>
        <AddAdminUserFormik
          formType={formType}
          handleClose={() => handleClose()}
          isLoading={addAdminUserInfoApi.status === "pending" || updateAdminUserApiInfo.isLoading}
          intialValues={intialValues}
          setInitialValues={setInitialValues}
          addApi={addAdminUserApi}
          editApi={updateAdminUserApi}
          selectedId={selectedId}
        
          
        />
      </Box>
      </Fade>
        </Modal> 
    </div>
  );
});

export default AddAdminUserWrapper;
