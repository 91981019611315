import React from 'react'
import { CommonTableHeaderPropTypes } from '../../../models/commonTypes';
import MOLTable from '../molecules/MOLTable';
import MOLTableHeader from '../molecules/MOLTableHeader';

interface ColumnsPropTypes {
    field: string;
    headerName?: string;
    renderHeader?: () => React.ReactNode | string;
    flex: number;
    renderCell?: (row: any) => React.ReactNode | string;
    extraClasses?: string;

}

interface renderRowTypes {
    extraClasses?: string;
}

interface MOLTablePropTypes {
    headers: ColumnsPropTypes[]
    rows: any[];
    renderRow?: null | ((rowData: any) => renderRowTypes);
    hidePagination?: boolean;
    page: number;
    onPageChange: (newPage: number) => void
    rowsPerPage: number;
    onRowsPerPageChange: (newRowsPerPage: number) => void;
    rowCount: number;
    isShowTableHeader?: boolean;
    tableHeaderProps?: CommonTableHeaderPropTypes;
    isTableLoading?: boolean;
    onRowClick?: (row: any) => void;
    maxHeight?: string | number;
    isCheckbox?: boolean;
    selectedRows?: any[];
    setSelectedRows?: (newSelectedRows: any) => void;
    size?: "small" | "medium"


}

const TMPListView = (
    {
        headers,
        rows,
        renderRow,
        hidePagination = false,
        rowCount,
        page,
        onPageChange,
        rowsPerPage,
        onRowsPerPageChange,
        tableHeaderProps,
        isShowTableHeader = true,
        isTableLoading,
        onRowClick,
        maxHeight,
        isCheckbox = false,
        selectedRows = [],
        setSelectedRows,
        size,
    }: MOLTablePropTypes
) => {
    return (
        <>
            <div className='p-3' >

                {
                    (tableHeaderProps && isShowTableHeader)  &&
                    <MOLTableHeader
                        exportBtn={tableHeaderProps.exportBtn}
                        addBtn={tableHeaderProps.addBtn}
                        search={tableHeaderProps.search}
                        filterBtn={tableHeaderProps.filterBtn}
                    />
                }
            </div>

            <MOLTable
                headers={headers}
                rows={rows}
                renderRow={renderRow}
                hidePagination={hidePagination}
                rowCount={rowCount}
                page={page}
                onPageChange={onPageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={onRowsPerPageChange}
                tableHeaderProps={tableHeaderProps}
                onRowClick={onRowClick}
                isTableLoading={isTableLoading}
                maxHeight={maxHeight}
                isCheckbox={isCheckbox}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                size={size}

            />
        </>
    )
}

export default TMPListView
