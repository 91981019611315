  
import {  object } from "yup"
import { isString} from "../../validation/validation"
import * as Yup from 'yup';

  export const ComboOfferValidationSchema = object({
    combo_offer_name:isString("Combo Offer Name is"),
    products: Yup.array().of(Yup.object().shape({
      productId : Yup.string().required(" Product is required") ,      
    })),
  })

