import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react'

interface ColumnsPropTypes {
    field: string;
    headerName?: string;
    renderHeader?: () => React.ReactNode | string;
    flex?: number;
    renderCell?: (row: any) => React.ReactNode | string;
    extraClasses?: string;

}
interface renderRowTypes {
    extraClasses?: string;
}

interface ATMTableRowsPropTypes {
    columns: ColumnsPropTypes[];
    rows: any[];
    renderRow?: null | ((rowData: any) => renderRowTypes)
    onRowClick?: (row: any) => void
    isCheckbox?: boolean;
    selectedRows?: any[];
    setSelectedRows?: (newSelectedRows: any) => void;


}

const ATMTableRows = (
    {
        columns,
        rows,
        renderRow = null,
        onRowClick,
        isCheckbox = false,
        selectedRows = [],
        setSelectedRows = () => { }
    }: ATMTableRowsPropTypes
) => {

    const tableRows = rows?.map((row, outerIndex) => {
        let extraRowProps: renderRowTypes = {
            extraClasses: ""
        }

        if (renderRow != null) {
            extraRowProps = renderRow(row)
        }

        const tableCell = columns?.map((column, index) => (
            <TableCell key={index} >   {column.renderCell ? column.renderCell(row) : row[column.field]}  </TableCell>
        ))

        return <TableRow hover={!extraRowProps.extraClasses?.length} onClick={() => onRowClick ? onRowClick(row) : null} key={outerIndex} className={` ${onRowClick ? "hover:cursor-pointer" : ""} ${extraRowProps.extraClasses ? extraRowProps.extraClasses : ""}`}>
            {
                isCheckbox &&
                <TableCell >
                    <Checkbox
                        checked={selectedRows?.findIndex((ele) => ele._id === row._id) === -1 ? false : true}
                        onChange={() => { selectedRows?.findIndex((ele) => ele._id === row._id) === -1 ? setSelectedRows([...selectedRows, row]) : setSelectedRows(selectedRows.filter((ele) => ele._id !== row._id)) }}
                    />
                </TableCell>
            }
            {tableCell}
        </TableRow>

    })



    return (
        <>
            <TableBody >
                {tableRows}
            </TableBody>
        </>
    )
}

export default ATMTableRows
