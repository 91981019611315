import {  string, number,ref, date, mixed,  boolean } from 'yup'



export const  isString = (name:string) => {
    return  string().trim().required(`${name} required`)
}
            

//  export const  isStringNumber = (name:string) => {
//                 return  string().trim().required(`${name} required`).test('Is positive?','ERROR: The number must be greater than 0!', (value) => value > 0)
//   }


export const isNumber =(name:string)=>{
    return  number().required(`${name} required`).typeError("Only numbers are allowed")

}
export const isNo =()=>{
    return  number().typeError("Only numbers are allowed")

}
export const isEmail=(name:string)=>{
    return string().email("Please provide a valid Email").trim().required(`${name} is required`)
}


export const isPassword=(name:string)=>{
    return string().trim()
}


export const isBoolean=(name:string)=>{
    return boolean().required(`${name} is required`).typeError("Please select Yes or No")
}

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const  regIndiaPhone = RegExp(/^[0]?[6789]\d{9}$/);

export const isPhoneNumber=(name:string)=>{
    return string().trim().matches(regIndiaPhone, 'Invalid Contact Number').nullable()
    .required(`${name} required`)
}

const re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

export const isLink=(name:string)=>{
    return string().matches(re,`${name}  required`).required(`${name}  required`)

}



export const isDate =(name:string)=>{
    return date().nullable().typeError('The value must be a date (YYYY-MM-DD)').required(`${name} is required`)
}
export const isTime=(name:string)=>{
    return mixed().when("followup_required", {
        is: "true",
        then: date().typeError("Please Enter Valid Time").required(`${name} is required`),
        otherwise: mixed().notRequired(),
      })
}
export const isUrl=(name:string)=>{
    return mixed().required(`${name} required`)
}




export const isConfirmPassword=(name:string,matchPassword:string)=>{
    return string().trim().when(`${matchPassword}`, {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then:string().oneOf(
          [ref(`${matchPassword}`)],
          "Both passwords need to be  same"
        )
      })
    }





