import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiGalleryAdd } from "../../models/gallery.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddGallery from "./AddGallery";
import { GalleryValidationSchema } from "../validationSchema/GalleryValidationSchema";
import {
  useAddGalleryMutation,
  useGetGalleryByIdQuery,
  useUpdateGalleryMutation,
} from "../../services/GalleryService";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { setCurrentPage } from "../../redux/slices/GallerySlices";
import { useGetGalleryCategoryWithPaginationQuery } from "../../services/GalleryCategoryService";

const AddGalleryFormik = withFormik<OtherProps & formikApiProps, ApiGalleryAdd>({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    let values: ApiGalleryAdd;
    switch (props.formType) {
      case "Add":
        values = props.intialValues;
        break;
      case "Edit":
        values = props.intialValues;
        break;
      case "View":
        values = props.intialValues;
        break;
      default:
        values = props.intialValues;
    }

    return values;
  },

  validationSchema: GalleryValidationSchema,

  handleSubmit(values, { props, setValues, setSubmitting }) {
    switch (props.formType) {
      case "Add":
        props.addApi(values).then((response: any) => {
          if (response?.data?.status) {
            // props.setInitialValues(props.intialValues)
            showToast(response?.data?.message, "success");
            store.dispatch(setCurrentPage(0));

            // setSubmitting(true)
            props.handleClose(false);
          } else {
            if (response?.error) {
              props.setInitialValues(values);
              showToast(response?.error?.data?.message, "error");
            } else {
              props.setInitialValues(values);
              showToast(response?.data?.message, "error");
            }
          }
        });
        break;
      case "Edit":
        let reqBody = {
          imageUrl: values.imageUrl,
          category: values.category,
        };

        setValues({ ...values });

        props
          .editApi({ body: reqBody, id: props.selectedId })
          .then((response: any) => {
            if (response?.data?.status) {
              showToast(response?.data?.message, "success");
              setSubmitting(true);
              props.handleClose();
            } else {
              if (response?.error) {
                props.setInitialValues(values);
                showToast(response?.error?.data?.message, "error");
              } else {
                props.setInitialValues(values);
                showToast(response?.data?.message, "error");
              }
            }
          });
        break;
      default:
        values = props.intialValues;
    }
  },
})(AddGallery);

const AddGalleryWrapper = React.forwardRef((props: OtherProps, ref) => {
  const { open = false, handleClose, formType } = props;
  const [addGalleryApi, addGalleryInfoApi] = useAddGalleryMutation();
  const [updateGalleryApi, updateGalleryApiInfo] = useUpdateGalleryMutation();
  const gallery: any = useSelector((state: RootState) => state.gallery);
  const { selectedId } = gallery;
  const {
    data: galleryData,
    isLoading: galleryDataIsLoading,
    isFetching: galleryDataIsFetching,
  } = useGetGalleryByIdQuery(selectedId, { skip: !selectedId });
  const {
    data: galleryCategoryData,
    isLoading: galleryCategoryDataIsLoading,
    isFetching: galleryCategoryDataIsFetching,
  } = useGetGalleryCategoryWithPaginationQuery({ 
    page: 0,
    limit: 0,
    searchValue: "",
    params: [
      
      "category_name",
    ],
    orderBy: "",
        isPaginationRequired: false})


  const initialData: ApiGalleryAdd = {
    category: "",
    imageUrl: [""]
  };
  const [intialValues, setInitialValues] = useState(initialData);
  const [galleryCategoryOption, setGalleryCategoryOption] = useState([
    {
      selectLabel: "",
      value: "",
    },
  ]);


  useEffect(() => {
    if (!galleryCategoryDataIsLoading && !galleryCategoryDataIsFetching) {
      let galleryCategoryOptions = galleryCategoryData?.data?.filter((data: { is_active: boolean; }) => data.is_active === true).map((ele :any)=>{
        return {
          selectLabel: ele.category_name,
            value: ele._id
        }
      })  
      setGalleryCategoryOption(galleryCategoryOptions);
    }

  }, [galleryCategoryData, galleryCategoryDataIsLoading, galleryCategoryDataIsFetching]);

  useEffect(() => {
    if (formType === "View" || formType === "Edit") {
      if (!galleryDataIsFetching && !galleryDataIsLoading) {
        const dataGallery = galleryData?.data;

        let galleryDataList: ApiGalleryAdd = {
          category: dataGallery?.category,
          imageUrl: dataGallery?.imageUrl,
        };
        setInitialValues(galleryDataList);
      }
    }
  }, [galleryDataIsLoading, galleryDataIsFetching, galleryData, formType]);

  return (
    <div>
      <Modal
        className="h-screen "
        open={open}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="fade-box">
            <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
              {formType} Gallery
            </div>
            <AddGalleryFormik
              formType={formType}
              handleClose={() => handleClose()}
              isLoading={
                addGalleryInfoApi.status === "pending" || updateGalleryApiInfo.isLoading
              }
              intialValues={intialValues}
              setInitialValues={setInitialValues}
              addApi={addGalleryApi}
              editApi={updateGalleryApi}
              selectedId={selectedId}
              gallerycategoryListSingle={galleryCategoryOption}

            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
});

export default AddGalleryWrapper;
