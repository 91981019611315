import React from 'react'
import { TextField } from '@mui/material'
import { ATMTextAreaPropType } from './ATMTextAreaPropType'

const ATMTextArea = ({
    size = "small",
    placeholder = "",
    variant = "standard",
    extraClasses = "",
    onChange = () => { },
    name,
    value,
    required = false,
    label,
    isValid = false,
    errorMessage,
    disabled = false,
    readOnly = false,
    minRows = 2,
    maxRows = 2,
}: ATMTextAreaPropType) => {
    return (
        <>
            <div className="mb-2">
                {required ?
                    <p className='font-medium'>{label}<span className='text-danger ps-3'>*</span></p>
                    :
                    <p className='font-medium'>{label}</p>
                }
            </div>
            <TextField
                multiline
                minRows={minRows}
                maxRows={maxRows}
                value={value}
                variant={variant}
                onChange={(e) => onChange(e)}
                name={name}
                disabled={disabled}
                placeholder={placeholder}
                size={size}
                className={extraClasses}
            />
            <p className='text-red-400 mt-1'>{isValid ? "" : errorMessage}</p>
        </>
    )

}

export default ATMTextArea