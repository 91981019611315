import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ORGTableActionMenu from '../../components/UI/organisms/ORGTableActionMenu';
import { AppDispatch, RootState } from '../../redux/store';
import showToast from '../../utils/toast';
import MOLDeleteConfirmationDialog from '../../components/UI/molecules/MOLDeleteConfirmationDialog';
import { CommonTableColumnsPropTypes, CommonTableHeaderPropTypes } from '../../models/commonTypes';

import { setSelectedId, setCurrentPage, setRowsPerPage, setSearchValue, setIsTableLoading, setItems, setTotalItems, setIsVisibleActiveDeactiveConfirmationDialog, setIsVisibleDeleteConfirmationDialog, setIsVisibleViewSideBar } from '../../redux/slices/ReviewSlices'
import { useChangeReviewStatusMutation, useDeleteReviewByIdMutation, useGetReviewWithPaginationQuery } from '../../services/ReviewService';
import { ReviewItemsTypes } from '../../models/review.model';
import ReviewList from './Review';
import MOLChangeStatusConfirmationDialog from '../../components/UI/molecules/MOLChangeStatusConfirmationDialog';
import AddReviewWrapper from '../../forms/reviews/AddReviewWrapper';

const ReviewWrapper = () => {

    // **** State
    const [currentStatus, setCurrentStatus] = useState(false)

    // **** Hooks
    const Review: any = useSelector((state: RootState) => state.review)
    const {
        searchValue,
        currentPage,
        rowsPerPage,
        isTableLoading,
        isVisibleViewSideBar,
        items,
        totalItems,
        selectedId,
        isVisibleDeleteConfirmationDialog,
        isVisibleActiveDeactiveConfirmationDialog
    } = Review

    const dispatch = useDispatch<AppDispatch>()

    // **** Services
    const { data, isFetching, isLoading } = useGetReviewWithPaginationQuery({
        page: currentPage + 1,
        limit: rowsPerPage,
        searchValue: searchValue.length >= 2 ? searchValue : "",
        params: [
            "product_id",          
        ],
        orderBy: ""
    })
    const [deleteFile] = useDeleteReviewByIdMutation()
    const [changeStatus, changeStatusInfo] = useChangeReviewStatusMutation()

    // **** Delete Handler
    const deleteHandler = () => {
        deleteFile(selectedId).then((res: any) => {
            if (res.data) {

                dispatch(setSelectedId(""))
                showToast(res.data?.message, res.data?.status ? "success" : "error");

            }
            if (res?.error) {
                showToast(res.error?.data?.message, "error")

            }
        }).catch((err: any) => {
            // console.log(err)
        })
        dispatch(setIsVisibleDeleteConfirmationDialog(false))
    }


    // **** Change Status Handler
    const changeStatusHandler = () => {
        changeStatus(selectedId)
        dispatch(setIsVisibleActiveDeactiveConfirmationDialog(false))
    }

    // **** Use Effect for set Playground Data
    useEffect(() => {
        if (!isFetching && !isLoading) {
            dispatch(setItems(data?.data || []))
            dispatch(setTotalItems(data?.totalItem || 0))
            dispatch(setIsTableLoading(false))

        } else {
            dispatch(setIsTableLoading(true))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, isLoading, data])

    // **** Table Columns Header
    const headers: CommonTableColumnsPropTypes[] = [

        {
            field: 'product_name',
            headerName: 'Product  Name ',
            flex: 1,
            
        },
        {
            field: 'fullName',
            headerName: 'Full Name ',
            flex: 1,
        },
        {
            field: 'rating',
            headerName: 'Rating ',
            flex: 1,
        },
        {
            field: 'text',
            headerName: 'Text ',
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (row: ReviewItemsTypes) => (
                row.is_approved ? "Active" : "Deactive"
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 40,
            extraClasses: " flex justify-end",
            renderCell: (row: ReviewItemsTypes) => (
                <div className='flex justify-end pr-3 '>

                    <ORGTableActionMenu actions={{
                       activeDeactive: {
                        
                        onClick: () => { dispatch(setIsVisibleActiveDeactiveConfirmationDialog(true)); setCurrentStatus(row.is_approved ? true : false); dispatch(setSelectedId(row._id)) },
                        text: row.is_approved ? "Deactive Review " : "Active Review "
                    },
                        delete: {
                            onClick: () => { dispatch(setSelectedId(row._id)); dispatch(setIsVisibleDeleteConfirmationDialog(true)) },
                            text: "Delete Review "
                        },
                        view: {
                            onClick: () => { dispatch(setSelectedId(row._id)); dispatch(setIsVisibleViewSideBar(true)); },
                            text: "View Review "
                        },
                      
                    }}
                    />
                </div>
            )
        },
    ]

    // **** Table Header Props 
    const tableHeaderProps: CommonTableHeaderPropTypes = {
       
        search: {
            searchValue: searchValue,
            setSearchValue: (newValue: string) => { dispatch(setSearchValue(newValue)); changeStatusInfo.originalArgs = '' }
        }
    }

    // **** Table Props
    const tableProps = {
        headers,
        rows: items,
        page: currentPage,
        onPageChange: (newPage: number) => { dispatch(setCurrentPage(newPage)); changeStatusInfo.originalArgs = '' },
        rowsPerPage: rowsPerPage,
        onRowsPerPageChange: (newRowsPerPage: number) => { dispatch(setRowsPerPage(newRowsPerPage)); dispatch(setCurrentPage(0)); changeStatusInfo.originalArgs = '' },
        rowCount: totalItems,
        tableHeaderProps,
        isTableLoading,
        renderRow: (row: ReviewItemsTypes) => ({
            extraClasses: `${!row.is_approved && "bg-slate-300"} `,
            }),
    }

    return (
        <>
            <ReviewList
                {...tableProps}
            />
            {
                isVisibleDeleteConfirmationDialog &&
                <MOLDeleteConfirmationDialog
                    onAccept={deleteHandler}
                    onDismiss={() => { dispatch(setIsVisibleDeleteConfirmationDialog(false)); dispatch(setSelectedId("")) }}
                />
            }
            {
                isVisibleActiveDeactiveConfirmationDialog &&
                <MOLChangeStatusConfirmationDialog
                    onAccept={changeStatusHandler}
                    onDismiss={() => { dispatch(setIsVisibleActiveDeactiveConfirmationDialog(false)); dispatch(setSelectedId("")) }}
                    message={`Do you really want to ${currentStatus ? 'Deactivate' : 'Active'}`}
                    title={currentStatus ? 'Deactivate confirmation' : "Active confirmation"}
                />
            }
            {isVisibleViewSideBar && <AddReviewWrapper open={isVisibleViewSideBar} handleClose={() => dispatch(setIsVisibleViewSideBar(false))} formType="View" />}

        </>
    )
}
export default ReviewWrapper
