import React from "react";
import { Form, FormikProps } from "formik";
import { ATMPassword, ATMButton } from "../../components/UI";
import { UpdateAdminUserPassword } from "../../models/adminUser.model";
import { OtherProps } from "../../models/addUI.model";

const UpdatePassword = (props: OtherProps & FormikProps<UpdateAdminUserPassword>) => {
  const {
    touched,
    errors,
    setFieldValue,
    handleClose,
    isLoading,
    values,
  } = props;
  return (
    <>
      <Form>
        <div className="text-fields p-4 ">
          <div className="mb-4">
            <ATMPassword
              size="medium"
              placeholder="Enter New password"
              variant="outlined"
              extraClasses="w-full"
              onChange={(e) => setFieldValue("new_password", e.target.value)}
              name="new_password"
              label="New Password"
              required={true}
              isValid={touched.new_password ? !touched.new_password : true}
              errorMessage={errors.new_password}
              value={values.new_password}
            />
          </div>
          <div className="mb-4">
            <ATMPassword
              size="medium"
              placeholder="Retype new password"
              variant="outlined"
              extraClasses="w-full"
              onChange={(e) =>
                setFieldValue("confirm_password", e.target.value)
              }
              name="confirm_password"
              label="Confirm Password"
              required={true}
              isValid={
                touched.confirm_password ? !touched.confirm_password : true
              }
              errorMessage={errors.confirm_password}
              value={values.confirm_password}
            />
          </div>
        </div>
        <div className="mt-1 p-2 m-2  ">
          <ATMButton
            extraClasses=" bg-blue-500 py-3 m-2 text-white cursor-pointer "
            text="Update Password"
            type="submit"
            loading={isLoading}
          />
          <ATMButton
            extraClasses="cta-btn bg-red-500 m-2 text-white py-3"
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default UpdatePassword;
