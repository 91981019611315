import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonPaginationApiDataType } from '../models/commonTypes';
import { BASE_URL } from "../utils/constants";
const AUTH_TOKEN = localStorage.getItem("authToken")

const TOKEN = AUTH_TOKEN ? AUTH_TOKEN : ""

export const newsletterApi = createApi({
    reducerPath: 'newsletterApi',
    tagTypes: ['file', 'allFiles'],
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/newsletter-subscription` }),
    endpoints: (builder) => ({


        // **** GET WITH PAGINATION
        GetNewsletterWithPagination: builder.query({
            providesTags: ['file'],
            query: (body: CommonPaginationApiDataType) => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body
            }),
        }),

    }),
})

export const
    {
        useGetNewsletterWithPaginationQuery,
    } = newsletterApi