import React from 'react'
const OnboardingLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='container-xl mx-auto h-screen flex justify-center px-5 py-10 shadow-lg bg-gradient-to-r from-blue-400 to-purple-500 items-center' >
      <div className="w-full ">        
        {children}
      </div>
    </div>
  )
}

export default OnboardingLayout

