  
import { object } from "yup"
import {  isEmail,  isPhoneNumber, isString} from "../../validation/validation"

  export const AdminUserValidationSchema = object({
    fullName:isString("Full Name is"),
    email : isEmail("Email "),
    mobile:isPhoneNumber("Contact Number is"),
    userName: isString("Username must not be empty")

  })
//   .test("userName", "Enter valid userName", (value) => {
//     return isEmail(value) || isValidMobile(value) || string()
// }),