import React from "react";
import { ErrorMessage, Form, FormikProps } from "formik";
import { ATMButton, ATMInput } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiInstitutionalProductAdd } from "../../models/institutionalProduct.model";
import ATMFilePicker from "../../components/UI/atoms/ATMFilePicker";
import MOLMarkdownEditor from "../../components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";

const AddInstitutionalProduct = (
  props: OtherProps & FormikProps<ApiInstitutionalProductAdd>
) => {
  const {
    touched,
    errors,
    handleChange,
    setFieldValue,
    handleClose,
    formType,
    isLoading,
    values,
  } = props;

  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          <div className="mb-4">
            <ATMFilePicker
              onSelect={(value) => setFieldValue("imageUrl", value)}
              onUnSelect={() => setFieldValue("imageUrl", "")}
              selected={values.imageUrl}
              label="Image "
              required={true}
              hidden={formType === "View" ? true : false}
              isValid={touched.imageUrl ? !touched.imageUrl : true}
              errorMessage={errors.imageUrl}
            >
              Select Image
            </ATMFilePicker>
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Institutional Product Name"
              variant="outlined"
              extraClasses="w-full"
              value={values.name}
              onChange={handleChange}
              name="name"
              label="Institutional Product Name"
              required={true}
              readOnly={formType === "View" ? true : false}
              isValid={touched.name ? !touched.name : true}
              errorMessage={errors.name}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Short Description"
              variant="outlined"
              extraClasses="w-full"
              value={values.shortDescription}
              onChange={handleChange}
              name="shortDescription"
              label="Short Description"
              required={true}
              readOnly={formType === "View" ? true : false}
              isValid={
                touched.shortDescription ? !touched.shortDescription : true
              }
              errorMessage={errors.shortDescription}
            />
          </div>

          <div className="mb-4">
            <p className="font-medium	p-1 ">
              Long Description <span className="text-danger"> * </span>
            </p>
            <div className="z-0 border rounded-md">
              <MOLMarkdownEditor
                label=""
                value={values.longDescription}
                placeholder="Write description or drag your files here...."
                extraClassName="border-red-300 min-h-[250px] border-[1px] border-slate-200"
                onChange={(newValue: any) => {
                  setFieldValue("longDescription", newValue);
                }}
              />
            </div>
            <ErrorMessage name="longDescription">
              {(errorMessage) => (
                <p className="text-red-400 mt-1">{errorMessage}</p>
              )}
            </ErrorMessage>
          </div>
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses="hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer"
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton
            extraClasses="py-3 border bg-red-500 hover:bg-red-600 text-white"
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddInstitutionalProduct;
