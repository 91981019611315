import React from "react";
import { Form, FormikProps } from "formik";
import {  ATMButton, ATMInput } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiBannerAdd } from "../../models/banner.model";
import ATMFilePicker from "../../components/UI/atoms/ATMFilePicker";
// import ATMFilePicker from "../../components/UI/atoms/ATMFilePicker";

const AddBanner = (props: OtherProps & FormikProps<ApiBannerAdd>) => {
  const { touched, errors,  handleChange, setFieldValue, handleClose, formType,values, isLoading } =
    props;


  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
         <div className="mb-4">
            <ATMFilePicker
              onSelect={(value) => setFieldValue("imageUrl", value)}
              onUnSelect={() => setFieldValue("imageUrl", "")}
              selected={values.imageUrl}
              label="Image "
              required={true}
              hidden={formType === "View" ? true : false}
              isValid={touched.imageUrl ? !touched.imageUrl : true}
              errorMessage={errors.imageUrl}
            >
              Select Image
            </ATMFilePicker>
        </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Banner Order Number"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="order"
              value = {values.order}
              label="Banner Order Number "
              required={true}
              readOnly={formType === "View" ? true : false }
              isValid={touched.order ? !touched.order : true}
              errorMessage={errors.order}
            />
          </div>          
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses='hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer'
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton

            extraClasses='py-3 border bg-red-500 hover:bg-red-600 text-white'
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddBanner;
