import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { Box, Fade, Modal } from "@mui/material";
import { ApiOrderAdd } from "../../models/order.model";
import { formikApiProps, OtherProps } from "../../models/addUI.model";
import showToast from "../../utils/toast";
import AddOrder from "./AddOrder";
// import { OrderValidationSchema } from "../validationSchema/OrderValidationSchema";
import {
  useAddOrderMutation,
  useGetOrderByIdQuery,
  useUpdateOrderMutation,
} from "../../services/OrderService";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const AddOrderFormik = withFormik<OtherProps & formikApiProps, ApiOrderAdd>({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    let values: ApiOrderAdd;
    switch (props.formType) {
      case "Add":
        values = props.intialValues;
        break;
      case "Edit":
        values = props.intialValues;
        break;
      case "View":
        values = props.intialValues;
        break;
      default:
        values = props.intialValues;
    }

    return values;
  },

  // validationSchema : OrderValidationSchema,

  handleSubmit(values, { props, setValues, setSubmitting }) {
    switch (props.formType) {
      case "Edit":
        let reqBody = {
          productData: values.productData,
          userId: values.userId,
          user_billing_address: values.user_billing_address,
          user_billing_details : values.user_billing_details,
          payment_gateway_details: values.payment_gateway_details,
          status: values.status,
          applied_coupon: values.applied_coupon,
          order_group_id: values.order_group_id,
          order_unique_id: values.order_unique_id,
          product_quantity: values.product_quantity,
          order_total_amount: values.order_total_amount,
          after_discount_order_total: values.after_discount_order_total,
        };
        setValues({ ...values });

        props
          .editApi({ body: reqBody, id: props.selectedId })
          .then((response: any) => {
            if (response?.data?.status) {
              showToast(response?.data?.message, "success");
              setSubmitting(true);
              props.handleClose();
            } else {
              if (response?.error) {
                props.setInitialValues(values);
                showToast(response?.error?.data?.message, "error");
              } else {
                props.setInitialValues(values);
                showToast(response?.data?.message, "error");
              }
            }
          });
        break;
      default:
        values = props.intialValues;
    }
  },
})(AddOrder);

const AddOrderWrapper = React.forwardRef((props: OtherProps, ref) => {
  const { open = false, handleClose, formType } = props;
  const [addOrderApi, addOrderInfoApi] = useAddOrderMutation();
  const [updateOrderApi, updateOrderApiInfo] = useUpdateOrderMutation();
  const order: any = useSelector((state: RootState) => state.order);
  const { selectedId } = order;
  const {
    data: orderData,
    isLoading: orderDataIsLoading,
    isFetching: orderDataIsFetching,
  } = useGetOrderByIdQuery(selectedId, { skip: !selectedId });
  const initialData: ApiOrderAdd = {
    productData: {
      _id: "",
      title: "",
      mrp: "",
      sellingPrice: "",
    },
    userId: "",
    user_billing_details : {
      fullname : "",
      email :"",
      Phone :"",
    },
    user_billing_address: {
      city: "",
      addressline1: "",
      addressline2: "",
      state: "",
      pincode: "",
    },
    payment_gateway_details: [
      {
        status: false,
        msg: "",
        data: {
          id: "",
          entity: "",
          amount: "",
          amount_paid: "",
          amount_due: "",
          currency: "",
          receipt: "",
          offer_id: "",
          status: "",
          attempts: "",
          notes: [""],
          created_at: "",
        },
      },
      {
        razorpay_order_id: "",
        razorpay_payment_id: "",
        razorpay_signature: "",
      },
    ],
    status: "",
    applied_coupon: "",
    order_group_id: "",
    order_unique_id: "",
    product_quantity: "",
    order_total_amount: "",
    after_discount_order_total: "",
  };
  const [intialValues, setInitialValues] = useState(initialData);
  useEffect(() => {
    if (formType === "View" || formType === "Edit") {
      if (!orderDataIsFetching && !orderDataIsLoading) {
        const dataOrder = orderData?.data;

        let orderDataList: ApiOrderAdd = {
          productData: dataOrder?.productData,
          userId: dataOrder?.userId,
          user_billing_details : dataOrder?.user_billing_details,
          user_billing_address: dataOrder?.user_billing_address,
          payment_gateway_details: dataOrder?.payment_gateway_details,
          status: dataOrder?.status,
          applied_coupon: dataOrder?.applied_coupon,
          order_group_id: dataOrder?.order_group_id,
          order_unique_id: dataOrder?.order_unique_id,
          product_quantity: dataOrder?.product_quantity,
          order_total_amount: dataOrder?.order_total_amount,
          after_discount_order_total: dataOrder?.after_discount_order_total,
        };
        setInitialValues(orderDataList);
      }
    }
  }, [orderDataIsLoading, orderDataIsFetching, orderData, formType]);

  return (
    <div>
      <Modal
        className="h-screen "
        open={open}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="fade-box">
            <div className="flex items-center bg-gradient-to-r from-blue-400 to-purple-500 h-16 py-9 px-3 text-h3 text-white font-medium">
              {formType} Order
            </div>
            <AddOrderFormik
              formType={formType}
              handleClose={() => handleClose()}
              isLoading={
                addOrderInfoApi.status === "pending" ||
                updateOrderApiInfo.isLoading
              }
              intialValues={intialValues}
              setInitialValues={setInitialValues}
              addApi={addOrderApi}
              editApi={updateOrderApi}
              selectedId={selectedId}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
});

export default AddOrderWrapper;
