import React from "react";
import { ErrorMessage, Field, FieldArray, Form, FormikProps } from "formik";
import { ATMButton, ATMInput } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiContactUsAdd } from "../../models/contactUs.model";
import { Grid, InputLabel } from "@mui/material";
import { Box } from "@mui/system";
import ATMFilePicker from "../../components/UI/atoms/ATMFilePicker";

const AddContactUs = (props: OtherProps & FormikProps<ApiContactUsAdd>) => {
  const {
    touched,
    errors,
    handleChange,
    handleClose,
    setFieldValue,
    formType,
    isLoading,
    values,
  } = props;

  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          <div className="justify-start	flex  ">
            <div className="m-4">
              <ATMInput
                size="small"
                placeholder="Email"
                variant="outlined"
                extraClasses="w-72"
                onChange={handleChange}
                name="email"
                label="Email "
                value={values.email}
                required={true}
                readOnly={formType === "View" ? true : false}
                isValid={touched.email ? !touched.email : true}
                errorMessage={errors.email}
              />
            </div>

            <div className="m-4">
              <ATMInput
                size="small"
                placeholder="Contact Number"
                variant="outlined"
                extraClasses="w-72"
                onChange={handleChange}
                name="mobile"
                value={values.mobile}
                label="Contact Number"
                required={true}
                isValid={touched.mobile ? !touched.mobile : true}
                errorMessage={errors.mobile}
                readOnly={formType === "View" ? true : false}
              />
            </div>
            
          </div>
         
        <div className="justify-start	flex  ">
          <div className="m-4">
              <ATMInput
                size="small"
                placeholder="Address"
                variant="outlined"
                extraClasses="w-72"
                onChange={handleChange}
                value={values.address}
                name="address"
                label=" Address "
                readOnly={formType === "View" ? true : false}
                required={true}
                isValid={touched.address && errors.address ? false : true}
                errorMessage={errors.address}
              />
            </div>
            <div className="m-4">
              <ATMInput
                size="small"
                placeholder="Disclaimer"
                variant="outlined"
                extraClasses="w-72"
                onChange={handleChange}
                value={values.offerSticker}
                name="offerSticker"
                label="Disclaimer"
                required={true}
                readOnly={formType === "View" ? true : false}
                isValid={touched.offerSticker ? !touched.offerSticker : true}
                errorMessage={errors.offerSticker}
              />
            </div>
          </div>
            <div className="m-4 ">
              <ATMInput
                size="small"
                placeholder="Map Link"
                variant="outlined"
                extraClasses="w-full"
                onChange={handleChange}
                value={values.mapLink}
                name="mapLink"
                label="Map Link"
                required={true}
                readOnly={formType === "View" ? true : false}
                isValid={touched.mapLink ? !touched.mapLink : true}
                errorMessage={errors.mapLink}
              />
            
          </div>
          <p className="font-medium	m-4 ">
            {" "}
            Social Links:<span className="text-danger"> * </span>{" "}
          </p>

          <div className="m-4 ">
            <FieldArray
              name="social_network_and_links"
              render={(arrayHelpers) => (
                <>
                  {values.social_network_and_links.map((friend, index) => (
                    <Box
                      key={index}
                      sx={{ borderWidth: "2px", width: "100%", p: 1, mt: 1 }}
                    >
                      <InputLabel htmlFor="productId">
                        <span className="font-semibold text-black">Name</span>{" "}
                        <span className="text-danger"> * </span>
                      </InputLabel>

                      <Field
                        className="border border-xl w-full py-1 pl-1 my-2 hover:border-black"
                        sx={{ backgroundColor: "black" }}
                        name={`social_network_and_links[${index}].name`}
                        readOnly={formType === "View" ? true : false }

                      />

                      <ErrorMessage
                        name={`social_network_and_links[${index}].name`}
                      >
                        {(errorMessage) => (
                          <p className="text-red-400 mt-1">{errorMessage}</p>
                        )}
                      </ErrorMessage>

                      <InputLabel htmlFor="productId">
                        <span className="font-semibold text-black">Link</span>{" "}
                        <span className="text-danger"> * </span>
                      </InputLabel>
                      <Field
                        className="border border-xl w-full py-1 pl-1 my-2  hover:border-black"
                        sx={{ backgroundColor: "black" }}
                        name={`social_network_and_links[${index}].link`}
                        readOnly={formType === "View" ? true : false }

                      />
                      <ErrorMessage
                        name={`social_network_and_links[${index}].link`}
                      >
                        {(errorMessage) => (
                          <p className="text-red-400 mt-1">{errorMessage}</p>
                        )}
                      </ErrorMessage>
                      <InputLabel htmlFor="productId">
                        <span className="font-semibold text-black">Icon</span>{" "}
                        <span className="text-danger"> * </span>
                      </InputLabel>

                      <div className="mb-4">
                        <ATMFilePicker
                          onSelect={(value) =>
                            setFieldValue(
                              `social_network_and_links[${index}].icon`,
                              value
                            )
                          }
                          onUnSelect={() =>
                            setFieldValue(
                              `social_network_and_links[${index}].icon`,
                              ""
                            )
                          }
                          selected={values.social_network_and_links[index].icon}
                          hidden={formType === "View" ? true : false}
                        >
                          Select Images
                        </ATMFilePicker>
                        <ErrorMessage
                          name={`social_network_and_links[${index}].icon`}
                        >
                          {(errorMessage) => (
                            <p className="text-red-400 mt-1">{errorMessage}</p>
                          )}
                        </ErrorMessage>
                      </div>
                      <InputLabel htmlFor="productId">
                        <span className="font-semibold text-black">
                          Social Link Order Number
                        </span>{" "}
                        <span className="text-danger"> * </span>
                      </InputLabel>
                      <Field
                        className="border border-xl w-full py-1 pl-1 my-2 hover:border-black"
                        sx={{ backgroundColor: "black" }}
                        name={`social_network_and_links[${index}].order`}
                        readOnly={formType === "View" ? true : false }

                      />
                      <ErrorMessage
                        name={`social_network_and_links[${index}].order`}
                      >
                        {(errorMessage) => (
                          <p className="text-red-400 mt-1">{errorMessage}</p>
                        )}
                      </ErrorMessage>

                      <button
                        type="button"
                        className=" hover:bg-red-500  text-white text-xs ml-2 mt-2 bg-red-500 font-normal	 hover:text-white py-1 px-1 border border-black-300 hover:border-transparent rounded"
                        onClick={() => arrayHelpers.remove(index)}
                        hidden={formType === "View" ? true : false }

                      >
                        Remove
                      </button>
                    </Box>
                  ))}
                  <Grid item xs={12}>
                    <button
                      type="button"
                      className=" hover:bg-blue-500  text-white text-xs p-2 mt-2  bg-blue-500 font-normal	 hover:text-white border border-black-300 hover:border-transparent rounded"
                      onClick={() =>
                        arrayHelpers.push({
                          link: "",
                          icon: "",
                          name: "",
                          order: "",
                        })
                      }
                      hidden={formType === "View" ? true : false }

                    >
                      Add
                    </button>
                  </Grid>
                </>
              )}
            />
          </div>
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses="hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer"
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton
            extraClasses="py-3 border bg-red-500 hover:bg-red-600 text-white"
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddContactUs;
