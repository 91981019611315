import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonExportApiType, CommonPaginationApiDataType } from '../models/commonTypes';
import { ApiCouponAdd, ApiCouponUpdate } from '../models/coupon.model';
import { BASE_URL } from "../utils/constants";
const AUTH_TOKEN = localStorage.getItem("authToken")

const TOKEN = AUTH_TOKEN ? AUTH_TOKEN : ""

export const couponApi = createApi({
    reducerPath: 'couponApi',
    tagTypes: ['file', 'allFiles'],
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/coupon` }),
    endpoints: (builder) => ({

        // **** GET WITH PAGINATION
        GetCouponWithPagination: builder.query({
            providesTags: ['file'],
            query: (body: CommonPaginationApiDataType) => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body
            }),
        }),

        // **** ADD FileManager
        addCoupon: builder.mutation({
            invalidatesTags: ['file'],
            query: (body: ApiCouponAdd
            ) => (
                {
                    url: `/add`,
                    headers: {
                        "x-access-token": TOKEN,
                    },
                    method: "POST",
                    body
                }
            ),
        }),

        // **** UPDATE FileManager
        updateCoupon: builder.mutation({
            invalidatesTags: ['file'],
            query: ({ body, id }: ApiCouponUpdate) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "PUT",
                body,
            }),
        }),


        // **** GET ALL FileManagers
        getAllCoupon: builder.query({
            providesTags: ['allFiles'],
            query: () => ({
                url: `/`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "GET",

            }),
        }),

        // **** GET BY ID
        getCouponById: builder.query({
            providesTags: ['file'],
            query: (id: string) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "GET",

            }),
        }),

        // **** GET BY ID
        changeCouponStatus: builder.mutation({
            invalidatesTags: ['file'],
            query: (id: string) => ({
                url: `/change-status/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "PUT",

            }),
        }),

        // **** DELETE BY ID
        DeleteCouponById: builder.mutation({
            invalidatesTags: ['file'],
            query: (id: string) => ({
                url: `/${id}`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "DELETE",

            }),
        }),

        /** EXPORT ALL FileManager DATA */
        exportCouponData: builder.query({
            query: ({ searchValue, params, filterBy, rangeFilterBy, orderBy, orderByValue }: CommonExportApiType) => ({
                url: `/export-all`,
                headers: {
                    "x-access-token": TOKEN,
                },
                method: "POST",
                body: { searchValue, params, filterBy, rangeFilterBy, orderBy, orderByValue },
            }),
        }),

    }),
})

export const
    {
        useGetAllCouponQuery,
        useGetCouponWithPaginationQuery,
        useGetCouponByIdQuery,
        useAddCouponMutation,
        useChangeCouponStatusMutation,
        useUpdateCouponMutation,
        useExportCouponDataQuery,
        useDeleteCouponByIdMutation,

    } = couponApi