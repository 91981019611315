import React from "react";
import { ErrorMessage, FieldArray, Form, FormikProps } from "formik";
import { ATMButton, ATMInput, ATMSelect } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiRecipeAdd } from "../../models/recipe.model";
import ATMFilePicker from "../../components/UI/atoms/ATMFilePicker";
import MOLMarkdownEditor from "../../components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";

const AddRecipe = (props: OtherProps & FormikProps<ApiRecipeAdd>) => {
  const {
    touched,
    errors,
    handleChange,
    handleClose,
    formType,
    values,
    setFieldValue,
    isLoading,
    recipecategoryListSingle = null,
  } = props;
  
  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >
          <div className="mb-4">
            <ATMFilePicker
              onSelect={(value) => setFieldValue("imageUrl", value)}
              onUnSelect={() => setFieldValue("imageUrl", "")}
              selected={values.imageUrl}
              label="Image "
              required={true}
              hidden={formType === "View" ? true : false}
              isValid={touched.imageUrl ? !touched.imageUrl : true}
              errorMessage={errors.imageUrl}
            >
              Select Image
            </ATMFilePicker>
          </div>
          <div className="mb-4">
            <ATMSelect
              options={recipecategoryListSingle}
              size="medium"
              placeholder="Recipe Category"
              extraClasses="w-full"
              onChange={handleChange}
              name="recipe_category"
              label="Recipe Category"
              value={values.recipe_category}
              defaultValue={values.recipe_category}
              required={true}
              disabled={formType === "View" ? true : false}
              isValid={
                touched.recipe_category ? !touched.recipe_category : true
              }
              errorMessage={errors.recipe_category}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Recipe Title"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="title"
              label=" Recipe Title"
              value={values.title}
              required={true}
              isValid={touched.title ? !touched.title : true}
              readOnly={formType === "View" ? true : false}
              errorMessage={errors.title}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Slug"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="slug"
              value={values.slug}
              label="Slug"
              required={false}
              readOnly={formType === "View" ? true : false}
              isValid={touched.slug ? !touched.slug : true}
              errorMessage={errors.slug}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Channel Name"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="channel_name"
              value={values.channel_name}
              label="Channel Name"
              required={false}
              readOnly={formType === "View" ? true : false}
              isValid={touched.channel_name ? !touched.channel_name : true}
              errorMessage={errors.channel_name}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Link"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="link"
              value={values.link}
              label="Link"
              required={false}
              readOnly={formType === "View" ? true : false}
              isValid={touched.link ? !touched.link : true}
              errorMessage={errors.link}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Video Description"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="video_description"
              value={values.video_description}
              label="Video Description"
              required={false}
              readOnly={formType === "View" ? true : false}
              isValid={
                touched.video_description ? !touched.video_description : true
              }
              errorMessage={errors.video_description}
            />
          </div>

          <div className="mb-4">
            <MOLMarkdownEditor
              label="Method"
              required
              value={values.method}
              placeholder="Write description or drag your files here...."
              extraClassName="border-red-300 min-h-[250px] border-[1px] border-slate-200"
              readonly={formType === "View" ? true : false}
              onChange={(newValue: any) => {
                setFieldValue("method", newValue);
              }}
            />
          </div>
          <ErrorMessage name="method">
            {(errorMessage) => (
              <p className="text-red-400 mt-1">{errorMessage}</p>
            )}
          </ErrorMessage>

          <div className="mb-4  ">
            <MOLMarkdownEditor
              required
              label="Ingredients"
              value={values.ingredients}
              placeholder="Write description or drag your files here...."
              extraClassName="border-red-300 min-h-[250px] border-[1px] border-slate-200"
              readonly={formType === "View" ? true : false}
              onChange={(newValue: any) => {
                setFieldValue("ingredients", newValue);
              }}
            />
          </div>
          <ErrorMessage name="ingredients">
            {(errorMessage) => (
              <p className="text-red-400 mt-1">{errorMessage}</p>
            )}
          </ErrorMessage>

          <p className="font-medium	mt-2">
            Images : <span className="text-danger "> * </span>
          </p>
          <FieldArray
            name="images"
            render={(arrayHelpers) => (
              <>
                {values.images.map((friend, index) => (
                  <div className="mb-4" key={index}>
                    <ATMFilePicker
                      onSelect={(value) =>
                        setFieldValue(`images[${index}]`, value)
                      }
                      onUnSelect={() => setFieldValue(`images[${index}]`, "")}
                      selected={values.images[index]}
                      hidden={formType === "View" ? true : false}
                      isValid={touched.images ? !touched.images : true}
                    >
                      Select Images
                    </ATMFilePicker>
                    <ErrorMessage name={`images[${index}]`}>
                      {(errorMessage) => (
                        <p className="text-red-400 mt-1">{errorMessage}</p>
                      )}
                    </ErrorMessage>

                    <button
                      type="button"
                      className=" hover:bg-red-500  text-white text-xs ml-2 mt-2 bg-red-500 font-normal	 hover:text-white py-1 px-1 border border-black-300 hover:border-transparent rounded"
                      onClick={() => arrayHelpers.remove(index)}
                      hidden={formType === "View" ? true : false}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className=" hover:bg-blue-500  text-white text-xs p-2 mt-2  bg-blue-500 font-normal	 hover:text-white border border-black-300 hover:border-transparent rounded"
                  onClick={() =>
                    arrayHelpers.push({
                      images: "",
                    })
                  }
                  hidden={formType === "View" ? true : false}
                >
                  Add
                </button>
              </>
            )}
          />
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses="hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer"
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton
            extraClasses="py-3 border bg-red-500 hover:bg-red-600 text-white"
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddRecipe;
