import React, { useEffect, useState } from 'react'
import OrderViewList from './OrderViewList'
import Header from '../../../components/LayoutTemplates/headerLayout/Header'
import { useGetOrderByIdQuery } from '../../../services/OrderService';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const OrderViewListWrapper = () => {
  const { id } = useParams();
  const [singleOrderData, setSingleOrderData] = useState<any>()
  const {
    data: orderData,
    isLoading: orderDataIsLoading,
    isFetching: orderDataIsFetching,
  } = useGetOrderByIdQuery(id, { skip: !id });


  // **** Use Effect for set ContactUs Data
  useEffect(() => {
    if (!orderDataIsLoading && !orderDataIsFetching) {
      setSingleOrderData(orderData?.data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDataIsLoading, orderDataIsFetching, orderData]);

  return (
    <div>
      <Header />
      {(orderDataIsLoading || orderDataIsFetching) && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}
      <OrderViewList
        singleOrderData={singleOrderData}
      /></div>
  )
}

export default OrderViewListWrapper