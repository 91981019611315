import { Routes, Route, BrowserRouter } from "react-router-dom";
import DashboardLayout from "../components/LayoutTemplates/dashboardLayout/DashboardLayout";
import FileManagerWrapper from "../screens/File-Manager/FileManagerWrapper";
import ContactUsWrapper from "../screens/Contact-Us/ContactUsWrapper";
import CategoryWrapper from "../screens/Category/CategoryWrapper";
import SocialMediaWrapper from "../screens/RecipeCategory/RecipeCategoryWrapper";
import SliderImageWrapper from "../screens/Order/OrderWrapper";
import BrandWrapper from "../screens/Brand/BrandWrapper";
import ProductWrapper from "../screens/Product/ProductWrapper";
import ReviewWrapper from "../screens/Review/ReviewWrapper";
import JobWrapper from "../screens/Job/JobWrapper";
import JobApplicationWrapper from "../screens/Job Application/JobApplicationWrapper";
import FranchiseWrapper from "../screens/Franchise/FranchiseWrapper";
import FeedbackWrapper from "../screens/Feedback/FeedbackWrapper";
import EnquiresWrapper from "../screens/Enquires/EnquiresWrapper";
import CouponWrapper from "../screens/Coupon/CouponWrapper";
import TransactionWrapper from "../screens/Transaction/TransactionWrapper";
import ProductGridWrapper from "../screens/Product-Grid/ProductGridWrapper";
import BannerWrapper from "../screens/Banner/BannerWrapper";
import RecipeWrapper from "../screens/Recipe/RecipeWrapper";
import UserWrapper from "../screens/User/UserWrapper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import LoginWrapper from "../screens/Onboarding/Login/LoginWrapper";
import AdminUserWrapper from "../screens/AdminUser/AdminUserWrapper";
import GalleryWrapper from "../screens/Gallery/GalleryWrapper";
import InstitutionalProductWrapper from "../screens/Institutional Product/InstitutionalProductWrapper";
import GalleryCategoryWrapper from "../screens/GalleryCategory/GalleryCategoryWrapper";
import DownloadWrapper from "../screens/Download/DownloadWrapper";
import UpdatePasswordFormWrapper from "../forms/updatePassword/UpdatePasswordFormWrapper";
import NewsletterWrapper from "../screens/Newsletter/NewsletterWrapper";
import CompanyPolicyWrapper from "../screens/Company Policy/CompanyPolicyWrapper";
import ComboOffersWrapper from "../screens/Combo-offers/ComboOffersWrapper";
import OrderViewListWrapper from "../screens/Order/View/OrderViewListWrapper";

const Index = () => {
  const [token, setToken] = useState<string | null | undefined>("")
  const { auth } = useSelector((state: RootState) => state)
  useEffect(() => {
    const tokenData = auth.token ? auth.token : (localStorage.getItem("authToken") ? localStorage.getItem("authToken") : "")
    setToken(tokenData)
  }, [auth.token])
  return (

    <BrowserRouter>
      <Routes>
        {!token ?
          <Route path="/" element={<LoginWrapper />} />

          :
          <>
            <Route path="/" element={<DashboardLayout />} />
            <Route path="/file-manager" element={<FileManagerWrapper />} />
            <Route path="/order" element={<SliderImageWrapper />} />
            <Route path="/recipe-category" element={<SocialMediaWrapper />} />
            <Route path="/category" element={<CategoryWrapper />} />
            <Route path="/contact-us" element={<ContactUsWrapper />} />
            <Route path="/brand" element={<BrandWrapper />} />
            <Route path="/product" element={<ProductWrapper />} />
            <Route path="/review" element={<ReviewWrapper />} />
            <Route path="/job" element={<JobWrapper />} />
            <Route path="/web-user" element={<UserWrapper />} />
            <Route path="/job-application" element={<JobApplicationWrapper />} />
            <Route path="/franchise" element={<FranchiseWrapper />} />
            <Route path="/feedback" element={<FeedbackWrapper />} />
            <Route path="/enquiry" element={<EnquiresWrapper />} />
            <Route path="/coupon" element={<CouponWrapper />} />
            <Route path="/transaction" element={<TransactionWrapper />} />
            <Route path="/product-grid" element={<ProductGridWrapper />} />
            <Route path="/combo-offers" element={<ComboOffersWrapper />} />
            <Route path="/homeslider" element={<BannerWrapper />} />
            <Route path="/recipe" element={<RecipeWrapper />} />
            <Route path="/admin-user" element={<AdminUserWrapper />} />
            <Route path="/gallery" element={<GalleryWrapper />} />
            <Route path="/gallery-category" element={<GalleryCategoryWrapper />} />
            <Route path="/institutional-product" element={<InstitutionalProductWrapper />} />
            <Route path="/downloads" element={<DownloadWrapper />} />
            <Route path="/update-password" element={< UpdatePasswordFormWrapper />} />
            <Route path="/newsletter" element={< NewsletterWrapper />} />
            <Route path="/company-policy" element={< CompanyPolicyWrapper />} />
            <Route path="/order/:id" element={< OrderViewListWrapper />} />


          </>
        }

      </Routes>
    </BrowserRouter>
  )
}

export default Index