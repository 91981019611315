import React from "react";
import { Form, FormikProps } from "formik";
import {  ATMButton, ATMInput } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiReviewAdd } from "../../models/review.model";

const AddReview = (props: OtherProps & FormikProps<ApiReviewAdd>) => {
  const { touched, errors,  handleChange, handleClose, formType, isLoading,values } =
    props;


  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        > 

        <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Product Name"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="product_name"
              label="Product Name "
              required={false}
              value= {values.product_name}
              readOnly={formType === "View" ? true : false}
              isValid={touched.product_name ? !touched.product_name : true}
              errorMessage={errors.product_name}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Rating"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="rating"
              label="Rating "
              required={false}
              value= {values.rating}
              readOnly={formType === "View" ? true : false}
              isValid={touched.rating ? !touched.rating : true}
              errorMessage={errors.rating}
            />
          </div>
        
         
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Full Name"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="fullName"
              label="Full Name "
              required={false}
              value= {values.fullName}
              readOnly={formType === "View" ? true : false}
              isValid={touched.fullName ? !touched.fullName : true}
              errorMessage={errors.fullName}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Email"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="email"
              label="Email "
              required={false}
              value= {values.email}
              readOnly={formType === "View" ? true : false}
              isValid={touched.email ? !touched.email : true}
              errorMessage={errors.email}
            />
          </div>
         
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Text"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="text"
              label="Text "
              required={false}
              value= {values.text}
              readOnly={formType === "View" ? true : false}
              isValid={touched.text ? !touched.text : true}
              errorMessage={errors.text}
            />
          </div>
        </div>

        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses='hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer'
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton

            extraClasses='py-3 border bg-red-500 hover:bg-red-600 text-white'
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddReview;
