import React from "react";
import { Form, FormikProps } from "formik";
import { ATMButton, ATMInput } from "../../components/UI";
import { OtherProps } from "../../models/addUI.model";
import { ApiUserAdd } from "../../models/user.model";

const AddUser = (props: OtherProps & FormikProps<ApiUserAdd>) => {
  const { touched, errors, handleChange, handleClose, formType, isLoading, values } =
    props;

  return (
    <>
      <Form>
        <div
          className="text-fields p-4 overflow-y-scroll"
          style={{ height: "86vh" }}
        >


          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Full Name"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="fullName"
              label="Full Name"
              readOnly={formType === "View" ? true : false}
              required={true}
              value={values.fullName}
              isValid={touched.fullName ? !touched.fullName : true}
              errorMessage={errors.fullName}
            />
          </div>

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="User Name"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="userName"
              label="User Name"
              readOnly={formType === "View" ? true : false}
              value={values.userName}
              required={true}
              isValid={touched.userName ? !touched.userName : true}
              errorMessage={errors.userName}
            />
          </div>



          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Email "
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="email"
              readOnly={formType === "View" ? true : false}
              label="Email"
              required={true}
              value={values.email}
              isValid={touched.email ? !touched.email : true}
              errorMessage={errors.email}
            />
          </div>
          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Contact Number"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="mobile"
              label="Contact Number"
              readOnly={formType === "View" ? true : false}
              required={true}
              value={values.mobile}
              isValid={touched.mobile ? !touched.mobile : true}
              errorMessage={errors.mobile}
            />
          </div>
          {formType === "Edit" ? null : (
            <><div className="mb-4" hidden={formType === "View" ? true : false}>
              <ATMInput
                size="small"
                placeholder="Password"
                variant="outlined"
                extraClasses="w-full"
                onChange={handleChange}
                name="password"
                label="Password"
                required={true}
                isValid={touched.password ? !touched.password : true}
                errorMessage={errors.password}
              />
            </div>
              <div className="mb-4" hidden={formType === "View" ? true : false}>
                <ATMInput
                  size="small"
                  placeholder="Confirm Password"
                  variant="outlined"
                  extraClasses="w-full"
                  onChange={handleChange}
                  hidden={formType === "View" ? true : false}
                  name="confirm_password"
                  label="Confirm Password"
                  required={true}
                  isValid={touched.confirm_password ? !touched.confirm_password : true}
                  errorMessage={errors.confirm_password}
                />
              </div>
            </>)}

          <div className="mb-4">
            <ATMInput
              size="small"
              placeholder="Address"
              variant="outlined"
              extraClasses="w-full"
              onChange={handleChange}
              name="address"
              readOnly={formType === "View" ? true : false}
              label="Address"
              required={false}
              value={values.address}
              isValid={touched.address ? !touched.address : true}
              errorMessage={errors.address}
            />
          </div>


        </div>


        <div className="flex bottom-0  w-full absolute">
          <ATMButton
            hidden={formType === "View" ? true : false}
            extraClasses='hover:bg-blue-600 bg-blue-500 py-3 text-white cursor-pointer'
            text={formType === "Edit" ? "Update" : formType}
            type="submit"
            loading={isLoading}
          />
          <ATMButton

            extraClasses='py-3 border bg-red-500 hover:bg-red-600 text-white'
            text="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          />
        </div>
      </Form>
    </>
  );
};

export default AddUser;
